
import React, { useState } from "react";
import { ActiveObject, useActiveFabricObject, useFabricCanvasHandler } from "../../../FabricCanvas";
import { BiEndSlider } from "../../../../../components/Sliders/Sliders";
import { useSelector } from 'react-redux';
import ToolPanelButton from "../../../../../components/Buttons/ToolPanelButton";
import Select from "../../../../../components/Select";
import { CategorySelect } from "../../CategoryDialog";
import TextArea from "../../../../../components/TextArea";
import { ActiveTextObject, addTextObject } from "../../../objectUtils";
import Input from "../../../../../components/Input/Input";
import { ColorStrip } from "../../ColorView";

function addFallbackFont(fontFamily) {
    return `${fontFamily},Hind`;
}
function removeFallbackFont(fontFamily) {
    return fontFamily?.replace(',Hind', '');
}

function isNullOrUndef(val) {
    return val === null || val === undefined;
}

export default function Basic({ }) {
    const fontData = useSelector(s => s.fonts);
    const defaultColor = useSelector(s => s.foregroundColors.defaultColor);
    const fonts = fontData.libraries.map(lib =>
        lib.fonts.map((f) => ({
            label: f.familyName,
            value: f.familyName,
            style: {
                fontFamily: f.familyName
            },
            font: f
        }))
    );
    const [selectedValue, setSelectedValue] = useState(0);
    const [activeObject, update] = useActiveFabricObject(ActiveTextObject);
    const fontOpts = fonts[selectedValue] || [];

    const [canvas] = useFabricCanvasHandler({
        'canvas:x-loaded': function () {
            delete window.Ext.canvasController.isTextDirty;
        }
    });

    const fontFamily = removeFallbackFont(activeObject.get('fontFamily'));
    const fontWeight = activeObject.get('fontWeight');
    const fontStyle = activeObject.get('fontStyle');
    const underline = activeObject.get('underline');
    const tracking = activeObject.get('tracking');

    return <>
        <div>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Text</label>
            <TextArea
                value={activeObject.get("text") || ''}
                onChange={val => {
                    if (activeObject.isEmpty()) {
                        addTextObject(canvas, val, {
                            fontFamily: (fontOpts[0]).value,
                            fill: defaultColor
                        });
                        update();
                    } else {
                        activeObject.set('text', val);
                        canvas.renderAll();
                        if (!(val.length % 5)) {
                            activeObject.fireModified();
                        }
                    }
                }}
                className={activeObject.size() > 1 ? 'x-item-disabled' : ''}
            />
        </div>
        {/* <CategorySelect
            onChange={(e, idx) => setSelectedValue(idx)}
            style={{ marginBottom: "0.6rem" }}
            value={selectedValue}
            categories={fontData.libraries.map((f, idx) => ({
                label: f.name,
                value: idx
            }))}
            label="Font Category"
            categoryKey="name"
        /> */}
        <div style={{ marginTop: '1rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Font</label>
            <div style={{ color: 'black', marginTop: '0.5rem' }}>
                <Select
                    closeMenuOnSelect={false}
                    options={fontOpts}
                    onChange={e => {
                        activeObject.set('fontFamily', addFallbackFont(e.value));
                        update();
                        activeObject.fireModified();
                    }}
                    value={fontOpts.find(i => i.value === fontFamily) || null}
                    isOptionDisabled={(option) => option.disabled}
                    placeholder={'Select Font...'}
                    clearable={true}
                    styles={{
                        control: e => ({ fontFamily }),
                        option: (base, state) => state.data?.style
                    }}
                />
            </div>
        </div>
        <div style={{ marginTop: '1rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Font Size</label>
            <div style={{ color: 'black', marginTop: '0.5rem' }}>
                <Input
                    style={{ border: "1px solid", borderRadius: 0 }}
                    type="number" value={activeObject.get('fontSize') || 12}
                    onChange={e => {
                        let val = Number(e.target.value);
                        if (isNaN(val) || val < 5) {
                            val = 5;
                        }
                        activeObject.set('fontSize', val)
                        update();
                    }}
                />
            </div>
        </div>
        <div style={{ marginTop: '1rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Style</label>
            <div style={{ display: 'flex', marginTop: '0.5rem' }}>
                <ToolPanelButton
                    pressed={fontWeight === 'bold'}
                    disabled={isNullOrUndef(fontWeight)}
                    className="fa-bold text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('fontWeight', fontWeight === 'bold' ? "normal" : "bold");
                        update();
                        activeObject.fireModified();
                    }}
                    title="Bold"
                />
                <ToolPanelButton
                    pressed={fontStyle === 'italic'}
                    style={{ marginLeft: "0.5rem" }}
                    disabled={isNullOrUndef(fontStyle)}
                    className="fa-italic text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('fontStyle', fontStyle === 'italic' ? "normal" : "italic");
                        update();
                        activeObject.fireModified();
                    }}
                    title="Italics"
                />
                <ToolPanelButton
                    pressed={underline}
                    disabled={isNullOrUndef(underline)}
                    style={{ marginLeft: "0.5rem" }}
                    className="fa-underline text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('underline', !underline);
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    title="Underline"
                />
            </div>
        </div>
        {/* <div style={{ marginTop: '1rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Letter Spacing</label>
            <div className={isNullOrUndef(tracking) ? 'x-item-disabled' : ''} style={{ margin: '0.5rem 0' }}>
                <BiEndSlider min={-100}
                    max={200}
                    value={Number(tracking) || 0}
                    style={{ marginBottom: 'var(--label-margin-bottom)' }}
                    onChange={(ev, val) => {
                        activeObject.set('tracking', val);
                        canvas.renderAll();
                    }}
                    onChangeCommitted={(ev, val) => {
                        update();
                        activeObject.fireModified();
                    }}
                />
            </div>
        </div> */}
        <div style={{ marginTop: '0.5rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Colors</label>
            <div className={activeObject.isEmpty() && 'x-item-disabled'} style={{ marginTop: "0.5rem" }}>
                <ColorStrip objTypes={["shaped-text", "curved-text"]} />
            </div>
        </div>
    </>;
}