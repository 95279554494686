import axios from '../../../axios';
import AddCartSlice, { addCartActions, saveAppliedCouponCode } from './AddCartSlice';
export async function getPriceSummaryByItem(id, count, isProofReading) {
  let prom;
  prom = axios({
    url: `/designer/books/priceSummary/${id}?count=${count}&isProofReadingService=${isProofReading}`,
    method: "get",
    reponseType: "json",
  });

  return await prom.then((response) => {
    let data = response.data;
    if (data.success) {
      return data.data;
    }
  })
}

export async function getShippingCharge(cartItems) {
  const items = cartItems.map(obj => ({ "bookId": obj.id, "quantity": obj.count }));
  let prom;
  prom = axios({
    url: `/designer/books/shippingChargeByProducts`,
    method: "post",
    data: items,
    reponseType: "json",
  });

  return await prom.then((response) => {
    let data = response.data;
    if (data.success) {
      return data.data;
    }
  })
}

export function applyCouponCode(data) {
  return axios({
    url: '/designer/coupon',
    method: 'post',
    data: data,
    responseType: "json",
  })
}

export function getCouponDisc(dispatch, data) {
  let couponCode = data?.couponCode
  return applyCouponCode(data).then(({ data }) => {
    if (data.valid) {
      dispatch(addCartActions.updateCouponCodeDiscount({ items: data.discount, couponCode: couponCode, totalDiscount: data.totalDiscount }));
      saveAppliedCouponCode(JSON.stringify({ promoCode: couponCode, couponDiscAmount: data.totalDiscount }));
      return data;
    }
    else if (!data.valid) {
      return data
    }
  }).catch((error) => {
    return error
  })
}

export function getPaymentConfig() {
  return axios({
    url: '/designer/config/payment'
  }).then(res => res.data.data)
}