import React from 'react';
import Utils from '../../Utils';
import classes from './ToolPanelButton.module.css';

function ToolPanelButton({ state, onClick, style, className, children, title, pressed, disabled }) {
    return <div onClick={onClick} style={style} title={title}
        className={Utils.getClasses(
            classes['toolpanel-button'], className,
            (state?.pressed || pressed) && classes['x-btn-pressed'],
            (state?.disabled || disabled) && classes['x-btn-disabled']
        )}
    >{children}</div>
}

export default ToolPanelButton;