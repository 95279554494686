import { createSlice } from "@reduxjs/toolkit";

const galleryEventsSlice = createSlice({
    name: "galleryEvents",
    initialState: { allGalleryEvents:[] },
    reducers: {
        fetchGalleryEvents: (state, action) => {
            state.allGalleryEvents = action.payload.events
        }
    },
});

export const galleryEventsAction = galleryEventsSlice.actions;
export default galleryEventsSlice.reducer;