import { useEffect, useState } from "react";
import classes from "./Storyline.module.css";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Dialogues from "./Dialogues";
import PlotTwist from "./PlotTwist";
import Utils from "../../../../Utils";
import AIData from "../../../../components/AIData/AIData";

export default function Plots({ values, setSection }) {
  const [selected, setSelected] = useState();
  return (
    <div className={classes.container}>
      <div className={classes.instruction}>
        Please select a Plot
      </div>
      <div className={classes.plotsMain} >
        {values?.map(plot => (
          <div key={plot.plot} className={Utils.getClasses(selected === plot && classes.selected)} onClick={(e) => setSelected(plot)}>
            <AIData text={plot.plot} />
          </div>
        ))}
      </div>
      <div className={classes.plotActions}>
        <div className={classes.plotInstruction}>
          Choose a Plot and unlock more!
        </div>
        <div className={Utils.getClasses(classes.actionsContainer, !selected && classes.disabled)} >
          <SecondaryButton className={classes.action} onClick={e => {
            setSection({
              key: "dialogues",
              value: "Dialogues",
              component: Dialogues,
              props: { values: selected.dialogues }
            })
          }}>Dialogues</SecondaryButton>
          <SecondaryButton className={classes.action} onClick={e => {
            setSection({
              key: "plotTwist",
              value: "Plot Twist",
              component: PlotTwist,
              props: { values: selected.plotTwists }
            })
          }}>Plot Twist</SecondaryButton>
        </div>
      </div>
    </div>
  );
}
