import React from "react";
import classes from "./StarAuthorCard.module.css";
import bookAloozaStar from "../../assets/person-icon.svg";

function StarAuthorCard({ authorImage, authorName }) {
  return (
    <div className={classes.main}>
      <div>
        <div className={classes.authorImage}>
          <img src={authorImage || bookAloozaStar} alt='Author Image' />
        </div>
      </div>
      <div>
        <h1 className={classes.authorName}>
          {authorName || "This could be you"}
        </h1>
      </div>
    </div>
  );
}

export default StarAuthorCard;
