import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBookContext } from "../BookContext";
import { fetchWordList, fetchWordLists } from "./AIHelpAction";
import classes from "./AIHelp.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Utils from "../../../Utils";
import { addTextboxObject } from "../objectUtils";
import { useFabricCanvas } from "../FabricCanvas";
import AIData from "../../../components/AIData/AIData";

export default function Wordlist({ setSection }) {
  const dispatch = useDispatch();
  const bookCtx = useBookContext();
  const wordList = useSelector((s) => s.aiHelp.wordList);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!wordList?.length) {
      fetchWordLists(dispatch);
    } else {
      const wordlist =
        wordList?.find((q) => q.theme === bookCtx.getTheme()) || wordList[0];
      fetchWordList(wordlist.id).then(setSelected);
    }
  }, [wordList]);

  //const themeOptions = wordList?.map((q) => ({ label: q.theme, value: q.id })) || [];

  return (
    <div className={classes.container}>
      {/* <Select
        options={themeOptions}
        value={themeOptions.find((t) => t.value === selected?.id)}
        onChange={(e) => fetchWordList(e.value).then(setSelected)}
      /> */}
      <div className={classes.innerMain}>
        {selected?.values?.map((data, idx) => (
          <AIData key={idx} text={data.word} addIcon={false} onClick={e =>
            setSection({
              props: { ...data, id: selected.id },
              key: `wordlist${idx}`,
              value: data.word,
              component: Word,
            })
          }/>
        ))}
      </div>
    </div>
  );
}

const Synonyms = "Synonyms";
const Antonyms = "Antonyms";
const Sentence = "Sentence";
const wordListButtonOptions = [
  {
    name: Synonyms,
  },
  {
    name: Antonyms,
  },
  {
    name: Sentence,
  },
];

const Word = (props) => {
  const canvas = useFabricCanvas();
  const [active, setActive] = useState({ name: Sentence, idx: 2 });
  return (
    <>
      <div className={classes.actionContainer}>
        {wordListButtonOptions.map((option, idx) => (
          <SecondaryButton
            onClick={() => setActive({ name: option.name, idx: idx })}
            className={Utils.getClasses(
              classes.primaryBtn,
              active.idx === idx ? classes.activeButton : ""
            )}
            key={idx}>
            {option.name}
          </SecondaryButton>
        ))}
      </div>
      <div className={classes.activeTabContainer}>
        <div className={classes.tabName}>{active.name}</div>
        <div className={classes.wordContainer}>
          {active.name === Sentence && (
            <div className={classes.sentenceContainer}>
              {getSentences(props.sentences)?.map((sentence, idx) => (
                <>
                  {idx !== 0 && (
                    <div
                      className={classes.sentence}
                      key={idx}
                      onClick={(e) =>
                        addTextboxObject(canvas, { text: sentence })
                      }>
                      {sentence}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
          {active.name === Synonyms && (
            <div
              className={classes.sentence}
              onClick={(e) =>
                addTextboxObject(canvas, { text: props.synonyms })
              }>
              {props.synonyms}
            </div>
          )}
          {active.name === Antonyms && (
            <div
              className={classes.sentence}
              onClick={(e) =>
                addTextboxObject(canvas, { text: props.antonyms })
              }>
              {props.antonyms}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const getSentences = (sentences) => {
  return sentences?.split(/\r*\n/);
};
