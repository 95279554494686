import { useState, useEffect } from "react";

export const useContainerDimensions = myRef => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    let observer;
    if (myRef.current) {
      setDimensions(getDimensions());
      observer = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length || !myRef.current) {
            return;
          }
          setDimensions(getDimensions());
        });
      });
      observer.observe(myRef.current);
    }

    return () => {
      observer?.disconnect();
    }
  }, [myRef])

  return dimensions;
};