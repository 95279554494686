import React from 'react';
import ReactDOM from 'react-dom';

const container = document.querySelector('head');
function CannonicalTag({baseUrls = [window.origin]}) {
    return ReactDOM.createPortal(
        <>
            {Array.from(new Set(baseUrls)).map(baseUrl => <link key={baseUrl} rel="canonical" href={`${baseUrl}${window.location.pathname}`}/>)}
        </>, 
        container
    );
}

export default CannonicalTag;