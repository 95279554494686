import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import classes from "./AddReview.module.css";
import SecondaryButton from '../Buttons/SecondaryButton';
import { addBookReviews, getBookReviews, getBookReviewsCount } from '../../modules/Books/BooksActions';
import { useDispatch } from 'react-redux';

function AddReview({ bookId, setReviewTotalPages }) {
  const [rating, setRating] = useState(0);
  const [reviewComment, setReviewComment] = useState("");
  const [reviewStatus, setReviewStatus] = useState()
  const dispatch = useDispatch()


  function changeRating(newRating) {
    setRating(newRating);
  }

  const addBookReviewsData = () => {
    addBookReviews({
      bookId,
      rating,
      comment: reviewComment,
    }).then((res) => {
      setReviewStatus(res.data.success);
      getBookReviews(dispatch, bookId, 1);
      getBookReviewsCount(bookId).then(function (response) {
        let data = response.data;
        setReviewTotalPages(Math.ceil(data.data.count / 10));
      });
      setReviewComment("");
      setTimeout((e) => setReviewStatus(null), 3000);
    });
  };
  return (
    <div className={classes.mainContainer}>
      <div className={classes.addReviewTitleWrapper}>
        <h3 className={classes.addReview}>Add your review</h3>
        <StarRatings
          rating={rating || 1}
          starRatedColor="#FBB805"
          numberOfStars={5}
          starDimension="1.25rem"
          starSpacing="0.125rem"
          changeRating={changeRating}
          starHoverColor="#FBB805"
        />
        <div className={classes.submitReview}>
          {reviewStatus !== null && reviewStatus !== undefined && (
          <>
            {reviewStatus ? (
              <span style={{ color: 'black' }} className={classes.reviewStatus}>Your valuable review added successfully.</span>
            ) : (
              <span style={{ color: 'red' }} className={classes.reviewStatus}>Something went wrong with your review. </span>
            )}
          </>
        )}
        </div>
      </div>
      <div className={classes.commentBoxWrapper}>
        <textarea
          className={classes.commentBox}
          placeholder={"Write your comment..."}
          value={reviewComment}
          onChange={(e) => setReviewComment(e.target.value)}
        />
      </div>
      <SecondaryButton className={classes.action} onClick={addBookReviewsData}>
        Submit
      </SecondaryButton>
    </div>
  )
}

export default AddReview;