import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Earning.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import EmptyDialogue from "../EmptyDialogue/EmptyDialogue";
import wallet from "../../../../assets/dashboardImage/animation/earnings-wallet.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchEarnings } from "../../../Users/UsersActions";
import Utils from "../../../../Utils";
import { useMediaQuery, useTheme } from "@material-ui/core";

function Earnings() {
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(totalEarnings);
  const [earnings, setEarnings] = useState([]);
  const theme = useTheme();
  const isMobScreen = !useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    setRemainingAmount(totalEarnings);
  }, [totalEarnings]);

  useEffect(() => {
    if (userDetails.user) {
      let filter = {};
      fetchEarnings({
        ...filter,
        where: { ...filter?.where, userId: userDetails.user.userId },
      }).then(function (response) {
        let data = response.data;
        if (data.success) {
          const totalEarnings = data?.data
            .map((elem) => elem.totalAmount)
            .reduce((a, b) => a + b, 0);
          setEarnings(data.data);
          setTotalEarnings(totalEarnings);
        }
      });
    }
  }, [userDetails]);

  const createBookHandler = () => {
    Utils.createBook(history);
  };
  return (
    <div className={classes.main}>
      <UserDashboardHeader
        key={"User Header"}
        title="Your Earnings"
        description="Manage your earnings here."
        // disabledButton={remainingAmount < 500}
        disabledButton={true}
        buttonText="Transfer to my bank"
      />
      {!totalEarnings ? (
        <EmptyDialogue
          key={"Empty message"}
          animation={wallet}
          descriptionText="No earnings yet! Publish a book and start earning."
          buttonText="Create your free book now"
          onClick={createBookHandler}
        />
      ) : (
        <div className={classes.container}>
          <div className={classes.earningWrapper}>
            <div className={classes.totalEarnings}>
              <h1 className={classes.totalEarningHeading}>
                Your Total Earning
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>
                  {Utils.getRupeeSymbol} {totalEarnings}
                </p>
              </div>
            </div>
            <div className={classes.transferredAmount}>
              <h1 className={classes.transferredAmountHeading}>
                Transferred Amount
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>{Utils.getRupeeSymbol} 0</p>
              </div>
            </div>
            <div className={classes.remainingAmounts}>
              <h1 className={classes.remainingAmountHeading}>
                Remaining Amount
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>
                  {Utils.getRupeeSymbol} {remainingAmount}
                </p>
              </div>
            </div>
          </div>

          <div className={classes.disclaimerWrapper}>
            <FontAwesomeIcon icon={faTriangleExclamation} /> Disclaimer : A
            minimum earning of Rs. 500 is required to withdraw your money! Sell
            more | Earn more
          </div>
          <div className={classes.earningsTableWrapper}>
            <table className={classes.earningTable}>
              <thead className={classes.earningTableHeading}>
                <tr className={classes.headingRow}>
                  <th className={classes.bookTitleHeadingCell}>
                    Book Title Name
                  </th>
                  <th className={classes.dateHeadingCell}>
                    Date of Publishing
                  </th>
                  <th className={classes.copiesSold}>Copies Sold</th>
                  <th className={classes.earningHeadingCell}>Earning</th>
                </tr>
              </thead>
              <tbody>
                {earnings?.map((earning, orderIdx) => {
                  const bookDetails = earning.bookDetails[0];
                  const publishDate = Utils.getFormattedDate(
                    bookDetails?.publishDate
                  );
                  return (
                    <tr className={classes.productRow} key={orderIdx}>
                      <td className={classes.bookNameCell}>
                        {bookDetails?.title || "Untitled"}
                      </td>
                      <td className={classes.bookDate}>{publishDate}</td>
                      <td className={classes.bookCopies}>
                        {earning.totalCount}
                      </td>
                      <td className={classes.bookPrice}>
                        {Utils.getRupeeSymbol} {earning.totalAmount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
export default Earnings;
