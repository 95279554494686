import { ActiveObject } from "./FabricCanvas";

export class ActiveTextObject extends ActiveObject {
  constructor(obj) {
    super(obj);
    this._activeObjects = this._activeObjects.filter(o => o.get('type') === 'shaped-text' || o.get('type') === 'curved-text');
  }
}

const rangeProps = new Set(['fontSize', 'fontWeight', 'fontStyle', 'fill', 'stroke', 'fontFamily', 'underline']);
export class ActiveTextboxObject extends ActiveObject {
  constructor(obj) {
    super(obj);
    const Textbox = window.fabric.IText;
    this._activeObjects = this._activeObjects.filter(o => o instanceof Textbox);
  }
  getLinedPosition(textLines, position) {
    let lineNum = 0;
    while (position > 0) {
      const len = textLines[lineNum].length + 1;
      if (len > position) {
        break;
      }
      position -= len;
      ++lineNum;
    }
    return { position, lineNum };
  }
  iterateSelectedChars(o, callback, startIndex, endIndex) {
    const textLines = o.text.split('\n');
    startIndex = startIndex ??  o.selectionStart;
    endIndex = endIndex ?? o.selectionEnd;
    let { position, lineNum } = this.getLinedPosition(textLines, startIndex);
    let lineLen = textLines[lineNum].length;
    for (let i = 0, len = endIndex - startIndex; i < len; ++i) {
      if (position > lineLen || lineLen === 0) {
        position = 0;
        lineLen = textLines[++lineNum].length;
      }
      if (lineLen > 0) {
        if (callback(lineNum, position)) {
          break;
        }
        position++;
      }
    }
  }
  updateStyles(o, style, startIndex, endIndex) {
    const styles = o.styles || {};
    this.iterateSelectedChars(o, (lineNum, position) => {
      styles[lineNum] = styles[lineNum] || {};
      styles[lineNum][position] = styles[lineNum][position] || {};
      Object.assign(styles[lineNum][position], style);
    }, startIndex, endIndex);
    return styles;
  }
  _getValue(o, key) {
    const styles = o.styles || {};
    let val = o.get(key);
    if (rangeProps.has(key) && o.isEditing) {
      if (o.selectionStart !== o.selectionEnd) {
        let res = null;
        this.iterateSelectedChars(o, (lineNum, position) => {
          const v = styles[lineNum]?.[position]?.[key];
          if (res === null) {
            res = v;
          }
          if (res !== v) {
            res = val;
            return true;
          }
        });
        return res ? res : val;
      } else {
        let styleCache = o.styleCache;
        if (styleCache?.key !== o.selectionStart) {
          let { position, lineNum } = this.getLinedPosition(o.text.split('\n'), o.selectionStart - 1);
          styleCache = {
            key: o.selectionStart,
            lineNum,
            style: JSON.parse(JSON.stringify(
              styles[lineNum]?.[position] ||
              styleCache?.style ||
              {}
            ))
          }
          styles[lineNum] = styles[lineNum] || {};
        }
        o.styleCache = styleCache;
        const v = styleCache.style?.[key];
        if (v) {
          return v;
        }
      }
    }
    return val;
  }
  _setValue(o, key, value) {
    o.dirty = true;
    if (rangeProps.has(key)) {
      if(o.isEditing){
        if (o.selectionStart !== o.selectionEnd) {
          o.set('styles', this.updateStyles(o, { [key]: value }));
          if (o.selectionStart === o.styleCache?.key || o.selectionEnd === o.styleCache?.key) {
            o.styleCache.style[key] = value;
          }
        } else if (o.selectionStart === o.styleCache?.key) {
          o.styleCache.style[key] = value;
        } else {
          o.set(key, value);
        }
      } else {
        o.set('styles', this.updateStyles(o, { [key]: value }, 0, o.text.length - 1));
        o.set(key, value);
      }
    } else {
      o.set(key, value);
    }
    if (key === 'enableTransliteration') {
      o.initHiddenTextarea();
      o.hiddenTextarea.focus();
      o.hiddenTextarea.value = o.text;
      o._updateTextarea();
    }
  }
  set(key, value) {
    const targets = this.getTargetObjects();
    const canvas = targets[0].canvas;
    for (let o of targets) {
      this._setValue(o, key, value);
    }
    canvas.fire('object:modified', { target: targets[0] });
    canvas.renderAll();
  }
  setProps(object) {
    const targets = this.getTargetObjects();
    const canvas = targets[0].canvas;
    for (let key in object) {
      for (let o of targets) {
        this._setValue(o, key, object[key]);
      }
    }
    canvas.fire('object:modified', { target: targets[0] });
    canvas.renderAll();
  }
}

export function addTextObject(canvas, newValue, props, isCurved) {
  let contentsLayer = canvas.getLayerById('contents');
  let textObject = new window.fabric[isCurved ? 'CurvedText' : 'ShapedText'](newValue, {
    left: contentsLayer.width / 2,
    top: contentsLayer.height / 2,
    fontSize: 10,
    scaleX: 0.8,
    scaleY: 0.8,
    fontFamily: 'Lato',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlignment: 'center',
    ...props
  });
  if (contentsLayer) {
    contentsLayer.add(textObject);
    canvas.setActiveObject(textObject);
  }
}

export function addTextboxObject(canvas, options) {
  let position = {
    left: 15,
    top: 100
  },
    contentsLayer = canvas.getLayerById('contents');

  const IText = window.fabric.TextArea;
  let textbox = new IText('', {
    ...position,
    fontFamily: 'Lato,Hind,Emoji',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 12,
    width: 190,
    scaleX: 0.665168,
    scaleY: 0.665168,
    allowedLeft: [[15, 142], [170, 297]],
    allowedTop: [[15, 213]],
    ...options
  });
  contentsLayer.add(textbox);
  canvas.setActiveObject(textbox);
  textbox.enterEditing();
  textbox.__onInput();
}

const defaultRange = {
  allowedLeft: [[15, 142], [170, 297]],
  allowedTop: [[15, 213]],
}

export function addImageToCanvas(imageData, scaling = 0.3, getBounds, range = defaultRange) {
  let fabric = window.fabric;

  if (!getBounds) {
    getBounds = () => window.Ext.canvasController.getProductBounds();
  }
  return new Promise(function (resolve, reject) {
    if (imageData.fileType === 'svg') {
      fabric.loadSVGFromURL(imageData.filePath, function (objects, options) {
        if (!objects || objects.length === 0) {
          reject();
          return;
        }

        var group = fabric.util.groupSVGElements(objects, options);
        var bounds = getBounds();

        var position = {
          x: bounds.left,
          y: bounds.top
        };

        var scale = 1.0,
          scaleX = 1.0,
          scaleY = 1.0;

        scaleX = (bounds.width / group.width) * scaling;
        scaleY = (bounds.height / group.height) * scaling;
        if (scaleX < scaleY)
          scale = scaleX;
        else
          scale = scaleY;

        position.y += (bounds.height - scale * group.height) / 2;
        position.x += (bounds.width - scale * group.width) / 2;

        group.scale(scale, scale);

        group.set({
          left: position.x,
          top: position.y,
          ...range
        });
        resolve(group);
      });
    } else {
      fabric.Image.fromURL(imageData.thumbPath, function (img) {
        var bounds = getBounds();
        var position = {
          x: bounds.left,
          y: bounds.top
        };

        var scaleX = bounds.width / img.width;
        var scaleY = bounds.height / img.height;
        var scale = scaleX > scaleY ? scaleX : scaleY;
        scale *= scaling;

        position.y += (bounds.height - scale * img.height) / 2;
        position.x += (bounds.width - scale * img.width) / 2;

        img.set({
          left: position.x,
          top: position.y,
          ...range
        }).scale(scale);

        resolve(img);
      });
    }
  })
}

export async function placeBackground(canvas, img, getBounds) {
  let background = canvas.getLayerById('background');
  if (background) {
    background._objects = [];
    let obj = await addImageToCanvas(img, 1, getBounds);
    obj.set({
      selectable: false,
      evented: false
    });
    background.add(obj);
    canvas.renderAll();
  }
}