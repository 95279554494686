import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

export default function TextArea({value, onChange, className}) {
    const [val, setValue] = useState('');
    useEffect(() => {
        setValue(value);
    }, [value])

    return <grammarly-editor-plugin client-id="client_EsRzBG4Z3iDXx5BpdUHgjt">
        <TextField fullWidth multiline maxRows={1} placeholder="Type your text here"
            variant="outlined" 
            style={{ 
                backgroundColor: "rgb(230, 231, 232)", 
                marginTop: '0.5rem'
            }}
            maxLength={256}
            value={val}
            onKeyDown={e => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                }
            }}
            onChange={e => {
                const value = e.target.value;
                setValue(value);
                onChange?.(value);
            }}
            className={className}
        />
    </grammarly-editor-plugin>
}