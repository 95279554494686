import axios from "../../../../axios";
import Utils from "../../../Utils";
import { uuiHelpAction } from "./AIHelpSlice";

export function fetchQuotes(dispatch) {
  return axios({
    url: "/designer/quotes/withoutItems",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      const quotes = data.data;
      Utils.shuffle(quotes);
      dispatch(uuiHelpAction.fetchQuotes(quotes));
    }
  });
}

export async function fetchQuote(id) {
  const { data } = await axios({ url: `/designer/quotes/${id}` });
  if (data.success) {
    return data.data;
  } else {
    throw "Failed to fetch";
  }
}

export function fetchWordLists(dispatch) {
  return axios({
    url: "/designer/wordlist/withoutItems",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(uuiHelpAction.fetchWordLists(data.data));
    }
  });
}

export async function fetchWordList(id) {
  const { data } = await axios({ url: `/designer/wordlist/${id}` });
  if (data.success) {
    const item = data.data
    Utils.shuffle(item.values)
    return item;
  } else {
    throw "Failed to fetch";
  }
}

export function fetchNames(dispatch) {
  return axios({
    url: "/designer/names/withoutItems",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(uuiHelpAction.fetchNames(data.data));
    }
  });
}

export async function fetchName(id) {
  const { data } = await axios({ url: `/designer/names/${id}` });
  if (data.success) {
    return data.data;
  } else {
    throw "Failed to fetch";
  }
}

export function fetchStoryLines(dispatch) {
  return axios({
    url: "/designer/storylines/withoutItems",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(uuiHelpAction.fetchStoryLines(data.data));
    }
  });
}

export async function fetchStoryLine(id) {
  const { data } = await axios({ url: `/designer/storylines/${id}` });
  if (data.success) {
    const item = data.data;
    Utils.shuffle(item.values);
    item.values.forEach(v => {
      v.plots.forEach(p => {
        //Utils.shuffle(p.dialogues);
        Utils.shuffle(p.plotTwists);
      })
      Utils.shuffle(v.plots);
      Utils.shuffle(v.scenes);
    })
    return item;
  } else {
    throw "Failed to fetch";
  }
}

export function fetchCharacters(dispatch) {
  return axios({
    url: "/designer/characters/withoutItems",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(uuiHelpAction.fetchCharacters(data.data));
    }
  });
}

export async function fetchCharacter(id) {
  const { data } = await axios({ url: `/designer/characters/${id}` });
  if (data.success) {
    const item = data.data
    Utils.shuffle(item.values);
    return item;
  } else {
    throw "Failed to fetch";
  }
}