import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Editor from './modules/Editor/Editor';
import Home from "./modules/Home/Home";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ProductOrder from "./modules/Order/ProductOrder";
import { getBookBySlag } from "./modules/Books/BooksActions";
import AddCartScreen from "./modules/AddCartScreen/AddCartScreen"
import { fetchAllUserImages } from "./modules/Editor/ToolPanels/UploadUserImages/UploadImagesAction";
import HelmetProvider from "./components/Helmet/Helmet";

function SocketConnection(opt) {
    for (let pr in opt) {
        this[pr] = opt[pr];
    }
    if (!this.maxTries) {
        this.maxTries = 5;
    }
}

SocketConnection.prototype = {
    timeInterval: 8000,
    connect: function () {
        delete this.disconnect;
        let loc = window.location;
        let ws = new WebSocket(`${loc.protocol === "http:" ? "ws" : "wss"}://${loc.host}/designer/chat`);
        this.ws = ws;
        ws.onmessage = this.onmessage;
        ws.onclose = () => {
            if (!this.disconnect && this.maxTries-- > 0) {
                setTimeout(this.connect.bind(this), this.timeInterval);
            }
        };
    },
    close: function () {
        this.disconnect = true;
        this.ws?.close();
    }
};

function EditBookView(props) {
    const { match: { url, params }, location: { pathname } } = props;
    const urlSlag = params.urlSlag;
    const [book, setBook] = useState();

    const dispatch = useDispatch();
    useEffect(() => {
        if (urlSlag) {
            getBookBySlag(dispatch, urlSlag).then(setBook);
        }
    }, [urlSlag]);

    return <>
        {
            book && <div style={{ display: url === pathname ? 'contents' : 'none' }}>
                <Editor book={book} />
            </div>
        }
        <Route exact path={`${url}/order`} component={ProductOrder} />
    </>;
}

export default function Dashboard({ match: { url }, location: { pathname } }) {
    if (url === "/") {
        url = "";
    }

    const dispatch = useDispatch()
    useEffect(() => {
        fetchAllUserImages(dispatch);
    }, [])

    return <>
        <HelmetProvider
            title={"Bookalooza- Write, Publish and Earn through Books"}
            description={
                "Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book."
            }
            schema={
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Bookalooza",
                    "url": "https://www.bookalooza.com",
                    "logo": "https://www.bookalooza.com/logo.png",
                    "sameAs": [
                        "https://www.facebook.com/bookalooza",
                        "https://www.instagram.com/bookalooza/",
                        "https://www.youtube.com/@Bookalooza",
                        "https://www.linkedin.com/company/bookalooza/",
                        "https://x.com/bookalooza"
                    ],
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "contactType": "Customer Service",
                        "telephone": "+91-8799721408",
                        "email": "support@bookalooza.com"
                    }
                }}
        />
        <Switch>
            <Route path={`${url}/user`} component={Home} />
            <Route path={`${url}/edit/:urlSlag`} component={EditBookView} />
            <Route exact path={`${url}/change-password`} component={ChangePassword} />
            <Route exact path={`${url}/cart`} component={AddCartScreen} />
        </Switch></>;
}