import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
};

const uploadImagesSlice = createSlice({
    name: 'uploadImage',
    initialState,
    reducers: {
        fetchUserImages: (state, action) => {
            state.items = action.payload.images
        },
        deleteImage: (state, action) => {
            const id = action.payload;
            state.items = state.items.filter(img => img.id !== id);
        },
        uploadedImage: (state, action) => {
            const imageData = action.payload.images
            state.items.push(...imageData)
        }
    }
});

export const uploadImagesAction = uploadImagesSlice.actions;
export default uploadImagesSlice.reducer;