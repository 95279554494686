import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import ModalDialog from "../Dialog/Dialog";
import classes from './MessageDialog.module.css'

function MessageDialog({ title, message, isCancel, onCancel, onAccept, closeText = 'Cancel', acceptText = 'OK' }) {
    return <ModalDialog
        title={title}
        content={
            <div className={classes.message}>
                <p>{message}</p>
            </div>
        }
        footer={
            <>
                {
                    isCancel &&
                    <SecondaryButton onClick={onCancel}>{closeText}</SecondaryButton>
                }
                {
                    onAccept &&
                    <PrimaryButton onClick={onAccept} positive>{acceptText}</PrimaryButton>
                }
            </>
        }
    />;
}

export default MessageDialog;