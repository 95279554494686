import axios from "axios";
import { podcastActions } from "./PodcastsSlice";

const pageSize = 9;

export function fetchAllPodcasts(dispatch, filter) {
  return axios({
    url: `/designer/podcast?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  })
    .then((res) => {
      const { data, success } = res.data;
      if (success) {
        dispatch(podcastActions.fetchPodcasts(data));
      }
      return res;
    })
    .catch((err) => err);
}

export function fetchPodcastCount(filter) {
  return axios({
    url: `/designer/podcast/count?where=${encodeURIComponent(JSON.stringify(filter))}`
  }).then(res => res).catch(err => err);
}

export function fetchPodcast(dispatch, id) {
  return axios({
    url: `/designer/podcast/${id}`,
  })
    .then((res) => {
      const { data, success } = res.data;
      if (success) {
        dispatch(podcastActions.fetchPodcast(data));
      }
      return res;
    })
    .catch((err) => err);
}

export function createPodcast(dispatch, data) {
  return axios({
    url: `/designer/podcast`,
    method: "post",
    data: data,
    // headers: { "content-type": "multipart/form-data" },
  })
    .then((res) => {
      const { data, success } = res.data;
      if (success) {
        dispatch(podcastActions.createNewPodcast());
      }
      return res;
    })
    .catch((err) => err);
}

export function updatePodcast(dispatch, data) {
  return axios({
    url: `/designer/podcasts`,
    method: "put",
    data: data,
  })
    .then((res) => {
      const { data, success } = res.data;
      if (success) {
        dispatch(podcastActions.createNewPodcast());
      }
      return res;
    })
    .catch((err) => err);
}
