import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classes from './Library.module.css';
import UserDashboardHeader from '../UserDashboardHeader/UserDashboardHeader';
import { deleteBook, getAllBooks } from '../../BooksActions';
import LibraryBookCard from '../../../../components/LibraryBookCard/LibraryBookCard';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import Utils from '../../../../Utils';
import Consts from '../../../../Consts';
import MessageDialog from '../../../../components/MessageDialog/MessageDialog';


function Library() {
    const userDetails = useSelector(state => state.user);
    const books = useSelector(state => state.books.books) || [];
    const [showDeleteModal, setShowDeleteModal] = useState('')
    const history = useHistory();
    const dispatch = useDispatch();

    const incompleteBooks = books?.filter(b => b?.status === 'DRAFT');
    const inReviewsBooks = books?.filter(b => b?.status === 'REVIEW');
    const publishedBooks = books?.filter(b => b?.status === 'PUBLISHED');

    useEffect(() => {
        const userId = userDetails?.user?.userId;
        if (userId) {
            getAllBooks(dispatch, { where: { userId }, sortBy: 'updateDate DESC' });
        }
    }, [userDetails]);

    function editHandler(urlSlag) {
        history.push(`${Consts.BASE_URL}/edit/${urlSlag}`);
    }

    function bookDetailsHandler(urlSlag) {
        Utils.showBookDetails(history, urlSlag);
    }

    const dialogContent = {
        title: "Delete",
        message: "Are you sure you want to delete this book?"
    }

    return (
        <>
            <div className={classes.mainContainer}>
                <UserDashboardHeader
                    title="Library"
                    description="Manage all your books here."
                />
                <div className={classes.innerContainer}>
                    <div className={classes.publishedBooksWrapper}>
                        <h3 className={classes.publishedBooksTitle}>Published</h3>
                        {
                            Boolean(publishedBooks?.length) ?
                            <><div className={classes.bookList}>
                                {
                                    publishedBooks.map((book) => {
                                        return <LibraryBookCard
                                            key={book.id}
                                            book={book}
                                            onClick={() => bookDetailsHandler(book.urlSlag)}
                                            style={{ 'cursor': 'pointer' }}
                                        />;
                                    })
                                }
                            </div></> :
                            <div className={classes.emptyMessageWrapper}>
                                No book published yet.
                            </div>
                        }
                        {/* <div className={classes.buttonWrapper}>
                            <SecondaryButton
                                style={{ width: 'fit-content', height: '2.625rem', alignSelf: 'center' }}
                                disabled={publishedBooks.length < 6 ? true : false}
                            >Show More</SecondaryButton>
                        </div> */}
                    </div>
                    <div className={classes.inReviewsBooksWrapper} id='review'>
                        <h3 className={classes.inReviewsBooksTitle}>Under review</h3>
                        {
                            Boolean(inReviewsBooks?.length) ?
                            <><div className={classes.bookList}>
                                {
                                    inReviewsBooks.map((book) => {
                                        return <LibraryBookCard
                                            ratingsLikesViewsDisabled
                                            key={book.id}
                                            book={book}
                                        />;
                                    })
                                }
                            </div></> :
                            <div className={classes.emptyMessageWrapper}>
                               No book under review yet.
                            </div>
                        }
                        {/* <div className={classes.buttonWrapper}>
                            <SecondaryButton
                                style={{ width: 'fit-content', height: '2.625rem', alignSelf: 'center' }}
                                disabled={inReviewsBooks.length < 6 ? true : false}
                            >Show More</SecondaryButton>
                        </div> */}
                    </div>
                    <div className={classes.draftBooksWrapper}>
                        <h3 className={classes.draftBooksTitle}>Drafts</h3>
                        {
                            Boolean(incompleteBooks?.length) ?
                            <><div className={classes.bookList}>
                                {
                                    incompleteBooks.map((book) => {
                                        return <LibraryBookCard
                                            onDelete={() => setShowDeleteModal(book.id)}
                                            ratingsLikesViewsDisabled
                                            key={book.id}
                                            book={book}
                                            onClick={() => editHandler(book.urlSlag)}
                                            style={{ 'cursor': 'pointer' }}
                                        />;
                                    })
                                }
                            </div></> :
                            <div className={classes.emptyMessageWrapper}>
                                No book in drafts yet.
                            </div>
                        }
                        {/* <div className={classes.buttonWrapper}>
                            <SecondaryButton
                                style={{ width: 'fit-content', height: '2.625rem', alignSelf: 'center' }}
                                disabled={incompleteBooks.length < 6 ? true : false}
                            >Show More</SecondaryButton>
                        </div> */}
                    </div>
                </div>
                {
                    showDeleteModal && <MessageDialog {...dialogContent}
                        onCancel={() => setShowDeleteModal('')}
                        isCancel closeText="Close"
                        acceptText='Delete'
                        onAccept={() => {
                            deleteBook(dispatch, { id: showDeleteModal });
                            setShowDeleteModal('');
                        }}
                        width={Consts.MESSAAGE_DIALOG_WIDTH} height={Consts.MESSAAGE_DIALOG_WIDTH}
                    />
                }
            </div>
        </>
    );
}

export default Library;