import { createSlice } from "@reduxjs/toolkit";

const foregroundColorsSlice = createSlice({
  name: "foregroundColors",
  initialState: {
    library: [],
    libraryItems: [],
    defaultColor: '#000'
  },
  reducers: {
    fetchColors: (state, action) => {
      state.library = action.payload.library;
      state.libraryItems = action.payload.libraryItems;
    },
    setDefaultColor: (state, action) => {
      state.defaultColor = action.payload;
    }
  },
});

export const foregroundColorsActions = foregroundColorsSlice.actions;
export const foregroundColorsReducer = foregroundColorsSlice.reducer;

const backgroundColorsSlice = createSlice({
  name: "backgroundColors",
  initialState: {
    library: [],
    libraryItems: []
  },
  reducers: {
    fetchColors: (state, action) => {
      state.library = action.payload.library;
      state.libraryItems = action.payload.libraryItems;
    }
  },
});

export const backgroundColorsActions = backgroundColorsSlice.actions;
export const backgroundColorsReducer = backgroundColorsSlice.reducer;


const fontSlice = createSlice({
  name: "fonts",
  initialState: { libraries: [] },
  reducers: {
    fetchFonts: (state, action) => {
      state.libraries = action.payload.libraries || [];
    }
  },
});

export const fontsActions = fontSlice.actions;
export const fontsReducer = fontSlice.reducer;

const clipartSlice = createSlice({
  name: "clipart",
  initialState: { libraries: [] },
  reducers: {
    fetchCliparts: (state, action) => {
      state.libraries = action.payload.libraries || [];
    }
  },
});

export const clipartActions = clipartSlice.actions;
export const clipartReducer = clipartSlice.reducer;

const imageSlice = createSlice({
  name: "image",
  initialState: { libraries: [] },
  reducers: {
    fetchImages: (state, action) => {
      state.libraries = action.payload || [];
    }
  },
});

export const imageActions = imageSlice.actions;
export const imageReducer = imageSlice.reducer;

const shapeSlice = createSlice({
  name: "shape",
  initialState: { libraries: [] },
  reducers: {
    fetchShapes: (state, action) => {
      state.libraries = action.payload || [];
    }
  },
});

export const shapeActions = shapeSlice.actions;
export const shapeReducer = shapeSlice.reducer;