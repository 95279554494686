import { useState } from "react";
import classes from "./Storyline.module.css";
import AIData from "../../../../components/AIData/AIData";

export default function Dialogues({ values }) {
  return (
    <div className={classes.container}>
      <div className={classes.plotsMain}>
        {values?.map((plot) => (<AIData key={plot} text={plot} />))}
      </div>
    </div>
  );
}
