import { configureStore } from '@reduxjs/toolkit';
import booksReducer from "./App/modules/Books/BooksSlice";
import themesReducer from './App/modules/Books/NewBook/ThemeSlice'
import uploadImageReducer from './App/modules/Editor/ToolPanels/UploadUserImages/UploadImageSlice'
import orderReducer from "./App/modules/Order/ProductOrderSlice";
import { foregroundColorsReducer, backgroundColorsReducer, fontsReducer, clipartReducer, imageReducer, shapeReducer } from './App/modules/Editor/EditorSlice';
import usersReducer from './App/modules/Users/UsersSlice';
import addCartActions from './App/modules/AddCartScreen/AddCartSlice';
import taggedImageReducer from './App/modules/Editor/ToolPanels/SearchImage/TaggedImageSlice';
import aboutReducer from './App/modules/Editor/ToolPanels/Author/AboutAuthorSlice';
import galleryEventsReducer from './App/modules/NewsRoom/Gallery/GallerySlice';
import blogEventsReducer from './App/modules/Blogs/BlogsSlice';
import aiHelpReducer from './App/modules/Editor/AIHelp/AIHelpSlice';
import podcastReducer from "./App/modules/Podcasts/PodcastsSlice"
// Configure our store combining all the state reducers.
export const store = configureStore({
  reducer: {
    books: booksReducer,
    foregroundColors: foregroundColorsReducer,
    backgroundColors: backgroundColorsReducer,
    fonts: fontsReducer,
    cliparts: clipartReducer,
    shapes: shapeReducer,
    images: imageReducer,
    user: usersReducer,
    order: orderReducer,
    addCart: addCartActions,
    themes: themesReducer,
    uploadImage: uploadImageReducer,
    taggedImage: taggedImageReducer,
    aboutAuthor: aboutReducer,
    galleryEvents: galleryEventsReducer,
    blogEvents: blogEventsReducer,
    aiHelp: aiHelpReducer,
    podcast: podcastReducer
  }
});
