import { useEffect, useState } from "react";

function useScreenSize() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [breakPoint, setBreakPoint] = useState("md");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenSize(screenWidth);
      setIsSmallScreen(screenWidth < 769);
      setBreakPoint(
        screenWidth < 576
          ? "xs"
          : screenWidth >= 576 && screenWidth < 768
            ? "sm"
            : screenWidth >= 768 && screenWidth < 992
              ? "md"
              : screenWidth >= 992 && screenWidth < 1200
                ? "lg"
                : screenWidth >= 1200 && screenWidth < 1400
                  ? "xl"
                  : screenWidth >= 1400
                    ? "xxl"
                    : "md"
      );
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { screenSize, isSmallScreen, breakPoint };
}

export default useScreenSize;
