import React from 'react'
import classes from './SocialMediaShareButtons.module.css';
import faceBook from '../../assets/social/share-social-media/facebook.png';
import whatsapp from '../../assets/social/share-social-media/whatsapp.png';
import telegram from '../../assets/social/share-social-media/telegram.png';
import linkedIn from "../../assets/social/share-social-media/linkedin.png"


const SocialMediaShareButtons = ({blogUrlSlug, className, style}) => {
  const shareUrl = `${window.origin}/designer/share/blog/slug/${blogUrlSlug}`;
  const faceBookShare = `${shareUrl}?ogUrl=${encodeURIComponent('https://hi-in.facebook.com/')}`;
  return (
    <div className={className} style={style}>
    <a  href={`https://www.facebook.com/sharer/sharer.php?u=${faceBookShare}`} className={classes.facebook} target='_blank'><img className={classes.socialMediaIcon} src={faceBook} alt="facebook" /></a> 
    <a href={`https://api.whatsapp.com/send?text=${shareUrl}`} className={classes.whatsapp} target='_blank'><img className={classes.socialMediaIcon} src={whatsapp} alt="whatsapp" /></a> 
    <a href={`https://telegram.me/share/url?url=${shareUrl}`} className={classes.telegram} target='_blank'><img className={classes.socialMediaIcon} src={telegram} alt="telegram" /></a> 
    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} className={classes.linkedin} target='_blank'><img className={classes.socialMediaIcon} src={linkedIn} alt="linkedIn" /></a> 
    
  </div>
  )
}

export default SocialMediaShareButtons