import React, { useEffect, useState } from 'react'
import { getContent } from '../../modules/Blogs/BlogsActions';

const HtmlViewer = ({ src, data }) => {
  const [blogContent, setBlogContent] = useState();
  useEffect(() => {
    if (src) {
      getContent(src).then(setBlogContent);
    } else if (data) {
      setBlogContent(data);
    }
  }, [src, data]);

  return (
    <div className={"ql-editor"} dangerouslySetInnerHTML={{ __html: blogContent }} />
  )
}

export default HtmlViewer