import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSchoolDetails } from "../../modules/Users/UsersActions";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card";
import classes from "./SignUp.module.css";
import Input from "../Input/Input";
import Password from "../Password/Password";
import PrimaryButton from "../Buttons/PrimaryButton";
import ImageDropDown from "../ImageDropDown/ImageDropDown";
import indianFlag from "../../assets/flags/IN.png";
import Utils from "../../Utils";
import LinkButton from "../Buttons/LinkButton";
import schoolIcon from "../../assets/icon-school.svg"

const country = [
  {
    country: "India",
    countryCode: "+91",
    imageSrc: indianFlag,
  },
];

export default function InfoForm({ isEmailValid, onSubmit, config, goToLogin }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const linkId = Utils.getValueFromSearchParam(location.search, "link");
  const school = useSelector((state) => state.user.schoolDetails || {})
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    userId: "",
    instituteName: "",
    rePassword: "",
    password: "",
    phoneNumber: "",
  });
  const [errorObj, setErrorObj] = useState({
    fullName: "",
    userId: "",
    instituteName: "",
    rePassword: "",
    password: "",
    phoneNumber: "",
  });

  const fullName = userDetails?.fullName || "";
  const firstName = fullName?.split?.(" ")?.[0];
  const lastName = fullName?.split?.(" ")?.[1] || "";

  useEffect(() => {
    if (linkId) {
      getSchoolDetails(dispatch, linkId)
    }
  }, [])

  const onInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "firstName") {
      name = 'fullName';
      value = `${e.target.value} ${lastName}`.trim();
    }

    if (name === "lastName") {
      name = 'fullName';
      value = `${firstName} ${e.target.value}`.trim();
    }

    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };


  const validateInput = (e) => {
    let { name, value } = e.target;
    setErrorObj((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter first name.";
          }
          break;
        case "lastName":
          if (!value) {
            stateObj[name] = "Please enter last name.";
          }
          break;
        case "userId":
          if (!value) {
            stateObj[name] = "Please enter email.";
          }
          break;
        case "instituteName":
          if (!value) {
            stateObj[name] = "Please enter school name."
          }
          break;
        case "rePassword":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (
            userDetails.rePassword &&
            value !== userDetails.rePassword
          ) {
            stateObj[name] = "Password doesn't match";
          }
          break;
        case "phoneNumber":
          const pValue = userDetails.phoneNumber.toString()
          if (!value) {
            stateObj[name] = "Please enter your phone number.";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const checkValidEmail = (value) => {
    if (value) {
      setErrorObj(prevState => ({
        ...prevState,
        userId: Utils.validateEmail(value).message
      }))
    }
  }

  const checkValidPhoneNumber = (value) => {
    if (value && value.toString().length !== 10) {
      setErrorObj(prevState => ({
        ...prevState,
        phoneNumber: "Mobile number must be 10 digits."
      }))
      return false;
    }
    return true;
  }

  const passwordMatches = userDetails.password === userDetails.rePassword;

  const handleNext = () => {
    if (checkValidPhoneNumber(userDetails.phoneNumber)) {
      if (passwordMatches) {
        let val = { ...userDetails };
        if (linkId) {
          val.linkId = linkId
        }
        delete val.rePassword;
        onSubmit?.(val);
      }
    }
  }

  return (
    <Card borderRadius="24px" backColor="white" padding="4rem 4rem">
      <div className={classes.main}>
        <div className={classes.heading}>
          <h2>{config.heading}</h2>
          {linkId && <div className={classes.schoolName}><img src={schoolIcon} /> {school?.school?.name}</div>}
          <p>{config.subHeading}</p>
        </div>
        <div className={classes.container}>
          {config.fullname && (
            <div className={classes.nameInputsWrapper}>
              <Input
                placeholder="First name"
                name="firstName"
                onChange={(e) => onInputChange(e)}
              />
              <Input
                placeholder="Last name"
                name="lastName"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          )}

          {errorObj.firstName && (
            <span className={classes.errorMsg}>{errorObj.firstName}</span>
          )}
          {errorObj.lastName && (
            <span className={classes.errorMsg}>{errorObj.lastName}</span>
          )}
          {!linkId && config.schoolName && (
            <>
              <Input
                placeholder="School Name"
                name="instituteName"
                onChange={(e) => onInputChange(e)}
                value={userDetails.instituteName}
                onBlur={(e) => checkValidEmail(e.target.value)}
              />
              {errorObj.instituteName && (
                <span className={classes.errorMsg}>{errorObj.instituteName}</span>
              )}
            </>
          )}
          <Input
            placeholder={config.heading !== "Forgot Password" ? "Enter Email" : "Enter Email or UserID"}
            name="userId"
            onChange={(e) => onInputChange(e)}
            value={userDetails.userId}
            onBlur={(e) => config.heading !== "Forgot Password" && checkValidEmail(e.target.value)}
          />
          {!isEmailValid && (
            <p className={classes.errorMsg}>Email already exists.</p>
          )}
          {errorObj.userId && (
            <span className={classes.errorMsg}>{errorObj.userId}</span>
          )}
          <Password
            placeholder="Create password"
            togglePasswordVisibility={true}
            height="2.25rem"
            name="rePassword"
            onChange={(e) => onInputChange(e)}
          />
          {errorObj.rePassword && (
            <span className={classes.errorMsg}>{errorObj.rePassword}</span>
          )}
          <Password
            placeholder="Confirm Password"
            togglePasswordVisibility={true}
            name="password"
            height="2.25rem"
            onPaste={(e) => e.preventDefault()}
            onChange={(e) => onInputChange(e)}
            autoComplete="off"
          />
          {errorObj.password && (
            <span className={classes.errorMsg}>{errorObj.password}</span>
          )}
          {config.phoneNumber && (
            <div className={classes.numberFieldWrapper}>
              <ImageDropDown
                className={classes.countryDropDown}
                options={country}
              />
              <span className={classes.divider}></span>
              <Input
                type="number"
                placeholder="Phone Number"
                onChange={(e) => onInputChange(e)}
                value={userDetails.phoneNumber}
                name="phoneNumber"
                onKeyDown={(e) => {
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                }}
              />
            </div>
          )}
          {errorObj.phoneNumber && (
            <span className={classes.errorMsg}>{errorObj.phoneNumber}</span>
          )}
          <div className={classes.actions} style={{ justifyContent: "center" }}>
            <PrimaryButton
              onClick={handleNext}
              style={{ width: "100%", height: "2.5rem", fontSize: "0.875rem" }}
              disabled={
                !passwordMatches || !userDetails.password ||
                (!userDetails.fullName && config.fullname) ||
                !userDetails.userId ||
                errorObj.userId ||
                (!userDetails.phoneNumber && config.phoneNumber) ||
                (!userDetails.instituteName && !linkId && config.schoolName) 
              }
            >
              Next
            </PrimaryButton>
          </div>
          <div className={classes.signIn}>
            <span> Already have an account? </span>
            <LinkButton onClick={goToLogin} label="Log in here" />
          </div>
        </div>
      </div>
    </Card>
  );
}
