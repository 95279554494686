import axios from '../../../../../axios';

/*
Get Order history by user
*/
export async function getBankDetails(filter){
  const createOrderUrl = `/designer/bank?filter=${encodeURIComponent(JSON.stringify(filter))}`;
  return axios({
      url: createOrderUrl,
      method: "get",
  }).then((response) => {
      if(response.status == 200){
          return response;
      }else{
          throw 'Something went wrong. Please try again';
      }
  });
}

export async function saveBankDetail(params){
  const addBankUrl = "/designer/bank/add";
  const postData = params;
  return axios({
      url: addBankUrl,
      method: "post",
      data: postData,
      reponseType: "json"
  }).then((response) => {
      if (response.data) {
          return response.data;
      } else {
          throw 'Something went wrong. Please try again';
      }
  });
}



export function deleteBank(id) {
  return axios({
    url: `/designer/bank/delete/${id}`,
    method: "delete",
    reponseType: "json"
  }).then((response) => {
    if (response.data) {
      return response.data;
    }else{
      throw 'Something went wrong. Please try again';
    }
  })
    .catch((error) => {
      return error;
    });
}