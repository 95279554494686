import React from 'react'
import classes from './StaticHeader.module.css'
import Lottie from 'react-lottie-player'

function StaticHeader({ title, description, animation }) {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.headingAndDescriptionWrapper}>
          <h1 className={classes.heading}>{title}</h1>
          <p className={classes.description}>{description}</p>
        </div>
        <div className={classes.animationWrapper}>
          <Lottie loop animationData={animation} play className={classes.lottie} />
        </div>
      </div>
    </>
  )
}

export default StaticHeader;