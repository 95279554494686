import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allThemes: [],
    trendingThemes: [],
    popularThemes: [],
    preface: [],
    lastPage: [],
    backPage: [],
    backgrounds: [],
};

const themesSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        fetchTrendingThemes: (state, action) => {
            state.trendingThemes = action.payload.themes
        },
        fetchPopularThemes: (state, action) => {
            state.popularThemes = action.payload.themes
        },
        fetchAllThemes: (state, action) => {
            state.allThemes = action.payload.themes
        },
        fetchPreface: (state, action) => {
            state.preface = action.payload.themes
        },
        fetchLastPage: (state, action) => {
            state.lastPage = action.payload.themes
        },
        fetchBackPage: (state, action) => {
            state.backPage = action.payload.themes
        },
        fetchBackGround: (state, action) => {
            state.backgrounds = action.payload.themes;
        }
    }
});

export const themesAction = themesSlice.actions;
export default themesSlice.reducer;