import React from "react";
import classes from "../HomeScreen.module.css";
import editorChoiceBanner from "../../../assets/Bookalooza-Editor-Choice-Banner.jpg";
import { Link } from "react-router-dom";

function EditorsChoice() {
  return (
    <div className={classes.editorChoiceMain}>
    <h3 className={classes.editorChoiceHeading}>Editor’s Choice: Top Books</h3>
      <div className={classes.imageContainer}>
        <div className={classes.editorsChoiceWrapper}>
          <Link
            className={classes.editorsChoiceImageContainer}
            to={`/details/who-is-the-murderer`}
          />
          <Link
            className={classes.editorsChoiceImageContainer}
            to={`/details/a-trip-to-the-jungle`}
          />
          <Link
            className={classes.editorsChoiceImageContainer}
            to={`/details/whispers-from-the-attic`}
          />
          <Link
            className={classes.editorsChoiceImageContainer}
            to={`/details/independent-wings`}
          />
        </div>
        <img src={editorChoiceBanner} style={{ width: "100%" }} alt="" />
      </div>
    </div>
  );
}

export default EditorsChoice;
