import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import { store } from "./store";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import browserHistory from "./history";
import ScrollToTop from "./App/components/ScrollToTop/ScrollToTop";
import { Route, Switch } from 'react-router-dom';
import Campaign from "./App/modules/Campaign/Campaign";
import Consts from "./App/Consts";
import ThankYou from "./App/modules/ThankYou/ThankYou";

const rootElement = document.getElementById("root");
const url = Consts.BASE_URL;
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={browserHistory}>
        <ScrollToTop />
        <Switch>
          <Route path={`${url}/campaign`} component={Campaign} />
          <Route exact path={`${url}/student/thankyou`} component={ThankYou}/>
          <Route exact path={`${url}/school/thankyou`} component={ThankYou}/>
          <Route path={url} component={App} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  rootElement
);