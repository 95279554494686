import { createSlice } from "@reduxjs/toolkit";
import Utils from "../../Utils";

const usersSlice = createSlice({
  name: "user",
  initialState: {
    notifications: [],
    starAuthors: [],
    schoolDetails: {},
    user: {}
  },
  reducers: {
    fetchProfile: (state, action) => {
      Object.assign(state, action.payload);
      Utils.setCurrentUser(action.payload.user);
    },
    fetchNotifications: (state, action) => {
      state.notifications = action.payload.notifications;
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (i) => i.id !== action.payload.id
      );
    },
    fetchStarAuthor: (state, action) => {
      state.starAuthors = action.payload.authors;
    },
    fetchSchoolDetails: (state, action) => {
      state.schoolDetails = action.payload;
    }
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
