import axios from "../../../axios";

export async function createOrder(params) {
    const createOrderUrl = "/designer/orders/create";
    const postData = params;
    return axios({
        url: createOrderUrl,
        method: "post",
        data: postData,
    }).then((response) => {
        if (response.data) {
            return response.data;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function createProofReadingOrder(id) {
    const createOrderUrl = `/designer/proofreading/createOrder/${id}`;
    return axios({
        url: createOrderUrl,
        method: "post",
    }).then((response) => {
        if (response.data) {
            return response.data;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function updateProofreadingStatus(data, id) {
    const createOrderUrl = `/designer/proofreading/updateStatus/${id}`;
    return axios({
        url: createOrderUrl,
        method: "post",
        data: data
    }).then((response) => {
        if (response) {
            return response;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function updateOrderStatus(data) {
    const updateOrderStatusUrl = "/designer/orders/updateStatus";
    return axios({
        url: updateOrderStatusUrl,
        method: "post",
        data
    }).then((response) => {
        if (response) {
            return response;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function schoolRecharge() {
    return axios({
        url: "/designer/school/studentFee",
        method: "post",
    }).then((res) => res.data)
}

export async function updateRechargeStatus(data) {
    return axios({
        url: "/designer/school/updatePaymentStatus",
        method: "post",
        data
    }).then((response) => {
        if (response) {
            return response;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}