
import packageJson from '../../package.json'

const Consts = {
    SENT_FOR_REVEIW: "sent_for_review",
    IN_PROGRESS: "in_progress",
    APPROVE: 'approve',
    MESSAAGE_DIALOG_WIDTH: '19rem',
    APP_VERSION: '1.0.1.46',
    BASE_URL: packageJson.homepage || ''
}
export default Consts;