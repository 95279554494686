import React, { useEffect, useRef, useState } from 'react';
import classes from './Campaign.module.css';
import NavBar from './NavBar';
import aloozaCharacter from '../../assets/campaign/aloozaCharacter.png';
import logo from '../../assets/campaign/Bookalooza-New-Logo_White.png';
import whyBookAlooza from '../../assets/campaign/why bookalooza  section -image.png';
import unlockSectionInfographics from '../../assets/campaign/Unlock section Infographics with text.png';
import WorkShopForm from '../../components/WorkShopForm/WorkShopForm';
import intrustingSection from '../../assets/campaign/TopInteresting.png'
import royaltySectionBackground from '../../assets/campaign/RoyaltySectionBackground.png'
import { useDispatch, useSelector } from 'react-redux';
import { getHomeScreenBooks } from '../Books/BooksActions';
import BookItem from '../../components/BookItem/BookItem';
import Utils from '../../Utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchAllBlogEvents } from '../Blogs/BlogsActions';
import Consts from '../../Consts';
import BuddingCard from '../../components/BuddingCard/BuddingCard';
import Input from '../../components/Input/Input';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { subscriberRegistration } from './CampaignActions';
import footerBack from '../../assets/campaign/footerBack.png';
import footer from '../../assets/campaign/footer.png';
import { SocialMedia } from '../../components/MainFooter/MainFooter';
import ModalDialog from '../../components/Dialog/Dialog';
import aarav from '../../assets/campaign/Aarav Chaturvedi.jpg';
import arpan from '../../assets/campaign/Arpan Singh.jpeg';
import saakar from '../../assets/campaign/Saakaar Upadhyay.jpeg';

import amita from '../../assets/campaign/Amita Wadhwani.jpg';
import richa from '../../assets/campaign/Richa Sood.jpg';
import seema from '../../assets/campaign/Seema Negi.jpeg';
import seemaWahi from '../../assets/campaign/Seema Wahi Mukherjee.jpeg';
import simi from '../../assets/campaign/Simi Srivastava.jpeg';
import usha from '../../assets/campaign/Usha-Chhabra.jpeg';

import aster from '../../assets/campaign/aster-logo.png';
import goenka from '../../assets/campaign/gd-goenka.jpg';
import lotusValley from '../../assets/campaign/lotus-valley.jpg';
import manavSthali from '../../assets/campaign/manav-sthali.png';
import dps from '../../assets/campaign/delhi -public.png';
import stXaviers from '../../assets/campaign/slogo8.png';
import newEra from '../../assets/campaign/New Era School.png';
import pragyan from '../../assets/campaign/PragyanSchool.png';
import advance from '../../assets/campaign/advance-academy.jpg';
import MessageDialog from '../../components/MessageDialog/MessageDialog';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';
import authorImage from "../../assets/campaign/Author_Image.png";

const menuItems = [
    {
        title: "Write",
        onClick: e => Utils.scrollTo('#write')
    },
    {
        title: "Publish",
        onClick: e => Utils.scrollTo('#publish')
    },
    {
        title: "Earn",
        onClick: e => Utils.scrollTo('#earn')
    },
    {
        title: "Register",
        onClick: e => Utils.scrollTo('#register')
    },
    {
        title: "Contact us",
        onClick: e => Utils.scrollTo('#contactUs')
    }
];

const speakers = [
    {
        image: simi,
        name: 'Simi Srivastav',
        designation: 'Master Storyteller, Founder Kathashala, Teacher Trainer, Educationist, Author, Curriculum Designer & Story Therapist'
    },
    {
        image: usha,
        name: 'Usha Chhabra',
        designation: 'Author, Educator & Storyteller'
    },{
        image: seemaWahi,
        name: 'Seema Wahi Mukherjee',
        designation: 'International Storyteller, Puppeteer, Toy Pedagogy Expert, Educationist & Teacher Trainer'
    },{
        image: richa,
        name: 'Dr. Richa Sood',
        designation: 'Principal Director Royal Kids School & CBSE National Awardee'
    },{
        image: seema,
        name: 'Dr. Seema Negi',
        designation: 'Principal Director Sanjeevani World School & Global Goodwill Ambassador'
    },{
        image: amita,
        name: 'Amita Wadhwani',
        designation: 'BA English ( Hons), MBA in Marketing, Copywriter and a Storyteller'
    }
];

function Speaker({info}) {
    return <div className={classes.eminentSpeakersBox}>
        <div className={classes.eminentImage}>
            <img src={info.image} alt='eminent speakers'/>
        </div>
        <div className={classes.eminentName}>{info.name}</div>
        <div className={classes.eminentDesignation}>{info.designation}</div>
    </div>
}

const schools = [
    {url: aster},
    {url: goenka},
    {url: lotusValley},
    {url: stXaviers},
    {url: manavSthali},
    {url: dps},
    {url: pragyan},
    {url: newEra},
    {url: advance},
];

function School({info}) {
    return <div>
        <img src={info.url} style={{width: '8rem'}}/>
    </div>
}

function EmailSender({onSubmit, onClose}) {
    const [formData, setFormData] = useState({});
    const [error, setError] =  useState(null)
    return <ModalDialog
        title={"Subscribe"}
        onClose={onClose}
        content={
            <div className={classes.subscribeForm}>
                <div>
                <Input name='name' value={formData.name} placeholder="Enter full name*"
                        onChange={e => setFormData(s => ({...s, name: e.target.value}))} />
                </div>
                <div>
                    <Input name='email' value={formData.email} placeholder="Enter email*"
                    onChange={e => setFormData(s => ({...s, email: e.target.value}))} 
                />
                </div>

                <div>
                <Input
                    type="number"
                    placeholder="Enter phone number*"
                    onChange={e => setFormData(s => ({...s, phoneNumber: e.target.value}))} 
                    value={formData.phoneNumber}
                    name="phoneNumber"
                    onKeyDown={(e) =>{
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                    }}
                />
                 </div>
                {error && <div className={classes.errorMsg}>
                    <span>*{error}</span>
                </div>}
                <div>
                <PrimaryButton onClick={e => { 
                    try {
                        Utils.validateName(formData.name);
                        Utils.validateEmailId(formData.email);
                        Utils.validatePhone(formData.phoneNumber);
                        onSubmit?.(formData);
                        onClose?.();
                    } catch (error) {
                        console.log(error);
                        setError(error.msg)
                    }
                }}>Submit</PrimaryButton>
                </div>
            </div>  
        }
        maxWidth='calc(100%-64px)'
    />;
}

function Header() {
    return <div className={classes.header}>
        <img src={logo} />
        <NavBar menuItems={menuItems} className={classes.navBar}/>
    </div>
}

const url = Consts.BASE_URL;
export default function Campaign({location:{hash}}) {
    const ratedBooks = useSelector(state => state.books.homeScreenBooks) || [];
    const allBlogEvents = useSelector((state) => state.blogEvents.allBlogsList) || [];
    const [type, setType] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [registrationMessage, setRegistrationMessage] = useState({
      title:"",
      message:""
    })
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!ratedBooks.length) {
            getHomeScreenBooks(dispatch, `overallRating DESC`);
        }
        if(!allBlogEvents.length){
            fetchAllBlogEvents(dispatch);
        }
    }, [])

    useEffect(() => {
        if (hash) {
          Utils.scrollTo(hash);
        }
      }, [hash])

    return <>
        <div className={classes.section1}>
            <Header />
            <ScrollToTopButton/>
            <div className={classes.headingContainer}>
                <div className={classes.heroContainer}>
                    <div className={classes.heading1}>Discover the joy of self-publishing with Bookalooza</div>
                    <div className={classes.link} onClick={e => setType('ebook')}>Download Free E-Book on Creative Writing</div>
                </div>
                <img src={aloozaCharacter} className={classes.alooza}/>
            </div>
        </div>
        <div id="write" className={classes.section2} style={{padding:'2rem'}}>
            <div className={classes.potentialContainer}>
                <div className={classes.potentialContent}>
                    <h1 >Unlock Your Potential with Bookalooza</h1>
                    <p className={classes.unlockContentLeft}>
                        Bookalooza is your personal portal to the
                        world of self-publishing, tailor-made for
                        young, imaginative writers like you. Having a
                        clear structure is the most important part in
                        your writing. Having a good plan for what you
                        want to say is vital, that's true!
                    </p>
                </div>
                <div className={classes.potentialContent} style={{alignItems:"center"}}>
                    <h1>With our Platform, you can:</h1>
                        <img src={unlockSectionInfographics} alt="bookalooza unlock content points" className={classes.unlockContent}/>
                   
                </div>
            </div>
        </div>
        <div>
            <div id="publish" className={classes.publishContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <div >
                    <div className={classes.heading1} style={{color: 'var(--landingPage-text)'}}>Why Bookalooza?</div>
                    <div className={classes.content} style={{color: '#48769E'}}>
                        Bookalooza is a place where your ideas
                        flourish, where your creativity is celebrated,
                        and where your words can reach
                        the world. With Bookalooza, you have the
                        freedom to choose from a variety of captivating
                        book styles that perfectly complement
                        your story. Plus, we've got awesome
                        sauce i.e nifty tools to help you write and
                        edit, making it a breeze to craft a fantastic
                        book. What's more? When your book is all
                        set and ready, we'll publish it for you.
                        <b style={{color: 'var(--landingPage-text)'}}> And here's the cherry on top</b>: for every
                        copy sold, you earn a sweet 10% royalty!
                    </div>
                </div>
                    <div className={classes.whyBookAloozaImage}>
                        <img src={whyBookAlooza} />
                    </div>
            </div>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <div className={classes.heading1} style={{color:"var(--landingPage-text)", textAlign:'center'}}>Register For Creative Writing Workshop</div>
            </div>
            <div id="register" className={classes.formContainer} >
                <div className={classes.workShopFormsContainer}>
                     <h2>Student Registration Form</h2>
                    <WorkShopForm
                        workShopName={"student"}
                        inputClassName={`${classes.formInputBox} ${classes.formInputBoxStudent}`}
                        source={"landing"}
                        buttonStyle = {classes.formButton}
                        icon={true}

                    />
                </div>
                <div className={classes.workShopFormsContainer}> 
                    <h2>School Registration Form</h2>
                    <WorkShopForm
                        workShopName={"school"}
                        inputClassName={classes.formInputBox}
                        source={"landing"}
                        buttonStyle = {classes.formButton}
                        icon={true}
                    />
                </div>
            </div>
            <div  className={classes.featureContainer}>
                <div className={classes.workShopFormsContainer} >
                   <h2>Imagine, Create & Publish - It's that easy!</h2> 
                   <p>
                    At Bookalooza, we believe that every young writer deserves a chance to shine. Our intuitive platform allows you to effortlessly
                    choose a book template and weave your story with the assistance of AI-powered tools. With just a few clicks, watch your vision come to life.
                   </p>
                </div>

                <div className={classes.workShopFormsContainer} >
                   <h2>AI-Assisted Writing and Editing</h2> 
                   <p>
                    No need to worry about writer's block or editing woes! Bookalooza provides AI tools to help you craft and polish your story,
                    ensuring it's not just great, but exceptional. We're here to make your writing journey smooth and exciting. Once you submit your 
                    book, our team of editors carefully polish your content and share the status.
                    </p>
                </div>

                <div className={classes.workShopFormsContainer} >
                   <h2>Showcase Your Talent and Earn Royalties</h2> 
                   <p>
                    Once your book is ready,let the world discover your creativity. For every sale, you'll earn a remarkable 10% royalty. It's an opportunity to share your talent
                   and earn recognition!
                    </p>
                </div>
            </div>
        </div>
        <div className={classes.section2} style={{overflow:"hidden", backgroundColor:"rgb(63, 33, 123)"}}>
            <div className={classes.joinContainer}>
                <div className={classes.joinLeftPart}>
                    <h1>Join the Bookalooza Family Today!</h1>
                    <p className={classes.content}>
                        Take the first step towards becoming a published author.
                        Sign up with Bookalooza now and start crafting the book
                        of your dreams. Let your imagination run wild, and we'll
                        help you refine it into a literary masterpiece.
                    </p>
                    <div className={classes.registerButton}><button onClick={()=> Utils.scrollTo('#register')}>Register Now</button></div>
                </div>
                <div className={classes.joinRightPart}>
                    {/* <h1>STAR AUTHORS</h1> */}
                    <div>
                        <img src={authorImage} alt='authors'/>
                    </div>
                    {/* <div>
                        <div className={classes.starAuthorContainer}>
                    <div className={classes.starAuthors} >
                        <div >
                            <div className={classes.outerCircle}>
                            <div className={classes.authorImage}>
                             <img src={aarav} alt='Author Image' />
                            </div>
                            </div>
                        </div>
                        <div>
                        <h1 className={classes.authorName} style={{color:"orange"}}>
                            Aarav Chaturvedi
                        </h1>
                        </div>
                    </div>
                    <div className={`${classes.starAuthors} ${classes.secondAuthor}`} >
                        <div >
                            <div className={classes.outerCircle} style={{borderColor:"#84FEB8"}}>
                            <div className={classes.authorImage}>
                             <img src={arpan} alt='Author Image' />
                            </div>
                            </div>
                        </div>
                        <div>
                        <h1 className={classes.authorName} style={{color:"#84FEB8"}}>
                            Arpan Singh
                        </h1>
                        </div>
                    </div>
                    <div className={classes.starAuthors}>
                        <div >
                            <div className={classes.outerCircle} style={{borderColor:"#01A3E0"}}>
                            <div className={classes.authorImage}>
                             <img src={saakar} alt='Author Image' />
                            </div>
                            </div>
                        </div>
                        <div>
                        <h1 className={classes.authorName} style={{color:"#01A3E0"}}>
                           Saakaar Upadhyay
                        </h1>
                        </div>
                    </div>
                    </div>
                    </div> */}
                </div>
            </div>
        </div>
        <div className={classes.eminentSpeakerContainer}>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <h1 className={classes.heading1} style={{color:'var(--landingPage-text)'}}>Eminent Speakers</h1>
            </div>
            <div className={classes.eminentSpeakers}>
                {speakers.map((s, idx) => <Speaker key={idx} info={s}/>)}
            </div>
        </div>
        <div id="earn" style={{backgroundImage: `url(${royaltySectionBackground})`, padding: '3rem'}}>
            <div className={`${classes.headingContainer} ${classes.earnHeading}`}>
                <div className={classes.heading1}>Write, Publish and Earn a <span style={{color: 'orange'}}>10% Royalty</span><div>for Every Sale!</div></div>
            </div>
            <div className={`${classes.heading1} ${classes.earnContent}`}>
                <div>Turn your stories into a paycheck.</div>
                <div>It's time to let your words create magic and fill your pockets too.</div>
            </div>
            <div style={{height: '10rem'}}/>
        </div>
        <div style={{backgroundImage: `url(${intrustingSection})`, backgroundSize: 'cover', paddingBottom: '3rem'}}>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <div className={classes.heading1}>Top Interesting Books</div>
            </div>
            <div className={classes.bookList}>
                {
                    ratedBooks?.length > 0 ? 
                        ratedBooks.map(book => (
                            <BookItem
                                key={book.id}
                                book={book}
                            />
                        )) 
                        : 
                        <p>No rated books available.</p>
                }
            </div>
        </div>
        <div className={classes.schoolsListContainer}>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <div className={classes.heading1} style={{color:'var(--landingPage-text)'}}>Happy Schools</div>
            </div>
            <div className={classes.schoolsList} style={{ justifyContent: 'center'}}>
                {schools.map((s, idx) => <School key={idx} info={s}/>)}
            </div>
        </div>
        <div>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center'}}>
                <div className={classes.heading1} style={{color:'var(--landingPage-text)'}}>Latest from Bookalooza</div>
            </div>
            <div className={classes.headingContainer} style={{padding: '2rem', justifyContent: 'center', gap: '1rem'}}>
                {allBlogEvents.slice(0,3).map(blog => 
                    <BuddingCard
                        key={blog?.id}
                        image={blog?.bannerImagePath}
                        title={blog?.title}
                        onClick={()=> history.push(`${url}/blogs/${blog?.urlSlug}`)}
                        blogCard={true}
                    />
                )}
                <div className={classes.newsLetterBox}>
                    <h3>SIGN UP FOR THE WEEKLY NEWSLETTER</h3>
                    <div>
                        <p>Free Writing Tips Every Week.</p>
                        <p>Learn to write more persuasive content.</p>
                    </div>
                   
                    <PrimaryButton onClick={e => setType('newsLetter')}>Subscribe</PrimaryButton>
                </div>
            </div>
        </div>
        <div id="contactUs" style={{backgroundImage: `url(${footerBack})`, backgroundSize: 'cover', padding: '3rem'}}>
            <div className={classes.footer}>
                <img src={footer} />
            </div>
            <SocialMedia />
        </div>
        {
            type && <EmailSender onClose={e => setType('')} onSubmit={data => {
                subscriberRegistration({...data, type}).then((res)=> {
                   
                    if(type!=="ebook"){
                        setShowSuccess(true);
                        setRegistrationMessage({
                            title: "Success",
                            message: "Thanks for subscribing to Bookalooza’s weekly newsletter" 
                          })
                    }
                 
                  });
                if (type == 'ebook') {
                    Utils.downloadFromURL('/CreativeWriting&StorytellingE-Book.pdf', 'CreativeWriting&StorytellingE-Book.pdf');
                }
            }}/>
        }

        { showSuccess && <MessageDialog title = {registrationMessage.title}  message={registrationMessage.message}
             onCancel={() => setShowSuccess(false)}
            isCancel closeText="Close"
            width={Consts.MESSAAGE_DIALOG_WIDTH} height={Consts.MESSAAGE_DIALOG_WIDTH}
      />}
    </>
}