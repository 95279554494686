
import axios from "axios";

const request = axios.create();

export function workShopRegistration(data) {
    return request({
        url: "/designer/workshopUsers",
        method: "post",
        data: data,
        responseType: "json",
    })
}