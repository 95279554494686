import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchName, fetchNames } from "./AIHelpAction";
import classes from "./AIHelp.module.css";
import Select from "../../../components/Select";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Utils from "../../../Utils";

const nameFilters = [
  {
    label: "Choose a category for the name",
    field: "typeOfName",
    getFilteredData: names => names
  },
  {
    label: "Select the type of name",
    field: "type",
    getFilteredData: (names, filter) => names.filter(i => i.typeOfName === filter.typeOfName)
  },
  {
    label: "Select a gender",
    field: "gender",
    getFilteredData: (names, filter) => names.filter(i => i.type === filter.type && i.typeOfName === filter.typeOfName)
  }
]

export default function Names() {
  const names = useSelector((s) => s.aiHelp.names);
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(true);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!names?.length) {
      fetchNames(dispatch);
    }
  }, []);

  const nameList = selected?.names?.split(",");
  nameList && Utils.shuffle(nameList);

  return (
    <div className={classes.container}>
      {showFilter && (
        <FilterSearch
          names={names}
          searchFilterData={filter => {
            setShowFilter(e => !e);
            const id = names.find(item => {
              for (let prop in filter) {
                if (filter[prop] !== item[prop]) {
                  return false;
                }
              }
              return true;
            }).id;
            fetchName(id).then(setSelected);
          }}
        />
      )}
      <div className={classes.nameContainer}>
        <div>{`${nameList?.length ? nameList.length : "No"
          } Names found`}</div>
        {!showFilter && (
          <div>
            <PrimaryButton
              className={classes.filterButton}
              onClick={() => setShowFilter((prevState) => !prevState)}>
              Filter
            </PrimaryButton>
          </div>
        )}
      </div>
      <div className={classes.innerMain}>
        {nameList?.map((name) => (
          <div className={classes.sentence} key={name}>{name}</div>
        ))}
      </div>
    </div>
  );
}

function FilterFelid({ label, data, field, onChange }) {
  const values = Array.from(new Set(data?.map(e => e[field]) || [])).filter(e => e && (e !== 'NA'));
  return <div>
    <label>{label}</label>
    <Select
      options={values.map(e => ({ label: e, value: e }))}
      onChange={onChange}
    />
  </div>
}

const FilterSearch = ({ names, searchFilterData }) => {
  const [filter, setFilter] = useState({});
  return (
    <div className={classes.filterContainer}>
      {
        nameFilters.map(f =>
          <FilterFelid label={f.label} key={f.label} data={f.getFilteredData(names, filter)} field={f.field}
            onChange={e => setFilter(s => ({ ...s, [f.field]: e.value }))}
          />
        )
      }
      <PrimaryButton
        className={classes.searchButton}
        onClick={e => searchFilterData?.(filter)}>
        Search
      </PrimaryButton>
    </div>
  );
};
