import React, { useEffect, useRef } from "react";
import classes from "./Header.module.css";
import dropDownImage from '../../assets/dropdown-outline.svg';
import Utils from '../../Utils';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Consts from "../../Consts";
import { useState } from "react";
import rightArrow from "../../assets/right-arrow.svg"

const url = Consts.BASE_URL;

const SubMenuItems = ({ subMenu, depth, showMenu }) => {
  return (
    <ul className={Utils.getClasses(classes.subMenu, depth > 1 ? classes.subMenuStyle : "", showMenu ? classes.showSubMenu : classes.hideSubMenu)}>
      <NavBar menuItems={subMenu} depth={depth + 1} />
    </ul>
  )
}

const NavItems = ({ item, depth, isActive, onClick, isSideBarMenu }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navMenuRef = useRef();

  useEffect(() => {

    const checkIfClickedOutside = e => {
      if (showMenu && navMenuRef.current && !navMenuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }

  }, [showMenu])

  return (
    <>
      {item?.subMenu && item?.subMenu.length > 0 ? (
        <li
          className={Utils.getClasses(
            !isSideBarMenu ? classes.navMenuItem : classes.sideBarMenuItem,
            classes.parentNavMenu,
            isActive && classes.navMenuItemActive,
          )}
          ref={navMenuRef}
          onClick={() => setShowMenu(prev => !prev)}
        >
          {depth > 0 && (
            <span
              onMouseEnter={() => setShowMenu(true)}>
              {" "}
              {item.title}
              {depth > 1 ? <img src={rightArrow} alt="dropdown icon" style={{ width: '1.5rem', marginLeft: "0.5rem" }} /> :
                <img src={dropDownImage} alt="dropdown icon" style={{ width: '0.8rem', marginLeft: "0.5rem" }} />}
            </span>
          )}

          <SubMenuItems subMenu={item.subMenu} depth={depth} showMenu={showMenu} />
        </li>
      ) : (
        <li
          className={Utils.getClasses(
            !isSideBarMenu ? classes.navMenuItem : classes.sideBarMenuItem,
            isActive && classes.navMenuItemActive,
            depth > 1 && isActive && classes.subMenuSelected,
          )}
          onClick={onClick}
        >
          {item.title}
        </li>
      )}
    </>
  );
};

const NavBar = ({ menuItems, depth, closeOpenHandler, isSideBarMenu }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      {menuItems.map((item, idx) => {
        const completeUrl = `${url}${item.url}`;
        let isActive = location.pathname.includes(completeUrl);
        return (
          <NavItems
            depth={depth}
            key={idx}
            item={item}
            isActive={isActive}
            isSideBarMenu={isSideBarMenu}
            onClick={() => {
              if (closeOpenHandler) {
                closeOpenHandler();
              }
              history.push(completeUrl);
            }}
          />
        );
      })}
    </>
  );
};

export default NavBar;
