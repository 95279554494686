import React, { useState } from "react";
import classes from "./Profile.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import LinkButton from "../../../../components/Buttons/LinkButton";
import personIcon from "../../../../assets/person-icon.svg";
import DashboardInput from "../../../../components/DashboardInput/DashboardInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateProfile } from "../../../Users/UsersActions";
import { FilePicker } from "../../../Editor/Picker";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import copyIcon from "../../../../assets/copy-text-icon.svg";

function Profile() {
  const userDetails = useSelector((state) => state.user?.user);
  const [editProfile, setEditProfile] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [user, setUser] = useState({});
  const [copyAuthorCode, setCopyAuthorCode] = useState(false);
  const dispatch = useDispatch();

  const fullName = user?.fullName;
  const firstName = fullName?.split?.(" ")?.[0];
  const lastName = fullName?.split?.(" ")?.[1];

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails);
    }
  }, [userDetails]);

  return (
    <div className={classes.main}>
      <UserDashboardHeader
        title={editProfile ? "Edit profile" : "Your Profile"}
        description="View and manage your profile."
        disabled
        userDisabled
      />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <img
            src={user?.photoURL || personIcon}
            alt="Person"
            className={classes.userImage}
          />
          <div className={classes.userNameWrapper}>
            <h1 className={classes.userName}>{user.fullName}</h1>
            <div className={classes.linkButtonWrapper}>
              {editProfile && (
                <FilePicker
                  Component={(props) => (
                    <LinkButton
                      onClick={props.onClick}
                      style={{ fontWeight: "var(--font-weight-semiBold)" }}
                    >
                      <span>Change Profile Picture</span>
                      {props.children}
                    </LinkButton>
                  )}
                  accept="image/png, image/jpeg"
                  onChange={(files) => {
                    setProfilePhoto(files[0]);
                    setUser((u) => ({
                      ...u,
                      photoURL: URL.createObjectURL(files[0]),
                    }));
                  }}
                />
              )}
            </div>
          </div>
          <div>
            {!editProfile && (
              <PrimaryButton
                className={classes.action}
                onClick={() => setEditProfile(true)}
              >
                Edit profile
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className={classes.userDetailsWrapper}>
          <DashboardInput
            label={"First name"}
            type="text"
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            id="firstName"
            disabled={!editProfile}
            placeholder={firstName || "First name"}
            onChange={(e) => {
              setUser((u) => ({
                ...u,
                fullName: `${e.target.value} ${lastName}`.trim(),
              }));
            }}
          />
          <DashboardInput
            label={"Last name"}
            type="text"
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            id="lastName"
            disabled={!editProfile}
            placeholder={lastName || "Last name"}
            onChange={(e) => {
              setUser((u) => ({
                ...u,
                fullName: `${firstName} ${e.target.value}`.trim(),
              }));
            }}
          />
          <DashboardInput
            label={"School / Institute Name"}
            type="text"
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            id="instituteName"
            disabled={!editProfile}
            placeholder={user?.instituteName || "School / Institute Name"}
            onChange={(e) => {
              setUser((u) => ({
                ...u,
                instituteName: e.target.value,
              }));
            }}
            value={user?.instituteName}
          />
          <DashboardInput
            label={"Phone Number"}
            type="number"
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            id="phoneNumber"
            disabled={!editProfile}
            placeholder={user?.phoneNumber || "Phone number"}
            onChange={(e) => {
              setUser((u) => ({
                ...u,
                phoneNumber: e.target.value.trim(),
              }));
            }}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            value={user?.phoneNumber}
          />
          <DashboardInput
            label={"Email Id"}
            type="email"
            id="email"
            disabled={!editProfile}
            inputDisabled
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            placeholder={user?.email || "Email"}
            value={user?.user}
          />
          <DashboardInput
            label={"Author code"}
            type="text"
            disabled={!editProfile}
            inputDisabled
            id="authorCode"
            className={
              editProfile ? classes.inputFelid : classes.disabledInputFelid
            }
            placeholder={user?.authorCode || "Author code"}
          />
        </div>
        {editProfile && (
          <div className={classes.actions}>
            <PrimaryButton
              onClick={(e) => {
                setEditProfile(false);
                updateProfile(user, profilePhoto, dispatch);
              }}
            >
              Save
            </PrimaryButton>
            <SecondaryButton onClick={() => setEditProfile(false)}>
              Cancel
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
