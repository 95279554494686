import React from "react";

function JustifiedLeft() {
  return (
    <svg
      id="Layer_6"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 6"
    >
      <path d="m478 33.97-444-.18c-18.78 0-34 15.22-34 34s15.22 34 34 34l444 .18c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
      <path d="m478 159.45-444-.18c-18.78 0-34 15.22-34 34s15.22 34 34 34l444 .18c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
      <path d="m512 318.92c0-18.78-15.22-34-34-34l-444-.18c-18.78 0-34 15.23-34 34s15.22 34 34 34l444 .18c18.78 0 34-15.22 34-34z" />
      <path d="m34 410.21c-18.78 0-34 15.22-34 34s15.22 34 34 34h306.64c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
    </svg>
  );
}

export default JustifiedLeft;
