import React, { useState, useEffect } from 'react';
import classes from "./CheckoutForm.module.css";
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const InfoForm = ({ setShowCheckoutForm, handlePayment, userData }) => {
  const [state, setState] = useState('');
  const [customerName, setCustomerName] = useState(userData.fullName);
  const [customerPhone, setCustomerPhone] = useState(userData.phone);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [alternatePhone, setAlternatePhone] = useState('');
  const [pincode, setPincode] = useState('');


  const handlePayNow = () => {
    handlePayment(state, customerName, customerPhone, city, address, pincode, addressLine2, landmark, alternatePhone)
  };

  return (
    <div className={classes.checkoutForm}>
      <h1 className={classes.checkOutHeading}>Order Confirmation
        <span className={classes.closeModalIcon} onClick={() => setShowCheckoutForm(false)}><FontAwesomeIcon icon={faXmark} /></span>
      </h1>
      <div style={{ margin: '2rem 0' }}>Are you sure you want to place this order?</div>
      <div className={classes.footer}>
        <SecondaryButton style={{ marginRight: "1rem" }} onClick={() => setShowCheckoutForm(false)}>Close</SecondaryButton>
        <PrimaryButton onClick={handlePayNow}>Order now</PrimaryButton>
      </div>
    </div>
  );
};

export default InfoForm;