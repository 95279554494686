import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classes from "./OrderResponse.module.css";
import Consts from "../../Consts";
import { clearCart } from '../../modules/AddCartScreen/AddCartSlice';
import { updateOrderStatus } from './PaymentAction';

function OrderResponse() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [paymentId, setPaymentId] = useState(params.get('payment_id'));
    const [paymentStatus, setPaymentStatus] = useState(params.get('payment_status'));
    const [transactionId, setTransactionId] = useState(params.get('id'));
    const [orderId, setOrderId] = useState(params.get('transaction_id'))
    useEffect(() => {
        if(orderId && paymentId && paymentStatus && transactionId){
            updateTrnasactionStatus(orderId, paymentId, paymentStatus, transactionId);
        }
    }, []);
    
    async function updateTrnasactionStatus(orderId, paymentId, paymentStatus, transactionId) {
        if(paymentStatus !== "Failed"){
            paymentStatus = "success";
        }else{
            paymentStatus = paymentStatus.toLowerCase();
        }
        const responseParams = {
            id : orderId,
            gateway_order_id: transactionId,
            gateway_payment_id: paymentId,
            razorpay_signature: "",
            gateway_payment_status: paymentStatus,
        };
        const resp1 = await updateOrderStatus(responseParams);
        if(resp1.status){
            //clearing cart items and coupon from localstorage
            clearCart()
            window.location = Consts.BASE_URL + "/user/order-history";
        }
    }
    return (
        <>
            <div className={classes.paymentResponseContainer} style={{ justifyContent: 'flex-start' }}>
                <div>
                    <div>
                        {paymentStatus === 'Success' && <h1>Order successful!</h1>}
                        <h3>Please do not close or refresh this page.</h3>
                        <h1>Redirecting to order page. Please wait ... </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderResponse