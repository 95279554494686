import React, { useEffect, useState } from "react";
import ManageColors, { ColorWrapper, getColors } from './ManageColors';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveFabricObject, useFabricCanvas, useFabricCanvasHandler } from "../FabricCanvas.js";
import "../components.css";
import ColorGrid from "./ColorGrid";
import { addForegroundColor } from "../EditorAction";
import { foregroundColorsActions } from "../EditorSlice";
import Container from "./components/Container";
import { ActiveTextboxObject } from "../objectUtils.js";

let fabric;

const action = {
	getSelectedColorableObjects: function (canvas) {
		const selectedObjects = [];

		canvas.getActiveObjects().forEach(o => {
			if (this.isColorableObject(o)) {
				selectedObjects.push(o);
			}
		});

		return selectedObjects;
	},

	isColorableObject: function (object) {
		return object instanceof fabric.Text;
	},

	getColorWapper: function (obj, prop, idx) {
		let color = obj[prop];
		if (prop === 'extraStrokes') {
			color = color[idx].stroke;
		}
		return new ColorWrapper({
			type: prop,
			color: color,
			colorType: color.type,
			target: obj,
			index: idx
		});
	},
	getObjectColors: function (obj) {
		if (obj.usedColors)
			return obj.usedColors;
		let me = this,
			colors = [];
		if (obj.fill && obj.type !== 'group')
			colors.push(this.getColorWapper(obj, 'fill'));
		if (obj.type !== 'shaped-text' || !(obj.fill instanceof fabric.Pattern)) {
			if (obj.stroke && obj.type !== 'group')
				colors.push(this.getColorWapper(obj, 'stroke'));
		}
		if ((obj instanceof fabric.ShapedText) && obj.extraStrokes) {
			obj.extraStrokes.forEach((dec, i) => {
				colors.push(this.getColorWapper(obj, 'extraStrokes', i));
			});
		}
		if (obj._objects) {
			obj._objects.forEach(function (o) {
				colors = colors.concat(me.getObjectColors(o));
			});
		}
		if (obj.type !== 'activeSelection') {
			obj.usedColors = colors;
		}
		return colors;
	},

	clearObjectUsedColors: function (obj) {
		delete obj.usedColors;
	},

	getAllActiveObjectColors: function (canvas) {
		let me = this,
			colors = [],
			objects = this.getSelectedColorableObjects(canvas);
		objects.forEach(function (o) {
			colors = [...colors, ...me.getObjectColors(o)];
		});
		return {
			colors: colors,
			objects: objects
		};
	}
};

function getColorView() {
	let colorView;
	const json = localStorage.getItem('colorView');
	if (json) {
		colorView = JSON.parse(json);
	} else {
		colorView = { list: false };
	}
	return colorView;
}

export function ColorView({ visible }) {
	const [manageDialog, setManageDialog] = useState({});
	const [selectionColor, setSelectionColor] = useState({ colors: [], selectedIdx: 0 });
	const [selectedValue, setSelectedValue] = useState(0);
	const [view, setView] = useState(getColorView());
	const [searchKey, setSearchKey] = useState("");
	const dispatch = useDispatch();
	const colorData = useSelector(state => state.foregroundColors);
	const [canvas] = useFabricCanvasHandler(canvas => ({
		'selection:created': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:updated': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:cleared': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:changed': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'object:modified': function () {
			setSelectionColor(state => ({ ...state, colors: action.getAllActiveObjectColors(canvas)?.colors }));
		},
	}));
	action.canvas = canvas;

	useEffect(() => {
		fabric = window.fabric;
	}, []);

	const categories = colorData.libraryItems.map((i, idx) => ({
		...i,
		label: i.name,
		value: idx
	})) || [];
	const selectedOption = categories[selectedValue] || {};

	const colorList = getColors(selectedOption);
	const filteredColorList = colorList.filter((color) => {
		return color.name.toLowerCase().includes(searchKey.toLowerCase());
	});
	const selectedIndex = filteredColorList.findIndex((color) => {
		return color?.color === selectionColor.colors[selectionColor.selectedIdx]?.color;
	});
	return <Container visible={visible} title={"Color"}>
		<div className="scroll-vertical">
			<ColorGrid colorlist={filteredColorList} selectIndex={[selectedIndex]} list={view.list}
				onClick={color => {
					dispatch(foregroundColorsActions.setDefaultColor(color.getColor()));
					let targetColor = action.getAllActiveObjectColors(canvas)?.colors?.[selectionColor.selectedIdx];
					if (!targetColor)
						return;
					targetColor.setColor(color);
					let target = targetColor.target;
					target.sepName = color.name;
					canvas.renderAll();
					if (targetColor) {
						canvas.fire('object:modified', { target: targetColor.target });
					}
				}} itemDraggable={false}
			/>
		</div>
		{
			manageDialog.open ?
				<ManageColors data={selectedOption}
					open={true} onClose={() => {
						setManageDialog({ open: false });
					}}
					onSave={(data) => {
						let updated = { ...selectedOption, colors: data };
						delete updated.value;
						delete updated.label;
						addForegroundColor(dispatch, updated, colorData).then(e => setManageDialog({ open: false }));
					}}
				/> : ''
		}
	</Container>;
}

export function ColorStrip() {
	const colorData = useSelector(state => state.foregroundColors);
	const [selectionColor, setSelectionColor] = useState({ colors: [], selectedIdx: 0 });
	const [colorList, setColorList] = useState([]);
	const dispatch = useDispatch();

	const [canvas] = useFabricCanvasHandler(canvas => ({
		'selection:created': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:updated': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:cleared': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'selection:changed': function () {
			setSelectionColor({ colors: action.getAllActiveObjectColors(canvas)?.colors, selectedIdx: 0 });
		},
		'object:modified': function () {
			setSelectionColor(state => ({ ...state, colors: action.getAllActiveObjectColors(canvas)?.colors }));
		},
	}));

	useEffect(() => {
		fabric = window.fabric;
	}, []);

	useEffect(() => {
		const categories = colorData.libraryItems?.map((i, idx) => ({
			...i,
			label: i.name,
			value: idx
		}));
		if (categories.length) {
			setColorList(getColors(categories[0]));
		}
	}, [colorData])

	const selectedIndex = colorList.findIndex((color) => {
		return color?.color === selectionColor.colors[selectionColor.selectedIdx]?.color;
	});

	return <ColorGrid colorlist={colorList} itemDraggable={false} selectIndex={[selectedIndex]}
		onClick={color => {
			dispatch(foregroundColorsActions.setDefaultColor(color.getColor()));
			let targetColor = action.getAllActiveObjectColors(canvas)?.colors?.[selectionColor.selectedIdx];
			let target = targetColor?.target;
			if (!targetColor || target?.lockColor)
				return;
			targetColor.setColor(color);
			target.sepName = color.name;
			canvas.renderAll();
			if (targetColor) {
				canvas.fire('object:modified', { target: targetColor.target });
			}
		}}
	/>
}


export function TextColorStrip() {
	const colorData = useSelector(state => state.foregroundColors);
	const [colorList, setColorList] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState([]);
	const dispatch = useDispatch();
	const [activeObject, update] = useActiveFabricObject(ActiveTextboxObject);

	useFabricCanvasHandler({
		'text:selection:ended': function () {
			update();
		}
	});

	useEffect(() => {
		const categories = colorData.libraryItems?.map((i, idx) => ({
			...i,
			label: i.name,
			value: idx
		}));
		if (categories.length) {
			setColorList(getColors(categories[0]));
		}
	}, [colorData])

	useEffect(() => {
		if (colorList && activeObject) {
			const fill = activeObject.get('fill');
			setSelectedIndex(colorList.findIndex(color => color?.color === fill))
		}
	}, [activeObject, colorList])

	return <ColorGrid colorlist={colorList} itemDraggable={false} selectIndex={[selectedIndex]}
		onClick={color => activeObject.set('fill', color.getColor())}
	/>
}