import React, { useState, useEffect } from "react";
import { CardMedia } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { downloadPDF } from "./ProductOrderActions";
import ModalDialog from "../../components/Dialog/Dialog";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from './ProductOrder.module.css';


const action = {
    submitOrder: function (svgUrls) {
        downloadPDF({svgUrls});
    }
};

function ProductOrder() {
    const history = useHistory();
    const orderData = useSelector(state => state.order.orderData);
    const [placed, setPlaced] = useState(false);
    useEffect(() => {
        if (!window.global.Designer.AppData.productModelData) {
            history.goBack();
        }
    }, []);

    return (
        <>
            {placed &&
                <ModalDialog title="Downloading" content={
                    <div>Your pdf is downloading.</div>
                } footer={
                    <div className={classes.modalFooter}>
                        <PrimaryButton onClick={() => setPlaced(false)}>Close</PrimaryButton>
                    </div>
                } />
            }
            <div className={classes.main}>
                <div className={classes.goBack}>
                    <span className="fa fa-reply cursor-pointer" onClick={() => history.goBack()} />
                </div>
                <div className={classes.image}>
                    <CardMedia
                        component="img"
                        style={{ maxWidth: 'fit-content' }}
                        alt={orderData.modelName}
                        image={orderData.productDesignThumb}
                        title={orderData.modelName} draggable={false}
                    />
                </div>
                <div className={classes.download}>
                    <PrimaryButton onClick={() => {
                        action.submitOrder(orderData);
                        setPlaced(true);
                    }}>Download PDF</PrimaryButton>
                </div>
            </div>
        </>
    );
}

export default ProductOrder;