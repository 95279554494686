import React, { useState } from "react";
import Shapes from './components/Shapes';
import Basic from './components/Basic';
import Container from "../components/Container";


export default function DecorativeTextView({ visible }) {
    const [tabValue, setTabValue] = useState("basic");
    return <Container visible={visible} title={"Heading"}>
        <Basic />
        <Shapes />
    </Container>;
}
