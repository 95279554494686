import React, { useEffect, useRef, useState } from "react";
import MainFooter from "../../components/MainFooter/MainFooter";
import classes from "./AddCartScreen.module.css";
import Card from "../../components/Card/Card";
import Divider from "../../components/Divider/Divider";
import IconButton from "../../components/Buttons/IconButton";
import AddIcon from "../../assets/add-circle-outline.svg";
import cancelIcon from "../../assets/cancel.png";
import SubtractIcon from "../../assets/minus-icon.svg";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackButton from "../../components/Buttons/BackButton";
import {
  getSubTotalAmount,
  addCartActions,
  getShippingChargeByCart,
  getAppliedCouponCode
} from "./AddCartSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCartItem,
  removeCartItem,
  updateCartItemPriceSummary,
  updateCartShippingCharge
} from "./AddCartSlice";
import { useHistory } from "react-router";
import { getCouponDisc, getPaymentConfig, getPriceSummaryByItem, getShippingCharge } from "./AddCartActions";
import Lottie from "react-lottie-player";
import emptyCart from "../../assets/animations/cart.json";
import Utils from "../../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import KnowAboutDiscount from "./KnowAboutDiscount";
import offerIcon from "../../assets/offer-icon.svg";
import Input from "../../components/Input/Input";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import LinkButton from "../../components/Buttons/LinkButton";
import disclaimerIcon from '../../assets/disclaimer-icon.svg'
import MessageDialog from '../../components/MessageDialog/MessageDialog';
import walletIcon from "../../assets/wallet.svg";
import Payment from "../../components/PaymentForms/Payment";


function AddCartScreen() {
  const cartItems = useSelector((state) => state.addCart.cartItems);
  const orderDetailsRef = useRef(null);
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);
  const userData = userDetails.user || {};
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [config, setConfig] = useState();

  useEffect(() => {
    getPaymentConfig().then(setConfig);
  }, [])

  return (
    <>
      {
        showCheckoutForm && config &&
        <Payment
          cartItems={cartItems}
          setShowCheckoutForm={setShowCheckoutForm}
          userData={userData}
          config={config}
        />
      }
      <div className={classes.cartContent}>
        <div className={classes.mainContainer}>
          <div className={classes.heading}>
            <BackButton />
          </div>
          {cartItems.length > 0 ? (
            <>
              <div className={classes.cartContainer}>
                <div className={classes.cartTableWrapper}>
                  {cartItems.map((cartItem) => (
                    <CartItem cartItem={cartItem} key={cartItem.id} />
                  ))}
                </div>
                <div
                  className={classes.orderDetailsContainer}
                  ref={orderDetailsRef}
                >
                  <CouponSummary cartItems={cartItems} userDetails={userData} />
                  <CreditSummary />
                  <OrderSummary onClick={() => setShowCheckoutForm(true)} />
                  <KnowAboutDiscount />
                </div>
              </div>
            </>
          ) : (
            <div className={classes.emptyCartMessage}>
              <div>
                <Lottie
                  loop
                  animationData={emptyCart}
                  play
                  style={{ width: "14rem", height: "14rem" }}
                />
              </div>
              <div>
                <div className={classes.messageWrapper}>
                  <p className={classes.emptyCartHeading}>
                    Hey! your cart is empty
                  </p>
                  <p className={classes.emptyCartdescription}>
                    Let's add some books in your cart
                  </p>
                </div>
              </div>
              <PrimaryButton onClick={() => Utils.openBookstore(history)}>
                Visit our bookstore
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>

      <MainFooter />
    </>
  );
}

const OrderSummary = ({ onClick }) => {
  const shippingAmount = useSelector((state) => getShippingChargeByCart(state));
  const subTotal = useSelector((state) => getSubTotalAmount(state));
  const couponDetails = useSelector((state) => state.addCart.couponDetails);
  const credits = useSelector(s => s.user.user?.credits) || 0;
  const platformFee = 0;

  const getTotalAmount = () => {
    let totalAmount = 0;
    totalAmount = subTotal - (couponDetails?.couponDiscAmount || 0);
    if (totalAmount < 0) {
      totalAmount = 0
    }
    return totalAmount + shippingAmount + platformFee;
  }

  return (
    <Card
      backColor="#FFF6F0"
      style={{ display: "flex", flexDirection: "column", alignItems: "unset" }}
      hasShadow={true}
    >
      <div className={classes.headerText}>Order Summary</div>
      <Divider />
      <div className={classes.orderDetails}>
        <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Subtotal</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {subTotal?.toFixed(2)}
          </label>
        </div>
        <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Coupon discount</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {(couponDetails?.couponDiscAmount || 0).toFixed(2)}
          </label>
        </div>
        <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Shipping</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {shippingAmount?.toFixed(2)}
          </label>
        </div>
        {/* <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Platform fee</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {platformFee.toFixed(2)}
          </label>
        </div> */}
        <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Total amount</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {getTotalAmount()?.toFixed(2)}
          </label>
        </div>
        <div className={classes.orderRows}>
          <label className={classes.itemLabel}>Net Payable amount</label>
          <label className={classes.orderSubtotal}>
            {Utils.getRupeeSymbol} {(Math.max(getTotalAmount() - credits, 0)).toFixed(2)}
          </label>
        </div>
      </div>
      <PrimaryButton
        style={{ width: "15rem", alignSelf: "center" }}
        onClick={onClick}
      >
        Check Out
      </PrimaryButton>
    </Card>
  );
};

const CouponSummary = ({ cartItems, userDetails }) => {
  const [couponCode, setCouponCode] = useState('');
  const [msg, setMsg] = useState({ status: "green", message: '' })
  const [isDisabled, setIsDisabled] = useState(false);
  const [couponPopUp, setCouponPopUp] = useState(false);
  const couponDetails = useSelector((state) => state.addCart.couponDetails);
  const couponAmount = couponDetails?.couponDiscAmount || 0;
  const promoCode = couponDetails?.promoCode || "";

  useEffect(() => {
    if (couponAmount > 0) {
      setMsg(prevState => { return { status: "green", message: `${promoCode} is applied.` } })
      setIsDisabled(true);
    }
  }, [couponAmount])

  const dispatch = useDispatch()

  //to store only book id and there quantity; and to store author code
  let items = cartItems.map(obj => ({ bookId: obj.id, quantity: obj.count }));
  const authorCode = userDetails.authorCode;

  function applyCouponCodeHandler() {
    let data = { couponCode: couponCode, cartItems: items }
    if (authorCode === couponCode) {
      setIsDisabled(true);
      setCouponCode("");
      setMsg((prevState) => { return { status: "red", message: 'Your not able to use your own coupon code' } });
    }
    else {
      getCouponDisc(dispatch, data).then(response => {
        if (response.valid) {
          setIsDisabled(true);
          setCouponPopUp(true);
          setMsg((prevState) => { return { status: "green", message: `${couponCode} is applied.` } });
        }
        else if (!response.valid) {
          setIsDisabled(true);
          setCouponCode("")
          setMsg((prevState) => { return { status: "red", message: 'Invalid coupon code' } });
        }
      })
    }
  };

  return (
    <Card
      style={{ display: "block", padding: "1rem", height: "fit-content" }}
      hasShadow={true}
    >
      <div className={classes.CouponSummaryMain}>
        <div>
          <div className={classes.headerText}>Offers</div>
          <div className={classes.selectpromoCode}>
            <img src={offerIcon} alt="offer icon" />
            <p>select a promo code</p>
          </div>
        </div>
        <div className={classes.couponInputWrapper}>
          <label htmlFor="promoCode" className={classes.promoCodeLabel}>Got an offer/ promo code?</label>
          <div id="promoCode" className={classes.promoCodeInputWrapper}>
            <Input
              onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
              disabled={isDisabled}
              value={couponCode}
            />
            <SecondaryButton style={{ height: '2.25rem' }}
              disabled={isDisabled}
              onClick={() => applyCouponCodeHandler()}
            >Apply</SecondaryButton>
          </div>
          <span className={isDisabled ? classes.couponCode : classes.hiddenCouponCode} style={{ color: `${msg.status}` }}>{msg.message}<LinkButton
            title={"Remove"}
            onClick={() => { setIsDisabled(false); dispatch(addCartActions.removeCouponDiscount()) }}
            style={{ color: "#8B8B8B", fontSize: "0.875rem" }}
          ><FontAwesomeIcon icon={faXmark} /></LinkButton> </span>
        </div>
      </div>
      {
        couponPopUp && <MessageDialog
          onAccept={() => {
            setCouponPopUp(false);
            setCouponCode('')
          }}
          message={
            <div className={classes.appliedCouponPopUP}>
              <div className={classes.couponSuccess}>{couponCode} APPLIED</div>
              <div className={classes.savingMessageWrapper}>
                <span className={classes.savedAmount}>{Utils.getRupeeSymbol}{couponAmount}</span>
                <span className={classes.savedAmountMessage}>You saved {Utils.getRupeeSymbol} {couponAmount} with this coupon.</span>
              </div>
            </div>
          } />
      }
    </Card>
  );
};

const CreditSummary = () => {
  const user = useSelector(s => s.user.user);
  if (Boolean(user?.credits)) {
    return <Card style={{
      display: "block",
      padding: "1rem",
      height: "fit-content",
      background: 'linear-gradient(132deg, rgba(243,231,255,1) 0%, rgba(237,232,255,1) 66%, rgba(202,241,255,1) 100%)'
    }} hasShadow={true}>
      <div className={classes.headerText}>
        <img src={walletIcon} alt="wallet icon" style={{
          width: '1.5rem',
          transform: 'translateY(20%)'
        }} />
        <span style={{ marginLeft: '0.5rem' }}>Wallet</span>
      </div>
      <div className={classes.wallet}>
        <label className={classes.itemLabel}>Available Balance</label>
        <label className={classes.orderSubtotal}>{Utils.getRupeeSymbol} {user?.credits}</label>
      </div>
    </Card>;
  }
  return '';
};

function getFontThumUrl(book) {
  return `${book.frontThumbURL}?${book.updateDate}`;
}

const maxQuantity = 10000;
const authorMinQty = 2;

const CartItem = (cartItemProps) => {
  const dispatch = useDispatch();
  const user = useSelector(s => s.user.user);
  const [count, setCount] = useState('');
  const cartItems = useSelector((state) => state.addCart.cartItems);
  const couponDetails = useSelector((state) => state.addCart.couponDetails);
  const promoCode = couponDetails?.promoCode || "";
  const cartItem = cartItemProps.cartItem;

  useEffect(() => {
    handleCartItemCount(cartItem, cartItem.count);
  }, [cartItem]);

  const handleCartItemCount = (cartItem, updatedCount) => {
    let min = user?.id === cartItem.authorId ? authorMinQty : 1;
    updatedCount = updatedCount > min ? updatedCount : min;
    if (updatedCount > maxQuantity) {
      updatedCount = maxQuantity
    };
    if (cartItem.count === updatedCount) {
      setCount(updatedCount);
      return;
    }
    const updatedCartItem = { ...cartItem, count: updatedCount };
    dispatch(updateCartItem(updatedCartItem));
  }

  const handleCancelClick = () => {
    dispatch(removeCartItem(cartItem));
  };

  useEffect(() => {
    const fetchItemPriceSummary = async () => {
      try {
        const priceSummary = await getPriceSummaryByItem(
          cartItem.id,
          cartItem.count,
          cartItem.isProofReadingService
        );
        setCount(cartItem.count);
        dispatch(updateCartItemPriceSummary(priceSummary));
      } catch (error) {
        console.log("Error fetching price summary:", error);
      }
    };

    const fetchShippingCharge = async () => {
      try {
        const shippinCharge = await getShippingCharge(cartItems);
        dispatch(updateCartShippingCharge(shippinCharge));
      } catch (error) {
        console.log("Error fetching price summary:", error);
      }
    };

    // get coupon discount after quantity changed
    if (promoCode) {
      let items = cartItems.map(obj => ({ bookId: obj.id, quantity: obj.count }));
      let couponCode = getAppliedCouponCode();
      let data = { couponCode: couponCode.promoCode, cartItems: items }
      getCouponDisc(dispatch, data);
    };

    fetchItemPriceSummary();
    fetchShippingCharge();
  }, [cartItem.count]);

  const min = user?.id === cartItem.authorId ? authorMinQty : 1;

  return (
    <>
      <div className={classes.cartItemCard}>
        <div className={classes.cartBookDetails}>
          <div className={classes.cartItemNewImage}>
            <img src={getFontThumUrl(cartItem)} alt="bookImage" />
          </div>
          <div className={classes.cartItemDetail}>
            <div className={classes.bookDetailsWrapper}>
              <label className={classes.cartItemTitle}>{cartItem?.title}</label>
              <label className={classes.cartItemDescription}>
                By {cartItem?.author}
              </label>
              <label className={classes.cartItemTitle}>{Utils.getRupeeSymbol} {Utils.getBookPrice(cartItem)}</label>
            </div>
            <div>
              <div className={classes.cartItemCountContainer}>
                <IconButton
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: "var(--selection-dark)",
                  }}
                  disabled={cartItem.count <= min}
                  iconStyle={{
                    display: "inline-block",
                    width: "1rem",
                    height: "0.5rem",
                  }}
                  onClick={(e) => {
                    handleCartItemCount(cartItem, cartItem?.count - 1);
                  }}
                  iconImage={SubtractIcon}
                />
                <input
                  className={classes.cartItemCount}
                  value={count}
                  type="number"
                  onChange={(e) => setCount(e.target.value)}
                  onBlur={e => {
                    handleCartItemCount(cartItem, parseInt(e.target.value) || 0)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCartItemCount(cartItem, parseInt(e.target.value) || 0)
                    }
                  }}
                  tabIndex={0}
                />
                <IconButton
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: "var(--selection-dark)",
                  }}
                  disabled={cartItem?.count >= maxQuantity}
                  iconStyle={{
                    display: "inline-block",
                    width: "0.5rem",
                    height: "0.5rem",
                    transform: "rotate(45deg)",
                  }}
                  onClick={(e) => {
                    handleCartItemCount(cartItem, cartItem?.count + 1);
                  }}
                  iconImage={AddIcon}
                />
              </div>
              {
                (user?.id === cartItem.authorId && cartItem.count <= authorMinQty) && <div className={classes.tooltip}> Minimum qty. {authorMinQty}
                  <span className={classes.disclaimerMessage}> <img src={disclaimerIcon} alt="" />
                    <span className={classes.tooltiptext}>As an author, you are requested to order a minimum of {authorMinQty} copies of your book due to the constraints of printing process. The other copies of your book can be displayed in your School Library to inspire your classmates and shared with your friends and family to read your creative work.</span>
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
        <div className={classes.pricingAndCancelBtnWrapper}>
          <div className={classes.pricing}>
            <div className={classes.displayGridTwoByTwo}>
              <span className={classes.amount}>Price</span>
              <span className={classes.amount}>{Utils.getRupeeSymbol} {(Utils.getBookPrice(cartItem) * cartItem?.count)?.toFixed(2)}</span>
            </div>
            <div className={classes.displayGridTwoByTwo}>
              <span className={classes.amount}>Quantity Discount</span>
              <span className={classes.amount} >-{Utils.getRupeeSymbol} {cartItem?.quantityDiscount?.toFixed(2)}</span>
            </div>
            {user?.id === cartItem.authorId && cartItem?.isProofReadingService &&
              <div className={classes.displayGridTwoByTwo}>
                <span className={classes.amount}>Proofreading Price</span>
                <span className={classes.amount} >+{Utils.getRupeeSymbol} {Utils.getProofReadingPrice(cartItem)}</span>
              </div>
            }
            <div className={classes.displayGridTwoByTwo}>
              <span className={classes.amount}>Author Discount</span>
              <span className={classes.amount}>-{Utils.getRupeeSymbol} {cartItem?.authorDiscount?.toFixed(2)}</span>
            </div>
            <div className={classes.totalAmountWrapper}>
              <span className={classes.amount}>Total Amount</span>
              <span className={classes.totalAmount}>{Utils.getRupeeSymbol} {cartItem?.totalAmount?.toFixed(2)}</span>
            </div>
          </div>
          <div className={classes.cancelBtnWrapper}>
            <img
              src={cancelIcon}
              onClick={handleCancelClick}
              className={classes.cancelBtn}
              alt="cancel"
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default AddCartScreen;
