import React from "react";
import classes from "./Podcast.module.css";
import { useSelector } from "react-redux";
import Utils from "../../../Utils";
import HtmlViewer from "../../../components/HtmlViewer/HtmlViewer";

function Podcast() {
  const podcast = useSelector((state) => state.podcast.podcast);
  const videoId = podcast?.link?.split("/").pop().split("?")[0];
  const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0`;

  return (
    <div className={classes.main}>
      <div className={classes.videoWrapper}>
        <iframe
          className={classes.video}
          src={embedUrl}
          title={podcast.title}
          frameBorder="0"
          allow="accelerometer; clipboard-write;"
          allowFullScreen
        ></iframe>
     
      </div>
      <div className={classes.content}>
        <h2 className={classes.title}>{podcast.title}</h2>
        <div className={classes.description}>
          <HtmlViewer data={podcast?.description} />
        </div>
      </div>
    </div>
  );
}

export default Podcast;
