import React, { useEffect, useState, useRef, useContext, createContext } from "react";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import "./components.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";

const FabricContext = createContext();
export const FabricContainer = FabricContext.Provider;

function useFabricContainer() {
	return useContext(FabricContext);
}

export function useFabricCanvas() {
	return useFabricContainer()?.canvas;
}

export function useFabricPreviewCanvas() {
	return useFabricContainer()?.previewCanvas;
}

export function useFabricPanCanvas() {
	return useFabricContainer()?.panCanvas;
}

export function useFabricCanvasHandler(dataOrCallback, dependencies = []) {
	const canvas = useFabricCanvas();
	const [handler] = useState({});

	useEffect(() => {
		const data = typeof dataOrCallback === 'function' ? dataOrCallback(canvas) : dataOrCallback;
		if (canvas && data) {
			canvas.off(handler);
			Object.keys(handler).forEach(k => delete handler[k]);
			Object.assign(handler, data);
			canvas.on(handler);
		}
		return () => {
			if (canvas && handler) {
				canvas.off(handler);
			}
		};
	}, [canvas, handler, ...dependencies]);

	return [canvas, handler];
}


export class ActiveObject {
	constructor(obj) {
		this._activeObject = obj;
		if (!obj) {
			this._activeObjects = [];
		} else if (obj.type === 'activeSelection') {
			this._activeObjects = obj.getObjects();
		} else {
			this._activeObjects = [obj];
		}
	}
	getTargetObjects() {
		return this._activeObjects;
	}
	_getValue(o, key) {
		return o.get(key);
	}
	_setValue(o, key, value) {
		o.set(key, value);
	}
	get(key) {
		const objects = this.getTargetObjects();
		if (!objects.length) {
			return;
		}
		let value = this._getValue(objects[0], key);
		for (let i = 1, len = objects.length; i < len; ++i) {
			if (value !== this._getValue(objects[i], key)) {
				return null;
			}
		}
		return value;
	}
	set(key, value) {
		for (let o of this.getTargetObjects()) {
			this._setValue(o, key, value);
		}
	}
	fireModified() {
		try {
			const o = this._activeObject;
			o && o.canvas.fire('object:modified', { o })
		} catch (e) { }
	}
	isEmpty() {
		return !this._activeObjects.length;
	}
	size() {
		return this._activeObjects.length;
	}
}

export function useActiveFabricObject(Wrapper = ActiveObject) {
	const [activeObject, setActiveObject] = useState(new Wrapper());
	function updateActiveObject() {
		setActiveObject(new Wrapper(canvas.getActiveObject()));
	}
	const [canvas] = useFabricCanvasHandler(canvas => {
		canvas && setActiveObject(new Wrapper(canvas.getActiveObject()));
		return {
			'selection:created': updateActiveObject,
			'selection:updated': updateActiveObject,
			'selection:cleared': updateActiveObject
		};
	});
	return [activeObject, updateActiveObject];
}

function onKeydown(canvas) {
	return e => {
		let left = 0,
			top = 0,
			step = 0.5,
			me = window.Ext.canvasController,
			lockType;
		switch (e.key) {
			case 'ArrowUp':
				top = -step;
				lockType = "lockMovementY";
				break;
			case 'ArrowDown':
				top = step;
				lockType = "lockMovementY";
				break;
			case 'ArrowRight':
				left = step;
				lockType = "lockMovementX";
				break;
			case 'ArrowLeft':
				left = -step;
				lockType = "lockMovementX";
				break;
			case 'Delete':
				me.deleteActiveObjects();
			default:
				return;
		}
		var activeObject = canvas.getActiveObject();
		if (activeObject && !activeObject[lockType]) {
			activeObject.left += left;
			activeObject.top += top;
			canvas.renderAll();
		}
	}
}

function onKeyup(canvas) {
	return e => {
		let lockType;
		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowDown':
				lockType = "lockMovementY";
				break;
			case 'ArrowLeft':
			case 'ArrowRight':
				lockType = "lockMovementX";
				break;
			default:
				return;
		}
		let activeObject = canvas.getActiveObject();
		if (activeObject && !activeObject[lockType]) {
			canvas.fire('object:modified', { target: canvas.getActiveObjects() });
		}
	}
}

function refreshPreviewPanel(canvas, previewCanvas) {
	var objects = canvas?.getObjects()?.concat();
	if (objects) {
		objects.forEach(function (object) {
			object.previewCanvas = previewCanvas;
		});

		previewCanvas._objects = objects;

		objects.forEach(function (object) {
			delete object.previewCanvas;
		})
	}
}

export default function FabricCanvas({ onShow, style, pageData }) {
	const canvasController = window.global.Ext.canvasController;
	const wrapper = useRef();
	const eventHandlers = useRef();
	const mainCanvasWrapper = useRef();
	const panCanvasWrapper = useRef();
	const { width, height } = useContainerDimensions(wrapper);
	const [isEditing, setEditing] = useState(false);
	const [canvas] = useFabricCanvasHandler({
		'layerTarget:changed': function (e) {
			setEditing(e.editable);
		}
	});

	const [zoom, setZoom] = useState(1);

	useEffect(() => {
		if (canvas && pageData) {
			canvasController.initialize(pageData.getSize());
			canvasController.recalcCanvasLayout();
		}
	}, [width, height, canvas, pageData]);

	useEffect(() => {
		let isMounted = true;
		if (canvas && pageData) {
			pageData.getDesign().then(design => {
				if (isMounted) {
					canvasController.loadDesign(design, pageData).then(e => {
						pageData.isMounted = true;
						pageData.canvas = canvas;
						pageData.getBook().fire('page:mounted', { target: pageData });
					});
				}
			})
		}
		return () => {
			isMounted = false;
			if (pageData) {
				pageData.isMounted = false;
				canvas.discardActiveObject();
				delete pageData.canvas;
				pageData.getBook().fire('page:unmounted', { target: pageData });
			}
		};
	}, [canvas, pageData]);

	useEffect(() => {
		if (mainCanvasWrapper.current) {
			mainCanvasWrapper.current.innerHTML = `<canvas class="mainCanvas" />`;
			panCanvasWrapper.current.innerHTML = `<canvas class="panCanvas" />`;
			canvasController.onCanvasPanelAfterRender(wrapper.current).then(e => {
				onShow?.({
					canvas: canvasController.canvas,
					previewCanvas: canvasController.previewCanvas,
					panCanvas: canvasController.panCanvas
				});
			}).catch(e => console.log(e))
		}
	}, [mainCanvasWrapper.current, onShow]);

	useEffect(() => {
		const el = wrapper.current;
		if (el && canvas) {
			eventHandlers.current = {
				onKeydown: onKeydown(canvas),
				onKeyup: onKeyup(canvas)
			};
			el.addEventListener('keydown', eventHandlers.current.onKeydown);
			el.addEventListener('keyup', eventHandlers.current.onKeyup);
		}
		return () => {
			if (el && canvas) {
				el.removeEventListener('keydown', eventHandlers.current.onKeydown);
				el.removeEventListener('keyup', eventHandlers.current.onKeyup);
			}
		};
	}, [wrapper.current, canvas]);

	useEffect(() => {
		canvasController.setZoom(zoom);
		refreshPreviewPanel(canvas, canvasController.previewCanvas);
	}, [zoom]);

	return <div tabIndex={0}
		style={{
			height: '100%',
			width: '100%',
			outline: 'none',
			position: 'relative',
			...style
		}} ref={wrapper}
	>
		<div style={{ position: 'absolute', width: "100%", height: "100%" }}>
			<div ref={mainCanvasWrapper} className="mainCanvasWrapper">
				<canvas className="mainCanvas" />
			</div>
			<div className="previewCanvasContainer" style={{ position: "absolute", left: 0, top: 0 }}>
				<canvas className="previewCanvas" />
				<div style={{ position: 'absolute', top: 0, left: 0 }} ref={panCanvasWrapper}>
					<canvas className="panCanvas" />
				</div>
				<span className="previewCanvasLabel" style={{ position: 'absolute', top: 0, left: 0 }} />
				<div className="zooming-btn-wrapper">
					<div onClick={() => setZoom(prev => prev < 2 ? prev + 0.5 : prev)}>
						<FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{ color: "#6a3089" }} />
					</div>
					<div onClick={() => setZoom(prev => prev > 1 ? prev - 0.5 : prev)}>
						<FontAwesomeIcon icon={faMagnifyingGlassMinus} style={{ color: "#6a3089" }} />
					</div>
				</div>
			</div>
			<div className={`edit-svg ${isEditing ? '' : 'hidden'}`}>
				<span className="hyperlink" onClick={() => canvas.closeSVGEditing()}>Close Editing</span>
			</div>
		</div>
	</div>;
};