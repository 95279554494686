import React, { useEffect, useState } from 'react'
import classes from './ImageDropDown.module.css';
import dropDownImage from '../../assets/dropdown-outline.svg';

function ImageDropDown({ options, onSelect }) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (options.length > 0 && !selectedOption) {
            setSelectedOption(options[0]);
            onSelect?.(options[0]);
        }
    }, [options, onSelect, selectedOption]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelect?.(option);
        setIsOpen(false);
    };

    return (
        <div className={classes.dropDown}>
            <div
                className={isOpen ? classes.selectedOptionOpen : classes.selectedOption}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption && (
                    <img
                        src={selectedOption.imageSrc}
                        alt={selectedOption.country}
                        className={classes.selectedDropImage}
                    />
                    
                )}
                <img src={dropDownImage} className={classes.dropIcon} alt="" />
            </div>
            {isOpen && (
                <ul className={classes.dorpItems}>
                    {options.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleOptionClick(option)}
                            className={selectedOption === option ? classes.selectedDropItem : classes.dropItem}
                        >
                            <img
                                src={option.imageSrc}
                                alt={option.country}
                                className={classes.itemImage}
                            />
                            {option.country} 
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default ImageDropDown