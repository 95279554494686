import React from "react";
import classes from './Card.module.css';
import Utils from "../../Utils";

function Card(props) {
    const backColor = props.backColor || 'white';
    const padding = props.padding || '2rem 2rem';
    const shadow = props.hasShadow ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "none";
    const borderRadius= props.borderRadius || '6px'
    return (
        <div
            className={Utils.getClasses(classes.card, props.className)}
            style={{
                ...props.style,
                width: props.width,
                height: props.height,
                backgroundColor: backColor,
                boxShadow: shadow,
                padding: padding,
                borderRadius: borderRadius
            }}
        >
            {props.children}
        </div>
    );
}

export default Card;