import React, { useState } from "react";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import classes from "./WorkShopForm.module.css";
import Utils from "../../Utils";
import { workShopRegistration } from "../../modules/WorkShops/workShopActions";
import MessageDialog from "../MessageDialog/MessageDialog";
import Consts from "../../Consts";
import { useHistory } from "react-router";
import nameIcon from "../../assets/icons/username.svg";
import slotIcon from "../../assets/icons/choose a slot.svg";
import phoneIcon from "../../assets/icons/phonenumber.svg";
import schoolIcon from "../../assets/icons/schoolname.svg";
import emailIcon from "../../assets/icons/mail.svg";
import cityIcon from "../../assets/icons/school address.svg";
import { useEffect } from "react";

function firstSaturday(month, year) {
  const firstSaturdayDate = new Date(Date.UTC(year, month, 1));
  const offset = 6 - firstSaturdayDate.getDay();
  firstSaturdayDate.setDate(firstSaturdayDate.getDate() + offset);
  return firstSaturdayDate;
}

function thirdSaturday(month, year) {
  const saturdayOfMonth = firstSaturday(month, year);
  saturdayOfMonth.setDate(saturdayOfMonth.getDate() + 14);
  return saturdayOfMonth;
}

const WorkShopForm = ({
  workShopName,
  inputClassName,
  source,
  buttonStyle,
  icon,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    phoneNumber: "",
    schoolName: "",
    email: "",
    slot: 0,
    workshop: workShopName,
  });

  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [displaySlot, setDisplaySlot] = useState(null);
  const [registrationMessage, setRegistrationMessage] = useState({
    title: "",
    message: "",
  });

  const [errorObj, setErrorObj] = useState({
    value: true,
    message: "",
  });

  useEffect(() => {
    let dates = [{ option: "Choose a Slot", val: 0, disabled: true }];

    const currentDate = new Date();

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const reqDates = 3;
    for (let i = 0, mon = currentMonth; i < reqDates; ++mon) {
      const month = mon % 12;
      const year = currentYear + Math.floor(mon / 12);
      const sat1 = firstSaturday(month, year);
      if (sat1 > currentDate) {
        ++i;
        dates.push({
          option: sat1.toLocaleString("en-IN", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
          val: sat1.getTime(),
        });
      }
      const sat3 = thirdSaturday(month, year);
      if (sat3 > currentDate) {
        ++i;
        if (i <= reqDates) {
          dates.push({
            option: sat3.toLocaleString("en-IN", {
              day: "numeric",
              month: "long",
              year: "numeric",
            }),
            val: sat3.getTime(),
          });
        }
      }
    }
    setDisplaySlot(dates);
  }, []);

  const handlWorkShopForm = (e, cb = (v) => v) => {
    let { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: cb(value) }));
  };

  const formValidate = () => {
    for (const [key, value] of Object.entries(formData)) {
      if (typeof formData[key] === "number") {
        continue;
      } else if (formData[key].trim() === "") {
        setErrorObj({
          value: false,
          message: "*Please fill all the required fields",
        });
        return false;
      }
    }

    if (formData.workshop === "student" && formData.slot === 0) {
      setErrorObj({
        value: false,
        message: "*Please fill all the required fields",
      });
      return false;
    }

    if (formData.phoneNumber) {
      if (!checkValidPhoneNumber(formData.phoneNumber)) {
        setErrorObj({
          value: false,
          message: "Please enter correct 10 digit phone number",
        });
        return false;
      }
    }

    if (formData.email) {
      if (!checkValidEmail(formData.email)) {
        setErrorObj({
          value: false,
          message: "Email is not valid",
        });
        return false;
      }
    }

    return true;
  };

  const checkValidEmail = (value) => {
    if (value) {
      let isValidEmail = Utils.validateEmail(value);
      if (!isValidEmail.valid) {
        return false;
      }
    }
    return true;
  };

  const checkValidPhoneNumber = (value) => {
    if (value && value.toString().length !== 10) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (formValidate()) {
      try {
        const resp = await workShopRegistration({ ...formData, source });
        setFormData({
          name: "",
          city: "",
          phoneNumber: "",
          schoolName: "",
          email: "",
          slot: 0,
          workshop: workShopName,
        });

        setErrorObj({
          value: true,
          message: "",
        });

        if (workShopName === "student") {
          history.push({
            pathname: `${Consts.BASE_URL}/student/thankyou`,
            state: {
              from: history.location.pathname,
            },
          });
        }

        if (workShopName === "school") {
          history.push({
            pathname: `${Consts.BASE_URL}/school/thankyou`,
            state: {
              from: history.location.pathname,
            },
          });
        }
      } catch (error) {
        setShowSuccess(true);
        setRegistrationMessage({
          title: "Failed",
          message: "Looks like you have registered already",
        });
      }
    }
  };
  return (
    <>
      {showSuccess && (
        <MessageDialog
          title={registrationMessage.title}
          message={registrationMessage.message}
          onCancel={() => setShowSuccess(false)}
          isCancel
          closeText="Close"
          width={Consts.MESSAAGE_DIALOG_WIDTH}
          height={Consts.MESSAAGE_DIALOG_WIDTH}
        />
      )}
      <div className={Utils.getClasses(classes.workShopForm, inputClassName)}>
        <div className={classes.formInputFields}>
          {icon && <img src={nameIcon} alt="icon" />}
          <Input
            name="name"
            value={formData.name}
            placeholder="Enter full name*"
            onChange={handlWorkShopForm}
          />
        </div>
        <div className={classes.formInputFields}>
          {icon && <img src={cityIcon} alt="icon" />}
          <Input
            name="city"
            value={formData.city}
            placeholder="Enter city*"
            onChange={handlWorkShopForm}
          />
        </div>
        <div className={classes.formInputFields}>
          {icon && <img src={phoneIcon} alt="icon" />}
          <Input
            type="number"
            placeholder="Enter phone number*"
            onChange={handlWorkShopForm}
            value={formData.phoneNumber}
            name="phoneNumber"
            onKeyDown={(e) => {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }}
          />
        </div>
        <div className={classes.formInputFields}>
          {icon && <img src={emailIcon} alt="icon" />}

          <Input
            name="email"
            value={formData.email}
            placeholder="Enter email*"
            onChange={handlWorkShopForm}
          />
        </div>
        <div className={classes.formInputFields}>
          {icon && <img src={schoolIcon} alt="icon" />}
          <Input
            name="schoolName"
            value={formData.schoolName}
            placeholder="Enter school name*"
            onChange={handlWorkShopForm}
          />
        </div>
        {workShopName === "student" && (
          <div className={classes.formInputFields}>
            {icon && <img src={slotIcon} alt="icon" />}
            <select
              className={classes.input}
              name="slot"
              value={formData.slot}
              onChange={(e) => handlWorkShopForm(e, (v) => Number(v))}
            >
              {displaySlot &&
                displaySlot.map((dates, index) => (
                  <option
                    value={dates.val}
                    key={index}
                    disabled={dates?.disabled}
                  >
                    {dates.option}
                  </option>
                ))}
            </select>
          </div>
        )}
        {!errorObj.value && (
          <div className={classes.errorMsg}>
            <span>*{errorObj.message}</span>
          </div>
        )}
        <div>
          <PrimaryButton className={buttonStyle} onClick={handleSubmit}>
            Register Now
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default WorkShopForm;
