import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { podcastActions } from "../PodcastsSlice";
import classes from "../Podcasts.module.css";
import PodcastCard from "../../../components/PodcastCard/PodcastCard";
import ModalDialog from "../../../components/Dialog/Dialog";
import Podcast from "../Podcast/Podcast";

function PodcastListing() {
  const dispatch = useDispatch();
  const podcasts = useSelector((state) => state.podcast.podcasts);
  const activePodcast = useSelector((state) => state.podcast.podcast)
  const [show, setShow] = useState(false);

  const handleCardClick = (data) => {
    if (data.linkType === "youtube") {
      dispatch(podcastActions.fetchPodcast(data));
      setShow(true);
    } else window.open(data.link, "_blank");
  };
  return (
    <div className={classes.podcastsWrapper}>
      {podcasts.length ? (
        podcasts.map((e) => (
          <PodcastCard
            key={e.id}
            data={e}
            handleClick={() => handleCardClick(e)}
          />
        ))
      ) : (
        <div className={classes.errorWrapper}>
          <span className={classes.errorMsg}>No podcasts are available at the moment.</span>
        </div>
      )}
      {show && (
        <ModalDialog
          maxWidth={"lg"}
          fullWidth={"lg"}
          title={activePodcast?.title}
          content={<Podcast />}
          className={classes.modelDialog}
          onClose={(e) => setShow(false)}
          contentClassName={classes.eulaContainer}
        />
      )}
    </div>
  );
}

export default PodcastListing;
