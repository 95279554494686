import { createSlice } from "@reduxjs/toolkit";

const blogEventSlice = createSlice({
    name: 'blogEvents',
    initialState: {
        allBlogsList: [],
        totalBlogsCount:0,
    },
    reducers: {
        fetchBlogEvents: (state, action) => {
            state.allBlogsList = action.payload.blogList;
        },
        blogsTotalCount:(state, action) =>{
            state.totalBlogsCount = action.payload.count;
        }

    }

});

export const blogsEventAction = blogEventSlice.actions;
export default blogEventSlice.reducer;