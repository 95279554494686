import React from 'react'
import ModalDialog from './Dialog'
import SecondaryButton from '../Buttons/SecondaryButton'
import PrimaryButton from '../Buttons/PrimaryButton'

export default function PdfViewer({url, onClose,width, height, publishHandler, closeLabel='Close', className, contentClassName, primaryButtonText}) {
    return (
        <ModalDialog
            className={className}
            title="Preview"
            content={
                <iframe src={url} frameBorder="0" scrolling="no" />
            }
            contentClassName={contentClassName}
            width={width}
            height={height}
            onClose={onClose}
            footer={
                <>
                    <SecondaryButton onClick={onClose}>{closeLabel}</SecondaryButton>
                    {publishHandler && <PrimaryButton onClick={publishHandler}>{primaryButtonText || "Publish"}</PrimaryButton>}
                </>
            }
            scrollable={false}
        />
    )
}
