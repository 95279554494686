import axios from 'axios';
import { aboutAuthorAction } from './AboutAuthorSlice';

export function fetchAllAboutAuthorTemplates(dispatch) {
    let filter = {
        sortBy : `updateTime DESC`
    }
    return axios({
        url: `/designer/aboutAuthor?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: "get",
    }).then((response) => {
        if (response.status === 200) {
            let data = response.data;
            if (data.success) {
                dispatch(aboutAuthorAction.fetchAboutAuthorTemplates(({ templates: data.data })))
            }
            return response;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}