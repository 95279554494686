import React, { useEffect, useRef, useState } from 'react';
import classes from './NavBar.module.css';
import Utils from '../../Utils';

export default function NavBar({menuItems, className, itemCls, style}) {
    return <ul className={Utils.getClasses(classes.navBar, className)} style={style}>
        {
            menuItems.map((item, idx) => 
                <li key={idx} className={itemCls} onClick={item.onClick} >{item.title}</li>
            )
        }
    </ul>;
}