import React from "react";
import classes from "./BookLifeCard.module.css";

function BookLifeCard({
  image,
  title,
  description,
  numberImage,
  headingColor,
}) {
  return (
    <div className={classes.main}>
      <div className={classes.left}>
        <div className={classes.numberImage}>
          <img src={numberImage} alt='Number' />
        </div>
        <div className={classes.leftcontent}>
          <div className={classes.title} style={{ color: headingColor }}>
            {title}
          </div>
          <div className={classes.description}>{description}</div>
        </div>
        <div className={classes.pointLogo} style={{ background: headingColor }}>
          <img src={image} alt='Point logo' />
        </div>
      </div>
    </div>
  );
}

export default BookLifeCard;
