import { useState } from "react";
import classes from "./Storyline.module.css";
import AIData from "../../../../components/AIData/AIData";

export default function PlotTwist({ values }) {
  return (
    <div className={classes.container}>
      <div className={classes.plotsMain}>
        {values?.map((value, i) => (<AIData key={i} text={value} />))}
      </div>
    </div>
  );
}
