import React from "react";
import Utils from "../../Utils";
import classes from './PrimaryButton.module.css';

const PrimaryButton = ({className, onClick, style, disabled, children}) => {
    return <button
          className={Utils.getClasses(classes.primaryBtn, className)}
          onClick={onClick}
          style={style}
          disabled={disabled}
      >
          {children}
      </button>;
  }

export default PrimaryButton;