import React from 'react'
import classes from './Dashboard.module.css'

function DashboardInput({ onChange, type, id, className, placeholder, inputDisabled, disabled, label, inputStyle, onPaste, value, onKeyDown}) {
    
    return <div className={classes.main}>
        <label htmlFor={id} className={classes.userDetailsLabel}>{label}</label>
        {
            disabled ? <label className={className}>{placeholder}</label> :<input type={type}
            style={{ borderRadius: '4px', ...inputStyle }}
            id={id}
            className={className || classes.inputFelid}
            onPaste={onPaste}
            disabled={inputDisabled}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            onKeyDown={onKeyDown}
        />
        }
    </div>

}

export default DashboardInput