import React from 'react';
import classes from './ToolbarButton.module.css';

function ToolbarButton(props) {
    const pressed = props.state?.pressed ? classes['x-btn-pressed'] : '';
    return (
        <div onClick={props.onClick} className={`${classes['toolbar-button']} ${pressed}`} >
            <div className={classes.iconBox}>
                <img src={props.icon} />
            </div>
            <div className={classes.title}>{props.title}</div>
        </div>
    );
}

export default ToolbarButton;