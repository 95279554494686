import axios from '../../../../../axios';
import Utils from '../../../../Utils';
import uploadImage, { uploadImagesAction } from './UploadImageSlice'


export function uploadUserImages(dispatch, files) {
    let formData = new FormData()
    files && files.forEach((file, i) => {
        formData.append("files", file);
    });
    return axios({
        url: '/designer/userImages',
        method: 'post',
        data: formData,
        responseType: 'json',
        headers: {
            'content-type': 'multipart/form-data'
        },
    }).then(function (response) {
        let data = response.data
        if (data.success) {
            dispatch(uploadImagesAction.uploadedImage({ images: data.data || [] }))
        }
    })
}

export function deleteUserImage(dispatch, fileId) {
    return axios({
        url: `/designer/userImages/${fileId}`,
        method: 'delete'
    }).then(({ data }) => {
        if (data.success) {
            dispatch(uploadImagesAction.deleteImage(fileId))
        }
    })
}


export function deleteUserImages(ids) {
    return axios({
        url: `/designer/userImages`,
        method: 'delete',
        data: ids
    })
}

export function fetchAllUserImages(dispatch) {
    const currentuser = Utils.getCurrentUser()
    if (!currentuser.userId) {
        return;
    }
    let filter = {
        where: {
            userId: currentuser.userId
        }
    }
    return axios({
        url: `/designer/userImages?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: 'get',
        responseType: 'json',
    }).then(function (response) {
        let data = response.data
        if (data.success) {
            dispatch(uploadImagesAction.fetchUserImages({ images: data.data || [] }))
        }
    })
}