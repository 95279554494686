import React, { useState } from "react";
import classes from "./Home.module.css";
import personIcon from "../../assets/person-icon.svg";
import Dashboard from "../Books/UserDashboardComponents/Dashboard/UserDashboard";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Divider from "../../components/Divider/Divider";
import { useHistory } from "react-router";
import Utils from "../../Utils";
import OrderHistory from "../Books/UserDashboardComponents/OrderHistory/OrderHistory";
import Profile from "../Books/UserDashboardComponents/Profile/Profile";
import Library from "../Books/UserDashboardComponents/Library/Library";
import BankDetails from "../Books/UserDashboardComponents/BankDetails/BankDetails";
import dashboardIcon from "../../assets/dashboardImage/dashboard-icon-home.svg";
import dashboardActiveIcon from "../../assets/dashboardImage/dashboard-icon-home-active.svg";
import profileIcon from "../../assets/dashboardImage/dashboard-icon-profile.svg";
import profileActiveIcon from "../../assets/dashboardImage/dashboard-icon-profile-active.svg";
import libraryIcon from "../../assets/dashboardImage/dashboard-icon-library.svg";
import libraryActiveIcon from "../../assets/dashboardImage/dashboard-icon-library-active.svg";
import historyIcon from "../../assets/dashboardImage/dashboard-icon-history.svg";
import historyActiveIcon from "../../assets/dashboardImage/dashboard-icon-history-active.svg";
import bankDetailsIcon from "../../assets/dashboardImage/dashboard-icon-bank-details.svg";
import bankDetailsActiveIcon from "../../assets/dashboardImage/dashboard-icon-bank-details-active.svg";
import earningsIcon from "../../assets/dashboardImage/dashboard-icon-earnings.svg";
import earningsActiveIcon from "../../assets/dashboardImage/dashboard-icon-earnings-active.svg";
import notificationIcon from "../../assets/dashboardImage/dashboard-icon-notification.svg";
import notificationActiveIcon from "../../assets/dashboardImage/dashboard-icon-notification-active.svg";
import logoutIcon from "../../assets/dashboardImage/dashboard-icon-logout.svg";
import logoutActiveIcon from "../../assets/dashboardImage/dashboard-icon-logout-active.svg";
import Earnings from "../Books/UserDashboardComponents/Earnings/Earnings";
import Notification from "../Books/UserDashboardComponents/Notification/Notification";
import { logout } from "../Users/UsersActions";
import MyAchievements from "../Books/UserDashboardComponents/MyAchievements/MyAchievements";
import MyAchievementsIcon from "../../assets/dashboardImage/dashboard-icon-my-achivements.svg";
import MyAchievementsActiveIcon from "../../assets/dashboardImage/dashboard-icon-my-achivements-active.svg";
import MainFooter from "../../components/MainFooter/MainFooter";
import { useDispatch } from "react-redux";

const menuItems = [
  {
    title: "Dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    url: `/dashboard`,
  },
  {
    title: "Profile",
    icon: profileIcon,
    activeIcon: profileActiveIcon,
    url: `/profile`,
  },
  {
    title: "Library",
    icon: libraryIcon,
    activeIcon: libraryActiveIcon,
    url: `/library`,
  },
  {
    title: "Order History",
    icon: historyIcon,
    activeIcon: historyActiveIcon,
    url: `/order-history`,
  },
  {
    title: "Bank Details",
    icon: bankDetailsIcon,
    activeIcon: bankDetailsActiveIcon,
    url: `/bank-details`,
  },
  {
    title: "Earnings",
    icon: earningsIcon,
    activeIcon: earningsActiveIcon,
    url: `/earnings`,
  },
  {
    title: "Achievements",
    icon: MyAchievementsIcon,
    activeIcon: MyAchievementsActiveIcon,
    url: `/my-achievements`,
  },
  {
    title: "Notifications",
    icon: notificationIcon,
    activeIcon: notificationActiveIcon,
    url: `/notifications`,
  },
  {
    title: "Logout",
    icon: logoutIcon,
    activeIcon: logoutActiveIcon,
  },
];

function Home() {
  const { pathName, url } = useRouteMatch();
  const user = Utils.getCurrentUser();
  const history = useHistory();
  const { location } = history;
  const dispatch = useDispatch();

  return (
    <>
      <div className={classes.main}>
        <div className={classes.horizontalNavbar}>
          {menuItems.map((item, idx) => {
            const completeUrl = `${url}${item.url}`;
            const isActive = location.pathname === completeUrl;
            return (
              <NavItem
                key={idx}
                item={item}
                isActive={isActive}
                onClick={() => {
                  if (item.url) {
                    history.push(completeUrl);
                  } else {
                    logout(history, dispatch);
                  }
                }}
              />
            );
          })}
        </div>
        <div className={classes.sidebarAndContainerWrapper}>
          <div className={classes.left}>
            <div className={classes.userDetailsWrapper}>
              <h1 className={classes.myAccountHeading}>My Account</h1>
              <div className={classes.userImageAndNameWrapper}>
                <img
                  src={user.photoURL || personIcon}
                  className={classes.userImage}
                  alt=""
                />
                <p className={classes.userName}>{user?.fullName}</p>
              </div>
            </div>
            <Divider
              style={{
                width: "100%",
                background: "var(--selection-dark)",
                height: "1px",
              }}
            />
            <div className={classes.navigation}>
              <ul className={classes.navItems}>
                {menuItems.map((item, idx) => {
                  const completeUrl = `${url}${item.url}`;
                  const isActive = location.pathname === completeUrl;
                  return (
                    <NavItem
                      key={idx}
                      item={item}
                      isActive={isActive}
                      onClick={() => {
                        if (item.url) {
                          history.push(completeUrl);
                        } else {
                          logout(history, dispatch);
                        }
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={classes.right}>
            <Switch>
              <Route path={`${url}/dashboard`} component={Dashboard} />
              <Route path={`${url}/profile`} component={Profile} />
              <Route path={`${url}/library`} component={Library} />
              <Route path={`${url}/order-history`} component={OrderHistory} />
              <Route path={`${url}/bank-details`} component={BankDetails} />
              <Route path={`${url}/earnings`} component={Earnings} />
              <Route path={`${url}/notifications`} component={Notification} />
              <Route
                path={`${url}/my-achievements`}
                component={MyAchievements}
              />
            </Switch>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
}

function NavItem({ item, isActive, onClick }) {
  const [isHover, setIsHover] = useState(false);
  const history = useHistory();

  return (
    <li
      className={
        Utils.getClasses(classes.navItem, isActive && classes.active) +
        " cursorPointer"
      }
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={classes.navItemContentWrapper}>
        <img src={isActive || isHover ? item.activeIcon : item.icon} />
        <div className={classes.navTitle}>{item.title}</div>
      </div>
    </li>
  );
}

export default Home;
