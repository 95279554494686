import React, { useEffect, useState } from "react";
import classes from "./Accordion.module.css";
import chevronDownIcon from "../../assets/icons/Icon-chevron-down-circle.svg";
import Utils from "../../Utils";

function Accordion({ title, children, active, onActiveChange }) {
  const [isActive, setIsActive] = useState(false);
  const [bodyClass, setBodyClass] = useState("");

  useEffect(() => {
    if (isActive) {
      setBodyClass(classes.collapsing);
      const timer = setTimeout(() => {
        setBodyClass(classes.bodyShow);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setBodyClass("");
    }
  }, [isActive]);

  return (
    <div className={Utils.getClasses(classes.main, isActive && classes.activeMain)}>
      <div
        className={classes.header}
        onClick={() => setIsActive((prev) => !prev)}
      >
        <span className={classes.title}>{title}</span>
        <div className={Utils.getClasses(classes.icon, isActive && classes.active)}>
          <img src={chevronDownIcon} alt="down" />
        </div>
      </div>
      <div className={Utils.getClasses(classes.body, bodyClass)}>
        {children}
      </div>
    </div>
  );
}

export default Accordion;
