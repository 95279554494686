import axios from "axios";

export function getCertificate(bookId) {
    return axios({
      url: `/designer/books/downloadCertificate/${bookId}`,
      method: "get",
      responseType: "blob",
    }).then((response) => {
        return response.data;
    }).catch((error) => {
      console.log(error);
    });
}