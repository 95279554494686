import React from "react";

function JustifiedCenter() {
  return (
    <svg
      id="Layer_6"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 6"
      width="300"
      height="300"
      version="1.1"
      fill=""
    >
      <g transform="matrix(1,0,0,1,0,0)">
        <path
          d="m34 101.79h444c18.78 0 34-15.22 34-34s-15.22-34-34-34h-444c-18.78.01-34 15.23-34 34.01s15.22 34 34 34z"
          fill="#000000ff"
          data-original-color="#000000ff"
          stroke="none"
        />
        <path
          d="m34 227.27h444c18.78 0 34-15.22 34-34s-15.22-34-34-34h-444c-18.78 0-34 15.22-34 34s15.22 34 34 34z"
          fill="#000000ff"
          data-original-color="#000000ff"
          stroke="none"
        />
        <path
          d="m34 352.74h444c18.78 0 34-15.22 34-34s-15.22-34-34-34h-444c-18.78 0-34 15.22-34 34s15.22 34 34 34z"
          fill="#000000ff"
          data-original-color="#000000ff"
          stroke="none"
        />
        <path
          d="m409.31 478.2c18.78 0 34-15.22 34-34s-15.22-34-34-34h-306.63c-18.78 0-34 15.22-34 34s15.22 34 34 34z"
          fill="#000000ff"
          data-original-color="#000000ff"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default JustifiedCenter;
