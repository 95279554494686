import React from 'react'
import classes from './Static.module.css';
import StaticHeader from './StaticHeader';
import Divider from '../../components/Divider/Divider';
import StaticContent from './StaticContent';
import refundAndCancellations from '../../assets/staticScreenAnimation/refund-and-cancellation.json'
import MainFooter from '../../components/MainFooter/MainFooter';
import BackButton from '../../components/Buttons/BackButton';
import HelmetProvider from '../../components/Helmet/Helmet';
const content = [
    {
        title: "",
        description: [
            `Authors who are under the age of 18 may register on the site through their parents or legal guardians. The Company retains the right, in its sole discretion, to reject a book or deactivate a user account. It is also deserving of the right to return the book copy(s) that were ordered`
        ]
    },
    {
        title: "In case of any of the below, the User shall be refunded 100% of the payment.",
        description: [
            <ul className={classes.refundList}>
                <li>The portal is continually down for more than 3 days without any scheduled notice.</li>
                <li>User access to the portal is cancelled without prior notification.</li>
                <li>The company is unable to provide the hard copy of the books requested by the user in 45 days after completion and publication of the book on the portal.</li>
            </ul>
        ]
    },
    {
        title: '',
        description: [
            <>For any queries, the User can write to <a href="mailto:info@bookalooza.com">info@bookalooza.com</a></>
        ]
    }
]

function RefundAndCancellations() {
    return (
        <>
            <HelmetProvider
                title={"Bookalooza- Write, Publish and Earn through Books"}
                description={
                    "Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book."
                }
                schema={
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Bookalooza",
                        "url": "https://www.bookalooza.com/refund-cancellation",
                        "logo": "https://www.bookalooza.com/logo.png",
                        "sameAs": [
                            "https://www.facebook.com/bookalooza",
                            "https://www.instagram.com/bookalooza/",
                            "https://www.youtube.com/@Bookalooza",
                            "https://www.linkedin.com/company/bookalooza/",
                            "https://x.com/bookalooza"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Customer Service",
                            "telephone": "+91-8799721408",
                            "email": "support@bookalooza.com"
                        }
                    }}
            />
            <div className={classes.main}>
                <div className={classes.backButtonWrapper}>
                    <BackButton className={classes.backButton} />
                </div>
                <div className={classes.container}>
                    <StaticHeader title={"Refund And Cancellations"} description={<>For children of grade1 to 12, Orange Vtech Pvt Ltd. offers a platform, named Bookalooza at <a href="https://www.bookalooza.com" target='blank'>https://www.bookalooza.com</a></>} animation={refundAndCancellations} />
                    <Divider style={{ marginBottom: '2rem' }} />
                    <div className={classes.contentWrapper}>
                        {
                            content?.map((item, idx) => {
                                return <StaticContent key={idx} title={item?.title} description={item.description} />
                            })
                        }
                    </div>
                </div>
            </div>
            <MainFooter />
        </>
    )
}

export default RefundAndCancellations