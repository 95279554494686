import React from "react";
import classes from "./CheckBoxPrimary.module.css";

function CheckBoxPrimary({ onChange, defaultChecked, value, disabled }) {
  return (
    <input
      className={classes.checkBox}
      checked={value}
      defaultChecked={defaultChecked}
      disabled={disabled}
      type="checkbox"
      onChange={(e) => onChange(e)}
    />
  );
}

export default CheckBoxPrimary;
