import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  podcasts: [],
  podcast: {}
};

const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  reducers: {
    fetchPodcasts: (state, action) => {
      state.podcasts = action.payload;
    },
    fetchPodcast: (state, action) => {
      state.podcast = action.payload
    }
  },
});

export const podcastActions = podcastSlice.actions;
export default podcastSlice.reducer;
