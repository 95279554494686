import React from 'react'
import classes from './ScrollToTopButton.module.css';
// import upIcon from '../../assets/icons/up-arrow-svgrepo-com.svg';
import upIcon from '../../assets/icons/up-round-svgrepo-com-orange.svg';


import { useEffect } from 'react';
import { useState } from 'react';
const ScrollToTopButton = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
  return (
    <div className={classes.scrollToTop} onClick={goToTop}>
          { showTopBtn && <img src={upIcon} className={classes.iconPosition}  />}
    </div>
  )
}

export default ScrollToTopButton