import React, { useState, useEffect } from "react";
import OTPInput from "../Input/OTPInput";
import backArrow from "../../assets/back-arrow-icon.svg";
import Card from "../Card/Card";
import classes from "./SignUp.module.css";
import LinkButton from "../Buttons/LinkButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const otpSize = 6;
export default function OTPForm({onCreate, resendOTP, onBack, errMsg, submitLabel="Create"}) {
    const [otp, setOTP] = useState('');
    return <Card borderRadius='24px' backColor="white" padding='4rem 4rem'>
        <div className={classes.main}>
            <div className={classes.heading}>
                <img src={backArrow} width={24} height={24}
                    style={{float: 'left'}}
                    alt="Back Arrow Icon" onClick={onBack}
                />
                <h2>OTP Verification</h2>
                <p>Hey! Enter OTP sent to your mobile number and email.</p>
            </div>
            <div className={classes.container}>
                <OTPInput size={otpSize} onCommit={setOTP}/>
                <div className={classes.resendButton}>
                    <span>Didn't Receive code ? </span>
                    <LinkButton onClick={resendOTP} label="Resend" />
                </div>
                {!errMsg && <p className={classes.errorMsg}>{errMsg}</p>}
                <div className={classes.actions} style={{ justifyContent: 'center' }}>
                    <PrimaryButton onClick={e => onCreate({otp})}
                        style={{ width: '100%', height: "2.5rem", fontSize: '0.875rem' }}
                        disabled={otp.length !== otpSize}
                    >
                        {submitLabel}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    </Card>
}