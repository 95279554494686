import React, { useState, useEffect } from "react";
import { useActiveFabricObject, useFabricCanvas } from "../../../FabricCanvas";
import TextShapes from './TextShapes';
import { Slider } from "../../../../../components/Sliders/Sliders";
import ToolPanelButton from "../../../../../components/Buttons/ToolPanelButton";
import SliderButton from "../../../../../components/Buttons/SliderButton";
import { ActiveTextObject, addTextObject } from "../../../objectUtils";

function getProperties(textObject) {
    var propertiesToInclude = [
        'savedTextValue',
        'skipDrawing',
        'startScaleX',
        'startScaleY',
        'warpMode',
        'warpAmount',
        'tracking'
    ];
    textObject.clipTo = null;

    var options = textObject.toObject(propertiesToInclude);
    delete options.type;
    delete options.text;

    return options;
}

function isNullOrUndef(val) {
    return val === null || val === undefined;
}

export default function Shapes() {
    const [activeObject, update] = useActiveFabricObject(ActiveTextObject);
    const canvas = useFabricCanvas();

    const type = activeObject.get('type');
    const radius = activeObject.get('radius');
    const placement = activeObject.get('placement');
    const warpMode = activeObject.get('warpMode');
    const warpAmount = activeObject.get('warpAmount');
    const isCurved = type === 'curved-text';
    return <>
        <div style={{ marginBottom: '.5rem', marginTop: "1rem" }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Curved Text</label>
            <SliderButton size="small" checked={isCurved} style={{ marginLeft: '0.5rem', marginTop: '0.5rem', height: '50%' }}
                onChange={val => {
                    try {
                        canvas.mute();
                        let target = activeObject.getTargetObjects()[0];
                        addTextObject(canvas, target.text, getProperties(target), !isCurved);
                        target.getParent().remove(target);
                        activeObject.fireModified();
                    } finally {
                        canvas.unmute();
                    }
                }}
                disabled={type ? false : true}
                offColor="#FFF"
                onColor="#FFF"
                offHandleColor="#0E5FBC"
                onHandleColor="#0E5FBC"
                uncheckedLabel="Off"
                checkedLabel="On"
            />
        </div>
        {
            isCurved ?
                <>
                    <div className={isNullOrUndef(radius) ? 'x-item-disabled' : ''}>
                        <label className="editor-text" style={{ fontSize: "1rem" }}>Radius</label>
                        <div style={{ margin: '0 10px' }}>
                            <Slider min={25} max={150}
                                value={Number(radius) || 30}
                                onChange={(ev, val) => {
                                    activeObject.set('radius', val);
                                    canvas.renderAll();
                                }}
                                onChangeCommitted={(ev, val) => {
                                    activeObject.fireModified();
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="editor-text" style={{ fontSize: "1rem" }}>Position</label>
                        <div style={{ display: 'flex', marginTop: '0.5rem' }}>
                            <ToolPanelButton
                                pressed={placement === 'outside'}
                                className={`${placement === 'outside' ? "img-curved-text-outside-selected" : "img-curved-text-outside"} text-view-btn-style2`}
                                onClick={e => {
                                    activeObject.set('placement', 'outside');
                                    canvas.renderAll();
                                    update();
                                }}
                            />
                            <ToolPanelButton
                                state={{ pressed: placement === 'inside' }}
                                className={`${placement === 'inside' ? "img-curved-text-inside-selected" : "img-curved-text-inside"} text-view-btn-style2`}
                                onClick={e => {
                                    activeObject.set('placement', 'inside');
                                    canvas.renderAll();
                                    update();
                                }}
                                style={{ marginLeft: '0.5rem' }}
                            />
                        </div>
                    </div>
                </>
                :
                <>
                    <div style={{ marginTop: '1rem'}}>
                        <label className="editor-text" style={{ fontSize: "1rem" }}>Shape Modifier</label>
                        <div style={{ marginTop: '0.5rem' }} className={isNaN(warpAmount) ? 'x-item-disabled' : ''}>
                            <Slider min={0} max={10}
                                value={isNaN(warpAmount) ? 5 : warpAmount}
                                onChange={(ev, val) => {
                                    activeObject.set("warpAmount", val);
                                    canvas.renderAll();
                                }}
                                onChangeCommitted={(ev, val) => {
                                    update();
                                    activeObject.fireModified();
                                }}
                            />
                        </div>
                    </div>
                    <label className="editor-text" style={{ fontSize: "1rem", marginBottom: '0.5rem' }}>Shapes</label>
                    <div className={isNaN(warpMode) ? 'x-item-disabled' : ''} style={{marginBottom: "4rem"}}>
                        <TextShapes value={warpMode} onChange={val => {
                            activeObject.set('warpMode', val);
                            canvas.renderAll();
                            activeObject.fireModified();
                            update();
                        }} style={{ marginTop: '0.5rem' }} />

                    </div>
                </>
        }
    </>
}