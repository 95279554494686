import React from "react";
import classes from './IconButton.module.css';

function IconButton(props) {
    const iconBtn = `btn btn-sm ${classes.iconBtn}`;

    if (!props.tooltip) {
        return (
            <button type="button" disabled={props.disabled}
                onClick={props.onClick}
                className={`${iconBtn} ${classes.btnWrapper}`}
                style={props?.style}>
                <img src={props.iconImage} style={props.iconStyle} alt={props.altText} />
            </button>
        );
    }

    return (
        <div className={`${classes.tooltip} ${classes.btnWrapper}`} disabled={props.disabled}>
            <button type="button"
                onClick={props.onClick}
                className={iconBtn}
                title={props.tooltip}
                style={props?.style}>
                <img src={props.iconImage} className={classes.iconImage} style={props.iconStyle} alt={props.tooltip} />
            </button>
            <span className={classes.tooltiptext}>{props.tooltip}</span>
        </div>
    );
}

export default IconButton;