import React, { useEffect } from "react";
import classes from "./WorkShops.module.css";
import WorkShopForm from "../../components/WorkShopForm/WorkShopForm";
import MainFooter from "../../components/MainFooter/MainFooter";

import schoolBannerImage from "../../assets/workShop/school/School_Banner_Image_CMP.jpg";

import creativeIcon from "../../assets/workShop/school/school_icons/Creative Writing Exploration.png";
import craftingIcon from "../../assets/workShop/school/school_icons/Crafting Your Story.png";
import writingIcon from "../../assets/workShop/school/school_icons/Writing Techniques.png";
import editingIcon from "../../assets/workShop/school/school_icons/Editing and Polishing.png";
import publicationIcon from "../../assets/workShop/school/school_icons/Publication _ Earnings.png";
import portfolioIcon from "../../assets/workShop/school/school_icons/Portfolio Development.png";

import superFeatureImage from "../../assets/workShop/school/school_feature_images/Express-Yourself-Like-a-Superhero.png";
import wizardFeatureImage from "../../assets/workShop/school/school_feature_images/Become-a-Word-Wizard.png";
import imaginationFeatureImage from "../../assets/workShop/school/school_feature_images/Make-Friends-with-Imagination.png";
import brainPowerFeatureImage from "../../assets/workShop/school/school_feature_images/Boost-Your-Brain.png";
import worriesFeatureImage from "../../assets/workShop/school/school_feature_images/Write-Away-Your-Worries.png";
import scoreFeatureImage from "../../assets/workShop/school/school_feature_images/Score-A+-in-School-and-Life.png";

import moreinfoImage from "../../assets/workShop/school/School-Single-Image.png";
import Utils from "../../Utils";
import HelmetProvider from "../../components/Helmet/Helmet";




const HeroSection = () => {
  return (
    <section
      className={classes.heroSection}
      style={{
        backgroundImage: `url(${schoolBannerImage})`,
      }}
    >
      <div className={classes.heroContent}>
        <div className={classes.companyName}>
        </div>
        <div className={classes.heroTitleContainer}>
          <h1>
            <span className={classes.heroTitle}>
              Creative Classrooms. Where Ideas Bloom.
            </span>
          </h1>
        </div>
        <div className={classes.heroDescritionContainer}>
          <p>
            <span className={classes.heroDescription}>
              Invest in your students' future by enhancing their writing skills.
              Bookalooza's workshop focuses on refining their craft and building
              confidence.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterTopSection = () => {
  return (
    <section id="quickQuestion" className={classes.registerTopSection}>
      <div className={classes.registerTopContent}>
        <div className={classes.registerTopLeftPara}>
          <h1 style={{ fontSize: '3rem' }}>Quick Question</h1>
          <p>
            Feeling a bit lost in the world of words? Don't worry, you're not
            alone. Nowadays, we need to connect through words more than ever.
          </p>
          <p>
            <span>Our zero-cost creative writing workshop </span>is designed to
            kickstart students' writing journeys, empowering them to express
            themselves more eloquently and authentically.
          </p>
          <p>
            At Bookalooza, we think everyone can be a wordsmith! That's why we
            have this awesome creative writing workshop- it’s a{" "}
            <span>cost-free workshop!</span> It doesn't matter what stories you
            want to tell, our creative writing workshop will give you the tools
            and inspiration to write better and stronger, connecting hearts and
            minds.
          </p>
          <p>
            Whether it's fiction, poetry, or any genre that sparks their
            creativity, our workshop ignites the creative flame and inspires
            young minds to craft words that not only tell stories but build
            connections that endure.
          </p>
        </div>

        <div className={classes.registerForm}>
          <h2 className={classes.registerFormTitle}>
            Register for the <span style={{ color: 'var( --button-color-yellow)' }}>No-Fee</span> Creative Writing Workshop Now!
          </h2>
          <WorkShopForm
            workShopName={"school"}
            inputClassName={classes.formInputBox}
          />
        </div>
      </div>
    </section>
  );
};

const BenefitsSection = () => {
  return (
    <section className={classes.benefitsSection}>
      <div className={classes.benefitsContent}>
        <h1 style={{ fontSize: '2rem' }}>
          Unlock the Writer in Every Student with Bookalooza's Creative Writing
          Workshop
        </h1>
        <div className={classes.benefitsList}>
          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={creativeIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Creative Writing Exploration</h3>
            <p>
              <span>Unleash Imagination:</span> Dive into the world of
              creativity, where every idea is a masterpiece waiting to be born.
            </p>
          </div>
          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={craftingIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Crafting Your Story</h3>
            <p>
              <span style={{ fontWeight: "bold" }}> Plot and Characters:</span>{" "}
              Learn to create captivating plots and characters that readers will
              love.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}> Setting the Scene:</span>{" "}
              Master the art of describing settings that transport readers into
              your world.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={writingIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Writing Techniques</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Hooks and Cliffhangers:
              </span>{" "}
              Discover the secrets of keeping your readers hooked from start to
              finish.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}> Show, Don't Tell:</span>{" "}
              Learn to make your writing come alive by showing emotions and
              actions.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={editingIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Editing and Polishing</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>Polish Your Prose:</span>{" "}
              Discover the secrets of keeping your readers hooked from start to
              finish.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Grammar and Punctuation:
              </span>{" "}
              Learn to make your writing come alive by showing emotions and
              actions.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={publicationIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Publication & Earnings</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>Publish Your Book:</span>{" "}
              Explore the thrilling journey of transforming your manuscript into
              a published book.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Earn Through Your Words:
              </span>{" "}
              Learn how to earn from your creativity and share your stories with
              the world.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={portfolioIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Portfolio Development</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>Create a strong writing portfolio:</span>{" "}
              Writers can showcase their progress and enhance credibility when submitting their work.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Certificate and Badges:
              </span>{" "}
              Students will be recognized for their achievements through awards and certificates.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const ClassRoomTalesSection = () => {
  return (
    <section className={classes.classRoomTalesSection}>
      <div className={classes.classRoomContent}>
        <h1 style={{ fontSize: '2rem' }}>Classroom Tales, Where Creativity Prevails! </h1>
        <h4 style={{ fontSize: '1.5rem' }}>What’s more to learn from this workshop?</h4>
        <div className={classes.talesListContainer}>
          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={superFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Express Yourself Like a Superhero!</h4>
              <p>
                Creative writing lets you unleash your inner superhero! With
                words as your superpower, you can express feelings, ideas, and
                dreams in a way that's uniquely you. So, grab that pen and let
                your creativity soar!
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={wizardFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Become a Word Wizard!</h4>
              <p>
                Imagine having a magical wand that lets you conjure up entire
                worlds and adventures. That wand is your pen! Creative writing
                helps you become a word wizard, spinning tales that transport
                people to far-off lands and exciting places. Let the stories
                flow!
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={imaginationFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Make Friends with Imagination!</h4>
              <p>
                Creative writing is like a playground for your imagination. It's
                where your imagination gets to run wild, playing games, making
                new friends, and having awesome adventures. Let your imagination
                be the captain of your creative ship!
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={brainPowerFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Boost Your Brainpower!</h4>
              <p>
                Did you know creative writing is like a workout for your brain?
                It strengthens your thinking muscles, making you sharper, more
                inventive, and super creative. It's like brain gymnastics, but
                with words instead of jumping jacks!
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={worriesFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Write Away Your Worries!</h4>
              <p>
                Have a bad day or feeling blue? Grab a notebook and write it
                out! Creative writing is your secret diary, a safe space to pour
                out your emotions. It's like having a magical genie that listens
                to your thoughts and helps you feel lighter.
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={scoreFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Score A+ in School and Life!</h4>
              <p>
                When you master creative writing, you become a rockstar in
                academics! Essays, stories, presentations—you name it, you ace
                it! Plus, it's an essential skill for the future. So, let your
                creativity be your guide to success!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MoreInfoSection = () => {
  return (
    <section className={classes.moreInfoSection}>
      <div className={classes.moreInfoContent}>
        <div className={classes.moreInfoImageContainer}>
          <img src={moreinfoImage} alt="more info" />
        </div>
        <div className={classes.moreInfoText}>
          <h3>
            Are you ready to ignite the creative flame in your students?
          </h3>
          <p>
            Look no further! Bookalooza brings you the ultimate workshop to turn
            your students into storytelling wizards. Our expert trainers spill
            the secrets which are battle-tested and proven, to help students
            weave captivating tales that hook readers from start to finish. The
            best part? Students don’t need to empty their pockets on expensive
            courses; our workshop is a treasure trove of knowledge without the
            hefty price tag. Let's make skill development a regular part of
            their schedule, empowering young minds to flourish and conquer the
            literary world.
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterBottomSection = () => {
  return (
    <section className={classes.registerNowSection}>
      <div className={classes.registerNowContent}>
        <h1>Register for your Creative Writing Workshop Now!</h1>
        <div className={classes.registerNowForm}>
          <WorkShopForm
            workShopName={"school"}
            inputClassName={classes.formInputBox}
          />
        </div>
      </div>
    </section>
  );
};

const SchoolWorkShop = ({ location: { hash } }) => {
  useEffect(() => {
    if (hash) {
      Utils.scrollTo(hash);
    }
  }, [hash])
  return (
    <div>
      <HelmetProvider
        title={"School Writing Workshops - Empower Students with Bookalooza"}
        description={"Enroll your school in Bookalooza’s writing workshops designed to inspire and guide students in writing and publishing their own books with AI support."}
        keywords={"school writing workshops, student book writing, Bookalooza workshops, writing programs for schools, student publishing programs"}
        schema={{
          "@context": "https://schema.org",
          "@type": "Event",
          "name": "Bookalooza Writing Workshops for Schools",
          "url": "https://www.bookalooza.com/workshops/school",
          "description": "Enroll your school in Bookalooza’s writing workshops designed to inspire and guide students in writing and publishing their own books with AI support.",
          "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "name": "Online"
          }
        }}
      />
      <div className={classes.main}>
        <HeroSection />
        <RegisterTopSection />
        <BenefitsSection />
        <ClassRoomTalesSection />
        <MoreInfoSection />
        <RegisterBottomSection />
      </div>
      <MainFooter />
    </div>
  );
};

export default SchoolWorkShop;
