import React from "react";
import classes from './SecondaryButton.module.css';
import Utils from "../../Utils";

const SecondaryButton = (props) => {
    return <button
        className={Utils.getClasses(classes.secondaryBtn, props.className)}
        onClick={props.onClick}
        style={props.style}
        disabled={props.disabled}
        title={props.title}
    >
        {props.children}
    </button>;
}

export default SecondaryButton;