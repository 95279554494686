import React from "react";
import classes from "./PodcastCard.module.css";

function PodcastCard({ data, handleClick }) {
  return (
      <div className={classes.main} onClick={handleClick}>
        <div className={classes.thumbnailWrapper}>
          <img src={data?.thumbnailImagePath} alt={data.id} />
        </div>
        <div className={classes.titleWrapper}>
          <h3 className={classes.title}>{data?.title}</h3>
        </div>
      </div>
  );
}

export default PodcastCard;
