import React, { useEffect, useRef, useState } from "react";
import classes from "./TabControl.module.css";
import Utils from "../../Utils";

function TabControl({ options, activeOpt, onTabChange }) {
  const [visibleTabs, setVisibleTabs] = useState([]);
  const activeTabsRefs = useRef([]);
  const [handleStyle, setHandleStyle] = useState({ width: "0px", height: "0px" })
  const handleRef = useRef()

  useEffect(() => {
    if (activeOpt) {
      setVisibleTabs(
        options.map((tab) => ({
          ...tab,
          isActive: tab.action === activeOpt,
        }))
      );
    } else {
      setVisibleTabs(
        options.map((tab, idx) => ({ ...tab, isActive: idx === 0 }))
      );
    }
  }, [options, activeOpt]);

  useEffect(() => {
    const activeTabIdx = visibleTabs.findIndex((tab) => tab.isActive);
    const active = activeTabsRefs.current[activeTabIdx];
    if (active && handleRef.current) {
      handleRef.current.style.width = `${active.offsetWidth}px`;
      handleRef.current.style.height = `${active.offsetHeight}px`;
      setHandleStyle({ width: `${active.offsetWidth}px`, height: `${active.offsetHeight}px` })
      handleRef.current.style.left = `${active.offsetLeft}px`
      handleRef.current.style.top = `${active.offsetTop}px`
    }
  }, [visibleTabs]);

  const tabChangeHandler = (action, index) => {
    if (onTabChange) onTabChange(action);
    setVisibleTabs((prev) => {
      return prev.map((tab, idx) => {
        return idx === index
          ? { ...tab, isActive: true }
          : { ...tab, isActive: false };
      });
    });
  };

  return (
    <div className={classes.main}>
      <div className={Utils.getClasses(classes.handle)} ref={handleRef} id="h"></div>
      {visibleTabs.map((opt, idx) => (
        <div
          className={Utils.getClasses(
            classes.opt,
            opt.isActive ? classes.activated : ""
          )}
          key={opt.action}
          ref={(tab) => (activeTabsRefs.current[idx] = tab)}
          onClick={() => tabChangeHandler(opt.action, idx)}
        >
          {opt.title}
        </div>
      ))}
    </div>
  );
}

export default TabControl;
