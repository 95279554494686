import React, { useEffect, useState } from 'react';
import classes from './OrderHistory.module.css'
import UserDashboardHeader from '../UserDashboardHeader/UserDashboardHeader';
import Lottie from 'react-lottie-player';
import openBoxAnimation from '../../../../assets/dashboardImage/animation/order-histrory-open-box-animation.json'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { getOrders } from '../../BooksActions';
import { useSelector } from 'react-redux';
import Utils from '../../../../Utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyDialogue from '../EmptyDialogue/EmptyDialogue';
import FontAwesome from 'react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OrderHistory() {
    const history = useHistory()
    const [allOrders, setAllOrders] = useState([]);
    const [isOpen, setIsOpen] = useState([]);
    const userDetails = useSelector(state => state.user);
    useEffect(() => {
        if (userDetails.user) {
            let filter = {};
            getOrders({
                ...filter,
                where: { ...filter?.where, userId: userDetails.user.userId, status: "success" },
                sortBy: `creationTime DESC`
            }).then(function (response) {
                let data = response.data;
                if (data.success) {
                    setAllOrders(data.data)
                    setIsOpen(data.data.map((ele) => false))
                }
            })
        }
    }, [userDetails]);

    function goToStoreHandler() {
        Utils.openBookstore(history);
    }


    const toggleHandler = (index) => {
        setIsOpen(prevState => prevState.map((ele, idx) => idx === index ? !ele : false))
    }
    //sample data to be removed

    // class names for delivery status.
    // 1) delivered = classes.delivered
    // 2) Processing = classes.processing
    // 3) In Transit = classes.inTransit
    // 4) track order = classes.trackOrders

    return (
        <div className={classes.main}>
            <UserDashboardHeader
                title="Order History"
                description="Manage all your past orders here."
            />
            <div className={classes.container}>
                {allOrders && allOrders.length > 0 ? (
                    <div className={classes.orderTableContainer}>
                        <table className={classes.orderTable}>
                            <thead className={classes.orderTableHeading}>
                                <tr >
                                    <th className={classes.orderId}>ID</th>
                                    <th className={classes.orderDate}>Date</th>
                                    <th className={classes.quantity}>Items</th>
                                    <th className={classes.priceColumnHeading}>Price</th>
                                    <th className={classes.priceColumnHeading}>Shipping</th>
                                    <th className={classes.priceColumnHeading}>Total Amt</th>
                                    <th className={classes.statusColumnHeading}>Status</th>
                                    <th className={classes.actionColumnHeading}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allOrders?.map((order, orderIdx) => {
                                        const orderDate = Utils.getFormattedDate(order.creationTime);
                                        let orderId;
                                        if ((orderIdx + 1) < 10) {
                                            orderId = "#0" + (orderIdx + 1)
                                        }
                                        else {
                                            orderId = "#" + (orderIdx + 1)
                                        }

                                        let items = order.items

                                        let quantity;
                                        if ((items?.length) < 10) {
                                            quantity = "0" + (items?.length)
                                        }
                                        else {
                                            quantity = items?.length ?? '01'
                                        }

                                        return (
                                            <React.Fragment key={orderIdx} >
                                                <tr className={classes.productRow}>
                                                    <td className={classes.leftAlignedCell}>{orderId}</td>
                                                    <td className={classes.centeredCell}>{orderDate.split(" ")?.[0]}</td>
                                                    <td className={classes.centeredCell}>{quantity || '01'}</td>
                                                    <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {order.amount - order.shippingCharge}</td>
                                                    <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {order.shippingCharge}</td>
                                                    <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {order.amount}</td>
                                                    <td className={classes.centeredCell}><div className={classes.deliveryStatus}><div className={order.dispatchStatus === 'delivered' ? classes.delivered : classes.processing}>{order.dispatchStatus === 'Pending' ? 'In Process' : order.dispatchStatus}</div></div></td>
                                                    <td className={classes.actionCell}>
                                                        <div className={classes.actionWrapper}>
                                                            <div className={classes.actionsCell}>
                                                                <p className={classes.visitOurStore}>Details</p>
                                                                {order?.docketNo &&
                                                                    <a className={classes.trackOrders} href={order.trackURL} rel="noreferrer" target="_blank">Track your order</a>
                                                                }
                                                            </div>
                                                            <div className={Utils.getClasses(classes.chevronIconWrapper, isOpen[orderIdx] ? classes.chevronUp : classes.chevronDown)}> <FontAwesomeIcon icon={faChevronUp} className='cursor-pointer' onClick={() => toggleHandler(orderIdx)} /> </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    isOpen[orderIdx] && <tr className={classes.orderDetails}>
                                                        <td className={classes.itemsWrapper} colSpan='10'>
                                                            <span className={classes.itemSectionHeading}>Items in this order</span>
                                                            <table className={classes.itemTable}>
                                                                <thead>
                                                                    <tr className={classes.itemHeadingWrapper}>
                                                                        <th className={Utils.getClasses(classes.itemHeading, classes.productColumnHeading)}>Title And Author</th>
                                                                        <th className={classes.itemHeading}>Qty</th>
                                                                        <th className={classes.itemHeading}>Auth Discount</th>
                                                                        <th className={classes.itemHeading}>Qty Discount</th>
                                                                        <th className={classes.itemHeading}>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        items?.map((item, idx) => {
                                                                            let quantity;
                                                                            if ((item?.quantity) < 10) {
                                                                                quantity = "0" + (item.quantity)
                                                                            }
                                                                            else {
                                                                                quantity = item?.quantity || '01'
                                                                            }
                                                                            return <tr className={classes.productRow} key={idx}>
                                                                                <td className={classes.leftAlignedCell}>
                                                                                    <div className={classes.productDetailsCell}>
                                                                                        <img className={classes.bookImage} src={item.frontThumbURL} alt="" />
                                                                                        <div className={classes.authorWrapper}>
                                                                                            <h1 className={classes.bookName}>{item.title || "Untitled"}</h1>
                                                                                            <p className={classes.orderByDate}>By. {item.author}</p>
                                                                                        </div>
                                                                                    </div></td>
                                                                                <td className={classes.centeredCell}>{quantity || '01'}</td>
                                                                                <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {item?.authorDiscount ?? 0}</td>
                                                                                <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {item?.quantityDiscount ?? 0}</td>
                                                                                <td className={Utils.getClasses(classes.centeredCell, classes.borderTopRightBottomLeft)}>{Utils.getRupeeSymbol} {item.totalAmount}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyDialogue
                        animation={openBoxAnimation}
                        descriptionText="Looks like you have no past orders"
                        buttonText="Visit our bookstore"
                        onClick={goToStoreHandler}
                    />
                )}
            </div>

        </div>
    )
}

export default OrderHistory