import React, { useEffect, useRef, useState } from "react";
import classes from "./Blogs.module.css";
import BackButton from "../../components/Buttons/BackButton";
import MainFooter from "../../components/MainFooter/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import BuddingCard from "../../components/BuddingCard/BuddingCard";
import { fetchAllBlogEvents, fetchBlog, fetchBlogsCount } from "./BlogsActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Consts from "../../Consts";
import { Route, Switch } from "react-router-dom";
import HtmlViewer from "../../components/HtmlViewer/HtmlViewer";
import SocialMediaShareButtons from "../../components/SocialMediaShareButtons/SocialMediaShareButtons";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import WrapperContainer from "../../components/Container/WrapperContainer";
import nextLabelIcon from "../../assets/images/pagination-next-icon.svg";
import previousLabelIcon from "../../assets/images/pagination-previous-icon.svg";
import ReactPaginate from "react-paginate";
import HelmetProvider from "../../components/Helmet/Helmet";

const url = Consts.BASE_URL;

const BlogListing = ({ children }) => {
  const history = useHistory();
  const allBlogEvents = useSelector((state) => state.blogEvents.allBlogsList);

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.blogContainer}>
        {allBlogEvents.length != 0 ? (
          allBlogEvents.map((blog) => {
            return (
              <BuddingCard
                key={blog?.id}
                image={blog?.bannerImagePath}
                title={blog?.title}
                onClick={() => {
                  history.push(`${url}/blogs/${blog?.urlSlug}`);
                }}
                blogCard={true}
              />
            );
          })
        ) : (
          <div className={classes.message}>No Blogs.</div>
        )}
      </div>
      {children}
    </div>
  );
};

const ViewBlogs = ({ match: { url, params }, location: { pathname } }) => {
  const urlSlug = params.urlSlug;
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (urlSlug) {
      fetchBlog(urlSlug).then(setBlog);
    }
  }, [urlSlug]);

  const ref = useRef();
  const dimension = useContainerDimensions(ref);

  return (
    <>
      <div
        className={classes.shareIcon}
        style={{ height: dimension.height ? dimension.height * 0.8 : "100%" }}
      >
        <SocialMediaShareButtons
          blogUrlSlug={blog?.urlSlug}
          className={`${classes.iconBar}`}
        />
      </div>

      <div className={classes.blogContentContainer} ref={ref}>
        <h1>{blog?.title}</h1>
        <div className={classes.blogBannerImageContainer}>
          <img
            src={blog?.bannerImagePath}
            alt={blog?.bannerAlt || blog?.title || "blog image"}
          />
        </div>
        <div className={classes.blogContent}>
          <HtmlViewer src={blog?.contentPath} />
        </div>
        <div className={classes.shareIconContainer}>
          <h3>Share:</h3>
          <SocialMediaShareButtons
            blogUrlSlug={blog?.urlSlug}
            className={classes.shareIconsBottom}
          />
        </div>
      </div>
    </>
  );
};

const pageSize = 20;

const Blogs = ({ match: { url }, location: { pathname } }) => {
  const dispatch = useDispatch();
  const allBlogEvents = useSelector((state) => state.blogEvents.allBlogsList);
  const count = useSelector((state) => state.blogEvents.totalBlogsCount);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getBlogs = () => {
    fetchAllBlogEvents(page, pageSize, dispatch);
  }

  useEffect(() => {
    setTotalPages(Math.ceil(count / pageSize))
  }, [count])


  useEffect(() => {
    if (!allBlogEvents.length) {
      getBlogs();
      fetchBlogsCount(dispatch);
    }
  }, []);

  useEffect(() => {
    if (page) {
      getBlogs();
    }
  }, [page]);

  function handlePageChange(e) {
    setPage(e.nextSelectedPage + 1);
  }

  return (
    <>
      <HelmetProvider
        title={"Bookalooza Blog - Insights on Writing, Publishing, and Creativity"}
        description={
          "Stay updated with the latest blog posts from Bookalooza. Get tips, insights, and guidance on writing, publishing, and inspiring creativity in students."
        }
        keywords={"Bookalooza blog, writing tips for students, book publishing blog, student authors blog, creative writing tips"}
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Bookalooza",
          "url": "https://www.bookalooza.com/blogs",
          "logo": "https://www.bookalooza.com/logo.png",
          "description": "Stay updated with the latest blog posts from Bookalooza. Get tips, insights, and guidance on writing, publishing, and inspiring creativity in students.",
          "sameAs": [
            "https://www.facebook.com/bookalooza",
            "https://www.instagram.com/bookalooza/",
            "https://www.youtube.com/@Bookalooza",
            "https://www.linkedin.com/company/bookalooza/",
            "https://x.com/bookalooza"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service",
            "telephone": "+91-8799721408",
            "email": "support@bookalooza.com"
          }
        }}
      />
      <div className={classes.main}>
        <div className={classes.backButtonWrapper}>
          <BackButton />
        </div>
        <WrapperContainer className={pathname === '/blogs' && classes.blogListingContainer} title={pathname === '/blogs' && "Blogs"}>
          <Switch>
            <Route exact path={url}>
              <BlogListing>
                <ReactPaginate
                  key={"Pagination"}
                  nextLabel={
                    <>
                      <img
                        className={
                          page === totalPages + 1
                            ? classes.disabledButton
                            : ""
                        }
                        src={nextLabelIcon}
                        alt="next"
                      />
                    </>
                  }
                  onClick={handlePageChange}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={Math.ceil(count / pageSize)}
                  previousLabel={
                    <>
                      <img
                        className={page === 2 ? classes.disabledButton : ""}
                        src={previousLabelIcon}
                        alt="next"
                      />
                    </>
                  }
                  pageClassName={classes.pageItem}
                  pageLinkClassName={classes.pageLink}
                  disableInitialCallback
                  previousClassName={classes.pageItemPrevious}
                  previousLinkClassName={classes.pageLink}
                  nextClassName={classes.pageItem}
                  nextLinkClassName={classes.pageLink}
                  breakLabel="..."
                  breakClassName={classes.break}
                  breakLinkClassName="page-link"
                  containerClassName={classes.pagination}
                  activeClassName={classes.pageItemActive}
                />
              </BlogListing>
            </Route>
            <Route path={`${url}/:urlSlug`} component={ViewBlogs} />
          </Switch>
        </WrapperContainer>
      </div>
      <MainFooter />
    </>
  );
};

export default Blogs;
