import axios from "../../../axios";
import Utils from "../../Utils";

export function downloadPDF(url) {
    const a = document.createElement('a');
    a.href = url;
    let date = new Date(),
        year = date.getFullYear().toString().substring(2),
        month = Utils.stringPadder(date.getMonth() + 1, '0', 2),
        numDate = Utils.stringPadder(date.getDate(), '0', 2),
        hr = Utils.stringPadder(date.getHours(), '0', 2),
        min = Utils.stringPadder(date.getMinutes(), '0', 2),
        sec = Utils.stringPadder(date.getSeconds(), '0', 2);
    a.download = String.format('Output_{0}{1}{2}{3}{4}{5}.pdf', year, month, numDate, hr, min, sec);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function publishBookById(id) {
    return axios({
        url: `/designer/books/review/${id}`,
        method: "post"
    });
}

export function publishBook(bookCtx) {
    return publishBookById(bookCtx.getId());
}

export function deleteReview(id) {
    return axios({
        url: `/designer/reviews/${id}`,
        method: "delete"
    })
}