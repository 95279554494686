import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import classes from './Input.module.css'

export default function OTPInput({size = 5, onChange, onCommit, value = ''}) {
    const [otp, setOtp] = useState(value);
    return <OtpInput 
        containerStyle={{
            display: 'flex',
            justifyContent: 'space-around'
        }}
        value={otp}
        onChange={e => {
            setOtp(e);
            onChange?.(e);
            if (e.length === size) {
                onCommit?.(e);
            }
        }}
        numInputs={size}
        renderSeparator={<div />}
        renderInput={(props) => {
            let args = {...props};
            delete args.style;
            return <input {...args} type="number" className={classes.OTPInput}/>
        }}
    />
}