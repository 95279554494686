import React from "react";
import { useHistory } from "react-router";
import classes from "./SignIn.module.css";
import Consts from "../../Consts";
import SignInForm from "./SignInForm";
import HelmetProvider from "../Helmet/Helmet";

function SignIn(props) {
	const history = useHistory();

	function postSignInProcess() {
		localStorage.setItem("isLoggedIn", "true");
		history.push(history.location?.state?.from || Consts.BASE_URL || '/');

	}

	function createHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/sign-up`,
			state: { ...history.location?.state },
		});
	}

	function forgotPasswordHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/forgot-password`,
			state: { ...history.location?.state },
		});
	}

	return (
		<div className={classes.mainContainer}>
			<HelmetProvider
				title={"Sign In to Bookalooza - Start Writing and Publishing Today"}
				description={
					"Sign in to your Bookalooza account and start your journey of writing and publishing your book. Access your drafts, edits, and publishing tools easily."
				}
				keywords={"Bookalooza sign in, student writing platform login, publish books account, login for young authors"}
				schema={
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "Bookalooza",
						"url": "https://www.bookalooza.}/sign-in",
						"logo": "https://www.bookalooza.com/logo.png",
						"description": "Sign in to your Bookalooza account and start your journey of writing and publishing your book. Access your drafts, edits, and publishing tools easily.",
						"sameAs": [
							"https://www.facebook.com/bookalooza",
							"https://www.instagram.com/bookalooza/",
							"https://www.youtube.com/@Bookalooza",
							"https://www.linkedin.com/company/bookalooza/",
							"https://x.com/bookalooza"
						],
						"contactPoint": {
							"@type": "ContactPoint",
							"contactType": "Customer Service",
							"telephone": "+91-8799721408",
							"email": "support@bookalooza.com"
						}
					}}
			/>
			<SignInForm
				postSignInProcess={postSignInProcess}
				createHandler={createHandler}
				forgotPasswordHandler={forgotPasswordHandler}
				fetchedUserDetails={props.userDetails || {}}
			/>
		</div>
	);
}

export default SignIn;
