import React from "react";
import classes from "./BackButton.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function BackButton(props) {
    const history = useHistory()
    return (<div className={classes.main}>
        <button type="button" onClick={() => props.onBack || history.goBack()}>
            <div className={classes.btnWrapper}>
                <span>BACK</span>
            </div>
        </button>
    </div>);

}

export default BackButton;