import Consts from "../../../../../Consts";

const warpShapes = [];
for (let i = 0; i < 10; ++i) {
    warpShapes.push({
        value: i,
        src: `${Consts.BASE_URL}/resources/textshapesvg/text-shape-${i}.svg`
    });
}
export default function TextShapes({ value, onChange, style }) {
    return <div className="scroll-vertical" style={{...style, display: 'flex', flexWrap: "wrap", paddingTop: '5px', justifyContent: 'center', gap: '5px' }}>
        {
            warpShapes.map(s =>
                <div key={s.value} className={`image-item-thumb-wrap shapeItem${value === s.value ? " x-item-selected" : ''}`}
                    style={{
                        backgroundImage: `url(${s.src})`
                    }}
                    onClick={() => onChange?.(s.value)}
                />
            )
        }
    </div>;
}