import React, { useState, useEffect } from 'react';
import classes from "./CheckoutForm.module.css";
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import Utils from '../../Utils';
import DashboardInput from '../DashboardInput/DashboardInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


const StateList = ({ state, setState }) => {
  const [states, setStates] = useState([
    "Andhra Pradesh",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Maharashtra",
    "Karnataka",
    "Haryana",
    "Himachal Pradesh",
    "Arunachal Pradesh",
    "Gujarat",
    "Assam",
    "Manipur",
    "Jharkhand",
    "Kerala",
    "Punjab",
    "West Bengal",
    "Mizoram",
    "Delhi",
    "Tripura",
    "Rajasthan",
    "Nagaland",
    "Odisha",
    "Madhya Pradesh",
    "Tamil Nadu",
    "Sikkim",
    "Uttar Pradesh",
    "Meghalaya",
    "Telangana",
    "Uttarakhand",
    "Jammu & Kashmir",
    "Chandigarh",
    "Ladakh ",
    "Dadra and Nagar Haveli & Daman and Diu",
    "Lakshadweep",
    "Andaman and Nicobar Islands",
    "Puducherry",
  ]);

  useEffect(() => {
    const sortedStates = [...states].sort((a, b) => a.localeCompare(b));
    setStates(sortedStates);
  }, []);

  return (
    <select value={state} onChange={(e) => setState(e.target.value)}>
      <option value="">Select State</option>
      {states.map((stateName) => (
        <option key={stateName} value={stateName}>
          {stateName}
        </option>
      ))}
    </select>
  );
};

const CheckoutForm = ({ setShowCheckoutForm, handlePayment, userData }) => {
  const [state, setState] = useState('');
  const [customerName, setCustomerName] = useState(userData.fullName);
  const [customerPhone, setCustomerPhone] = useState(userData.phone);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [alternatePhone, setAlternatePhone] = useState('');
  const [pincode, setPincode] = useState('');
  const [isError, setIsError] = useState(false);
  const [formMessage, setFormMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission logic here
  };

  const handlePayNow = () => {
    if (state && customerName && customerPhone && city && address && landmark && pincode) {
      if (!Utils.validatePhoneNumber(customerPhone)) {
        setIsError(true);
        setFormMessage('Please enter valid phone');
      } else {
        if (alternatePhone && !Utils.validatePhoneNumber(alternatePhone)) {
          setIsError(true);
          setFormMessage('Please enter valid alternate phone');
        } else {
          handlePayment(state, customerName, customerPhone, city, address, pincode, addressLine2, landmark, alternatePhone); // Call the handlePayment function if all fields are filled
        }
      }
    } else {
      // Display an error message or perform any other action for incomplete fields
      setIsError(true);
      setFormMessage('Please fill in all fields');
    }
  };

  return (
    <div className={classes.checkoutForm}>
      <h1 className={classes.checkOutHeading}>Checkout
        <span className={classes.closeModalIcon} onClick={() => setShowCheckoutForm(false)}><FontAwesomeIcon icon={faXmark} /></span>
      </h1>
      <div className={classes.formTitle}>Enter delivery address.</div>
      <div className={classes.formGroup}>
        <label className={classes.stateListLabel} htmlFor="state">State<span className={classes.required}>*</span>:</label>
        <StateList state={state} setState={setState} />
      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Name<span className={classes.required}>*</span>:</>}
          type="text"
          id="name"
          placeholder={customerName || 'Enter your name'}
          autoFocus={true}
          onChange={(e) => setCustomerName(e.target.value)}
          value={customerName}
        />
      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Mobile No. 1<span className={classes.required}>*</span>:</>}
          type="number"
          id="phone"
          placeholder={customerPhone || 'Enter your mobile no.'}
          autoFocus={true}
          onChange={(e) => setCustomerPhone(e.target.value)}
          value={customerPhone}
        />

      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Alternate Mobile No:</>}
          type="number"
          id="alternatePhone"
          placeholder={alternatePhone || 'Enter your alternate mobile no.'}
          autoFocus={true}
          onChange={(e) => setAlternatePhone(e.target.value)}
          value={alternatePhone}
        />
      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Address Line 1<span className={classes.required}>*</span>:</>}
          type="text"
          id="address"
          placeholder={address || 'Enter your address line 1'}
          autoFocus={true}
          onChange={(e) => setAddress(e.target.value)}
          value={address}
        />
      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Address Line 2:</>}
          type="text"
          id="addressLine2"
          placeholder={address || 'Enter your address line 2'}
          autoFocus={true}
          onChange={(e) => setAddressLine2(e.target.value)}
          value={addressLine2}
        />
      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Landmark<span className={classes.required}>*</span>:</>}
          type="text"
          id="landmark"
          placeholder={landmark || 'Enter your landmark'}
          autoFocus={true}
          onChange={(e) => setLandmark(e.target.value)}
          value={landmark}
        />

      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>City<span className={classes.required}>*</span>:</>}
          type="text"
          id="city"
          placeholder={city || 'Enter your city'}
          autoFocus={true}
          onChange={(e) => setCity(e.target.value)}
          value={city}
        />

      </div>
      <div className={classes.formGroup}>
        <DashboardInput
          label={<>Pincode<span className={classes.required}>*</span>:</>}
          type="number"
          id="pincode"
          placeholder={pincode || 'Enter your pincode'}
          autoFocus={true}
          onChange={(e) => setPincode(e.target.value)}
          value={pincode}
        />
      </div>

      <span className={`${classes.formMessage} ${isError ? classes.error : classes.success}`}>{formMessage}</span>
      <div className={classes.footer}>
        <SecondaryButton style={{ marginRight: "1rem" }} onClick={() => setShowCheckoutForm(false)}>Close</SecondaryButton>
        <PrimaryButton onClick={handlePayNow}>Pay now</PrimaryButton>
      </div>
    </div>
  );
};

export default CheckoutForm;