import React, { useEffect, useState } from "react";
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import StyledTextField from "../../../components/StyledTextField/StyledTextField";
import { ColorPicker, FilePicker } from "../Picker.js";
import ColorGrid from "./ColorGrid.js";
import Select from 'react-select';
import Utils from "../../../Utils";

export function ColorWrapper(opt) {
	for (let p in opt) {
		if (opt[p] !== undefined)
			this[p] = opt[p];
	}
}

ColorWrapper.prototype = {
	colorType: 'normal',
	type: 'fill',
	getNormalColor: function (color) {
		if (!this.fabricColor) {
			this.fabricColor = new window.fabric.Color(color);
		}
		let fabricColor = this.fabricColor;
		fabricColor._tryParsingColor(color);
		return '#' + fabricColor.toHexa();
	},
	getColorStopsColor: function (color) {
		if (!color || !color.colorStops)
			return '';
		let init = '';
		if (color.type === 'linear' && color.coords) {
			let coords = color.coords;
			init = Math.atan((coords.y2 - coords.y1) / (coords.x2 - coords.x1)) + Math.PI / 2;
			init += "rad";
		}
		return color.colorStops.slice().reverse().reduce((p, n) => (p ? (p + ", " + n.color) : n.color), init);
	},
	getValue: function () {
		let color = this.getColor();
		switch (this.colorType) {
			case 'normal':
				return this.getNormalColor(color);
			case 'pattern':
				return color.getColor();
			case 'radial':
				return `radial-gradient(${this.getColorStopsColor(color)})`;
			case 'linear':
				return `linear-gradient(${this.getColorStopsColor(color)})`;
			default:
				return 'gray';
		}
	},
	getColor: function () {
		return this.color;
	},
	getType: function () {
		switch (this.type) {
			case 'extraStrokes':
				return `extraStrokes_${this.index}`;
			default:
				return this.type;
		}
	},
	getBaseType: function () {
		switch (this.type) {
			case 'extraStrokes':
				return 'stroke';
			default:
				return this.type;
		}
	},
	setColor: function (c) {
		let obj = this.target,
			type = this.type,
			val = c.getColor();
		if (type === 'extraStrokes') {
			obj[type][this.index].stroke = val;
		} else if (this.colorType === 'pattern') {
			obj[type].setColor(val);
		} else {
			obj[type] = val;
		}
		obj.set('dirty', true);
		this.colorType = c.colorType;
		this.color = val;
		this.onColorChange && this.onColorChange(val);
	},
	getTooltip: function () {
		return this.name;
	},
	toObject: function () {
		let color = this.getColor();
		switch (this.colorType) {
			case 'pattern':
			case 'normal':
				return {
					colorValue: this.getColor(),
					name: this.name
				};
			case 'radial':
			case 'linear':
				return {
					name: this.name,
					colorType: color.type,
					coords: color.coords,
					colorStops: color.colorStops
				};
			default:
				return 'gray';
		}
	}
};

function getRGBColorValue() {
	if (this._colorVal)
		return this._colorVal;
	let value = this.value;
	return 'rgb(' + value.red + ',' + value.green + ',' + value.blue + ')';
}

function getRGBAColorValue() {
	if (this._colorVal)
		return this._colorVal;
	let value = this.value;
	return 'rgba(' + value.red + ',' + value.green + ',' + value.blue + ',' + value.alpha + ')';
}

function getDefaultColorValue() {
	return this.colorValue || '#000';
}

function getWrappedColor(val) {
	let color;
	if (!val.colorType || val.colorType === 'normal') {
		let method;
		switch (val.colorSpace) {
			case 'RGB':
				method = getRGBColorValue;
				break;
			case 'RGBA':
				method = getRGBAColorValue;
				break;
			default:
				method = getDefaultColorValue;
				break;
		}
		color = new ColorWrapper({
			color: method.call(val),
			name: val.name
		});
	} else {
		let fabric = window.fabric;
		color = new ColorWrapper({
			color: new fabric.Gradient({
				type: val.colorType,
				colorStops: val.colorStops,
				coords: val.coords,
				gradientUnits: 'percentage'
			}),
			colorType: val.colorType,
			name: val.name
		});
	}
	return color;
}

export function getColors(obj) {
	if (!obj || !obj.colors)
		return [];
	return obj.colors.map(getWrappedColor);
}

function ColorStop({ label, onChange, color }) {
	const [val, setValue] = useState(color);
	return <tr>
		<td>{label}</td>
		<td style={{ display: 'flex', alignItems: 'center', margin: '0.5rem 0' }}>
			<ColorPicker color={val}
				onChange={e => {
					let val = e.target.value;
					setValue(val);
					onChange(val);
				}}
			/>
		</td>
	</tr>;
}

const optionsForGradient = [{ value: 'horizontal', label: 'Horizontal' }, { value: 'vertical', label: 'Vertical' }];
const opts = [{ value: 'linear', label: 'Linear' }, { value: 'radial', label: 'Radial' }];

function getAngle(direction) {
	if (direction === 'horizontal') {
		return 1.5708;
	} else {
		return 3.14;
	}
}

function getCoords(direction) {
	if (direction === 'horizontal') {
		return { x1: 0, y1: 0, x2: 1, y2: 0 };
	} else {
		return { x1: 0, y1: 0, x2: 0, y2: 1 };
	}
}

function ColorForm(props) {
	const { edit, color, type } = props;
	let colorType = type;
	if (edit && color) {
		colorType = color.colorType;
	}
	let Component;
	switch (colorType) {
		case 'normal':
			Component = NormalColorForm;
			break;
		default:
			Component = GradientForm;
			break;
	}
	return <Component {...props} />;
}


function NormalColorForm({ onClose, onSave, color = { color: '#000', name: 'New Color', type: 'normal' }, edit }) {
	const [colorItem, setColorItem] = useState({ ...color });
	return <Dialog
		open={true} aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<DialogTitle>
			<span>{edit ? 'Edit Color' : 'Add Color'}</span>
		</DialogTitle>
		<DialogContent>
			<div style={{ marginBottom: '1rem' }}>
				<div style={{ fontSize: '0.75rem', marginBottom: '0.5rem', color: 'rgb(205, 206, 208)' }}>Click color to edit</div>
				<ColorPicker color={colorItem.color}
					style={{ verticalAlign: 'bottom' }}
					onChange={e => {
						let val = e.target.value;
						setColorItem(v => ({
							...v,
							name: v.name?.match(/(#.*)|(New Color)/i) ? val : v.name,
							color: val,
							errorText: ""
						}));
					}}
				/>
			</div>
			<StyledTextField error={colorItem.errorText ? true : false}
				helperText={colorItem.errorText}
				style={{ width: '25rem' }} ref={(ref) => {
					if (ref) ref.querySelector('input').value = colorItem.name;
				}} label="Name" onChange={(el) => {
					let newColorName = el.target.value;
					let newErrorText;
					if (!newColorName) {
						newErrorText = "The color name cannot be empty";
					}
					setColorItem(v => ({
						...v,
						name: newColorName,
						errorText: newErrorText
					}));
				}} />
		</DialogContent>
		<DialogActions>
			<PrimaryButton className="text-btn cornered-btn dialog-btn-enabled" onClick={e => {
				onSave(new ColorWrapper(colorItem));
				onClose(e);
			}}>OK</PrimaryButton>
			<PrimaryButton className="text-btn cornered-btn dialog-btn-enabled" onClick={onClose}>Cancel</PrimaryButton>
		</DialogActions>
	</Dialog>;
}
const emptyColorNameErrorMsg = "The color name cannot be empty";
const defaultGradientColor = {
	name: "Gradient",
	colorType: "linear",
	coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
	colorStops: [
		{ offset: 1, color: "#000", opacity: 1 },
		{ offset: 0, color: "#000", opacity: 1 }
	]
};

function GradientForm({ onClose, onSave, color = getWrappedColor(defaultGradientColor), edit }) {
	const [name, setName] = useState(color.name);
	const [type, setType] = useState(color.colorType);
	const colorStops = color.color.colorStops;
	const [color1, setColor1] = useState(colorStops[1].color);
	const [color2, setColor2] = useState(colorStops[0].color);
	const [gradientDirection, setGradientDirection] = useState('horizontal');

	return <Dialog
		open={true} aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<DialogTitle>
			<span>{edit ? 'Edit Gradient Color' : 'Add Gradient Color'}</span>
		</DialogTitle>
		<DialogContent>
			<StyledTextField error={!Boolean(name)}
				helperText={name ? '' : emptyColorNameErrorMsg}
				style={{ width: '25rem' }} label="Name"
				defaultValue={name}
				onChange={el => setName(el.target.value)}
			/>
			<div style={{ marginTop: 20, height: '18rem' }}>
				<table style={{ color: 'var(--text-light-color)' }}>
					<tbody>
						<tr>
							<td style={{ width: '7rem' }}>Type</td>
							<td style={{ width: '10rem' }}>
								<Select value={opts.find(i => i.value === type)} styles={{
									option: styles => ({
										...styles,
										color: "black",
										backgroundColor: "rgb(230, 231, 232)"
									}),
								}}
									onChange={e => setType(e.value)} options={opts}
								/>
							</td>
						</tr>
						<ColorStop color={color1} label="Colorstop 1" onChange={v => setColor1(v)} />
						<ColorStop color={color2} label="Colorstop 2" onChange={v => setColor2(v)} />
						{type === 'linear' &&
							<tr>
								<td style={{ width: '7rem' }}>Direction</td>
								<td style={{ width: '10rem' }}>
									<Select value={optionsForGradient.find(i => i.value === gradientDirection)}
										onChange={e => setGradientDirection(e.value)} options={optionsForGradient} styles={{
											option: styles => ({
												...styles,
												color: "black",
												backgroundColor: "rgb(230, 231, 232)"
											}),
										}}
									/>
								</td>
							</tr>}
					</tbody>
				</table>
				<div>
					<span className="color-item-thumb-wrap">
						<span draggable="false" style={{
							background: `${type}-gradient(${type === 'linear' ? `${getAngle(gradientDirection)}rad, ` : ''}${color1}, ${color2})`
						}} />
					</span>
				</div>
			</div>
		</DialogContent>
		<DialogActions>
			<PrimaryButton disabled={!Boolean(name)}
				className={`text-btn cornered-btn ${name ? 'dialog-btn-enabled' : 'dialog-btn-disabled'}`}
				onClick={e => {
					onSave?.(
						new ColorWrapper({
							color: new window.fabric.Gradient({
								type: type,
								colorStops: [
									{ offset: 1, color: color2, opacity: 1 },
									{ offset: 0, color: color1, opacity: 1 }
								],
								coords: type === 'linear' ?
									getCoords(gradientDirection) :
									{ x1: 0.5, y1: 0.5, x2: 0.5, y2: 0.5, r1: 0, r2: 0.5 },
								gradientUnits: 'percentage',
								gradientDirection
							}),
							colorType: type,
							name: name
						})
					);
					onClose?.(e);
				}} autoFocus>OK</PrimaryButton>
		</DialogActions>
	</Dialog>;
}

export default function ManageColors({ open, data, onClose, onSave, disableGradient }) {
	const [colorDialog, setColorDialog] = useState({ open: false });
	const [items, setItems] = useState([]);
	const [selectIndex, setSelectIndex] = useState([]);
	useEffect(() => {
		setItems(getColors(data));
	}, [open, data]);

	return (
		<>
			<Dialog
				open={open} aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle>
					<span>Manage Colors</span>
				</DialogTitle>
				<DialogContent>
					<div style={{ padding: 10, paddingTop: 0, textAlign: 'right', color: '#c0c0c0' }}>
						{
							!disableGradient &&
							<span className="fa fa-plus" style={{
								background: 'linear-gradient(#f00, #0f0, #00f)',
								position: 'relative',
								textAlign: 'center'
							}} onClick={e => setColorDialog({ open: true, type: 'gradiant' })} />
						}
						<span title="Add Color" className="fa fa-plus clipart-category-btn" style={{ position: 'relative', textAlign: 'center', marginLeft: '5px' }}
							onClick={e => {
								setColorDialog({ open: true, type: 'normal' });
							}}
						/>
						<span title="Edit Color" className={Utils.getClasses("fa fa-pen clipart-category-btn", selectIndex.length !== 1 && 'x-item-disabled')} style={{ position: 'relative', textAlign: 'center' }}
							onClick={e => {
								setColorDialog({ open: true, edit: true });
							}}
						/>
						<span title="Remove Color" style={{ marginLeft: '5px', textAlign: 'center' }} className={Utils.getClasses('fa fa-trash clipart-category-btn', !selectIndex.length && 'x-item-disabled')} onClick={() => {
							setItems(items.filter((val, index) => !selectIndex.includes(index)));
							setSelectIndex([]);
						}} />
						<FilePicker title="Upload Colors" style={{ marginLeft: '5px' }} accept="application/json" className="fa fa-upload clipart-category-btn" onChange={files => {
							Utils.readAsText(files[0]).then(text => {
								let json = JSON.parse(text);
								if (json)
									setItems(items.concat(getColors(json)));
							});
						}} />
					</div>
					<div style={{ height: '16rem', width: '26rem', background: 'var(--panel-semi-light-background)' }} className="scroll-vertical">
						<ColorGrid className="manage-colors" multiSelect={true}
							colorlist={items} itemDraggable={false}
							selectIndex={selectIndex}
							onClick={(c, color, selection) => {
								setSelectIndex(selection);
							}}
						/>
					</div>
				</DialogContent>
				<DialogActions >
					<SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
					<PrimaryButton onClick={() =>
						onSave && onSave(items.map(i => i.toObject()))
					} positive>Save</PrimaryButton>
				</DialogActions>
			</Dialog>
			{
				colorDialog.open &&
				<ColorForm setItems={setItems} onClose={e => setColorDialog({ open: false })}
					type={colorDialog.type}
					color={colorDialog.edit ? items[selectIndex[0] || 0] : undefined}
					edit={colorDialog.edit}
					onSave={item => {
						item.name = item.name.trim();
						if (colorDialog.edit) {
							setItems(i => {
								let arr = i.slice();
								arr.splice(selectIndex[0], 1, item);
								return arr;
							});
						} else {
							setItems(items.concat(item));
						}
					}}
				/>
			}
		</>
	);
}