import { createSlice } from "@reduxjs/toolkit";

const aboutAuthorSlice = createSlice({
    name: "aboutAuthor",
    initialState: { allAboutAuthorTemplates:[] },
    reducers: {
        fetchAboutAuthorTemplates: (state, action) => {
            state.allAboutAuthorTemplates = action.payload.templates;
        }
    },
});

export const aboutAuthorAction = aboutAuthorSlice.actions;
export default aboutAuthorSlice.reducer;