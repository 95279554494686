import React from 'react'
import classes from "./Loading.module.css";

function Loader(props) {
  return (
    <>
        <div className={classes.backdrop}> </div>
            <div className={classes.main} >
                <span>{props.text} </span>
                <div className={classes.loader}></div>
            </div>
    </>
  )
}

export default Loader