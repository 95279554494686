import React from 'react';
import classes from './NotificationCard.module.css';
import { Close } from '@material-ui/icons';
import Utils from '../../Utils';

const color = {
  ORDERED: '#FFFEF1',
  REJECTED: '#FFF6F6',
  APPROVED: '#EDFFF8',
  LIKED: '#FBF5FF'
}

function NotificationCard({ notificationId, icon, title, description, status, timeSince, deleteNotificationHandler }) {

  return (
    <div className={classes.main} style={{ background: color[status] }} >
      <div className={Utils.getClasses(classes.closeButtonWrapper, 'cursorPointer')}>
        <Close onClick={() => deleteNotificationHandler(notificationId)} style={{ width: '1rem', height: '1rem', color: 'black' }} />
      </div>
      <div className={classes.notificationCardWrapper}>
        <div><img src={icon} alt="icon" /></div>
        <div className={classes.NotificationWrapper}>
          <div className={classes.NotificationContentWrapper}>
            <h1 className={classes.notificationTitle}>{title}</h1>
            <p className={classes.notificationDescription}>{description}</p>
          </div>
          <p className={classes.timeZone}>{timeSince}.</p>
        </div>
      </div>
    </div>
  )
}

export default NotificationCard