import React from 'react'
import classes from './FeatureCard.module.css'
import Lottie from 'react-lottie-player'
import thumbsUpAnimation from '../../assets/animations/thumbsup.json'

function FeatureCard({content}) {
  return <div className={classes.main}>
        <span>{content}</span>
        <div className={classes.thumbsUp}><Lottie animationData={thumbsUpAnimation} play style={{ width: "5rem", height: "5rem", margin: "1rem" }} /></div>
    </div>;
}

export default FeatureCard;