import React, { forwardRef, useEffect, useRef, useState } from "react";
import { updateBook } from "../Books/BooksActions.js";
import { useFabricCanvasHandler } from "./FabricCanvas.js";
import { useHistory } from "react-router";
import ModalDialog from "../../components/Dialog/Dialog";
import classes from "./Editor.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton.js";
import SecondaryButton from "../../components/Buttons/SecondaryButton.js";
import { useImperativeHandle } from "react";
import { useBookContextHandler, usePageContext } from "./BookContext.js";
import { Add } from "@material-ui/icons";
import Utils from "../../Utils.js";
import { Slider } from "../../components/Sliders/Sliders.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPenNib, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { useMobileScreen } from "../../custom-hooks/MobileScreen.js";

const action = {
  saveDesign: function (book, history, callback, cb) {
    window.loading(true);
    return updateBook(book, history)
      .then((e) => {
        book.fire("book:saved");
        callback?.(
          "bookSaved",
          "Book saved successfully.",
          "var(--text-light-color)",
          cb
        );
        return e;
      })
      .catch((e) => {
        callback?.("bookSaved", "Failed to save book.", "red", cb);
      })
      .finally((e) => window.loading(false));
  },
};

const Footer = forwardRef(function ({ setConfirmLoginDialogue, toggleFullScreen }, ref) {
  const isMobileScreen = useMobileScreen();
  const page = usePageContext();
  const [backDialog, setBackDialog] = useState(false);
  const [targetLocation, setTargetLocation] = useState('');
  const [showNotificaion, setShowNotificaion] = useState({ place: '', content: '', color: '' });
  const clearMessage = useRef();
  const history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [canvas] = useFabricCanvasHandler({
    'text:limitReached': function (e) {
      showNotifications('bookSaved', 'Page limit reached.', 'red')
    }
  });
  const [bookCtx] = useBookContextHandler({
    'book:dirty-changed': function (e) {
      setCanSave(e.target);
    },
    'page:unmounted': function ({ target }) {
      save();
    }
  }, [canvas, page]);

  const [zoom, setZoom] = useState(100);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (canSave) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you wish to close?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    const unblock = history.block((location, action) => {
      const isUserTriggeredClose = window.isUserTriggeredClose;
      setTargetLocation({
        path: location.pathname,
        action: action,
      });
      delete window.isUserTriggeredClose;
      if (!isUserTriggeredClose && canSave) {
        setBackDialog(true);
        return false;
      }
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblock();
    };
  }, [canSave]);

  function showNotifications(place, message, color, callback, time = 3000) {
    clearTimeout(clearMessage.current);
    setShowNotificaion({ place: place, content: message, color: color });
    clearMessage.current = setTimeout(() => {
      setShowNotificaion({ place: "", content: "", color: "" });
      callback?.();
    }, time);
  }

  function moveBack() {
    window.isUserTriggeredClose = true;
    switch (targetLocation.action?.toLowerCase()) {
      case "push":
        history.push(targetLocation.path);
        break;
      case "replace":
        history.replace(targetLocation.path);
        break;
      case "pop":
      default:
        if (history.length > 1) {
          history.goBack();
        } else {
          history.replace("");
        }
    }
    clearTimeout(clearMessage.current);
  }

  const onBookSave = async (cb) => {
    if (!Utils.isUserLoggedIn()) {
      await new Promise((accept, reject) => {
        setConfirmLoginDialogue({ accept, reject });
      });
    }
    page.updatePage(canvas);
    if (bookCtx?.isDirty() || !bookCtx.getId()) {
      return await action.saveDesign(
        bookCtx,
        history,
        showNotifications,
        typeof cb === "function" ? cb : null
      );
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onBookSave,
        onBookClose: () => {
          if (bookCtx?.isDirty()) {
            setBackDialog(true);
          } else {
            moveBack();
          }
        },
      };
    },
    [bookCtx, page, canvas]
  );

  function save(cb) {
    onBookSave(cb).catch((e) => { });
  }

  useEffect(() => {
    if (canvas) {
      const canvasWrapper = document.querySelector(".canvas-container");
      const mainCanvasWrapper = document.querySelector(".mainCanvasWrapper");
      if (canvasWrapper) {
        const width = canvasWrapper.offsetWidth;
        const height = canvasWrapper.offsetHeight;
        const scale = zoom / 100;
        const translateX = width * (scale - 1) / scale / 2;
        const translateY = height * (scale - 1) / scale / 2
        canvasWrapper.style.transform = `scale(${scale})translate(${translateX}px, ${translateY}px)`;

        if (mainCanvasWrapper) {
          if (scale === 1) {
            mainCanvasWrapper.style.overflow = "hidden";
          } else {
            mainCanvasWrapper.style.overflow = "auto";
            const wrapperWidth = mainCanvasWrapper.offsetWidth;
            const wrapperHeight = mainCanvasWrapper.offsetHeight;
            const scrollLeft = (canvasWrapper.scrollWidth * scale - wrapperWidth) / 2;
            const scrollTop = (canvasWrapper.scrollHeight * scale - wrapperHeight) / 2;
            mainCanvasWrapper.scrollLeft = scrollLeft;
            mainCanvasWrapper.scrollTop = scrollTop;
          }
        }
      }
    }
  }, [canvas, zoom]);

  return (
    <>
      <div className={classes.footer}>
        <div className={classes.viewToolbarButton}>
          <PrimaryButton
            className={classes.footerButton}
            onClick={(e) => window.showLeftSidebar?.(true)}
          >
            <FontAwesomeIcon icon={faPenNib} />
          </PrimaryButton>
        </div>
        <div
          className={classes.addNewButtonWrapper}
        >
          {page?.isRegularPage() && (
            <SecondaryButton className={classes.footerButton} onClick={() => bookCtx.appendPage()}>
              <Add style={{ color: "#e8b03e" }} /> {!isMobileScreen && "Add page"}
            </SecondaryButton>
          )}
        </div>
        <div className="canvas-scale-slider">
          <Slider
            min={100}
            max={300}
            value={zoom}
            onChange={(e, val) => setZoom(val)}
          />
        </div>
        <div>
          <SecondaryButton
            className={Utils.getClasses(classes.footerButton, classes.fullScreenBtn)}
            onClick={toggleFullScreen}
          ><FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} /></SecondaryButton>
        </div>
        {showNotificaion.place === "bookSaved" && (
          <div
            className={classes.bookSaved}
            style={{ color: showNotificaion.color }}
          >
            {showNotificaion.content}
          </div>
        )}
        <PrimaryButton
          className={classes.footerButton}
          disabled={!canSave}
          onClick={save}
        >
          <FontAwesomeIcon icon={faFloppyDisk} /> {!isMobileScreen && " Save"}
        </PrimaryButton>
      </div>
      {backDialog && (
        <ModalDialog
          title="Save Your Work"
          content={
            <div style={{ color: "#101010" }}>
              We care about your progress. Click on the Save button to ensure
              all your changes are saved.
            </div>
          }
          onClose={(e) => setBackDialog(false)}
          footer={
            <>
              {showNotificaion.place === "bookSaved" && (
                <div style={{ color: showNotificaion.color, flex: 1 }}>
                  {showNotificaion.content}
                </div>
              )}
              <SecondaryButton onClick={() => moveBack()}>
                Close
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  save(moveBack);
                }}
                autoFocus
              >
                Save
              </PrimaryButton>
            </>
          }
          scrollable={false}
        />
      )}
    </>
  );
});

export default Footer;
