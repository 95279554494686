import React from 'react'
import classes from './SignIn.module.css'
import MessageDialog from '../MessageDialog/MessageDialog';
import Card from '../Card/Card';
import Input from '../Input/Input';
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";
import LinkButton from '../Buttons/LinkButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import Consts from "../../Consts";
import Password from '../Password/Password';
import { signInUser } from '../../modules/Users/UsersActions';
import { useState } from 'react';


const SignInForm = ({ postSignInProcess, createHandler, forgotPasswordHandler, fetchedUserDetails }) => {

    const [userDetails, setUserDetails] = useState(fetchedUserDetails || {});
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });

    function signInHandler() {
        let data = { ...userDetails };
        if (userDetails.password) {
            signInUser(data).then((response) => {
                if (response && response.data && response.data.success) {
                    postSignInProcess();
                } else if (response.data?.errorCode) {
                    setDialogContent(() => {
                        return {
                            title: "Failed",
                            message: "Invalid email or password.",
                        };
                    });
                    setShowMessageDialog(true);
                }
            });
            setIsPasswordValid(true);
        } else {
            setIsPasswordValid(false);
        }
    }

    return (
        <div className={classes.mainBox}>
            {showMessageDialog && (
                <MessageDialog
                    {...dialogContent}
                    onCancel={() => setShowMessageDialog(false)}
                    isCancel
                    closeText="Close"
                    width={Consts.MESSAAGE_DIALOG_WIDTH}
                />
            )}
            <Card backColor="white" borderRadius='24px'>
                <div className={classes.main}>
                    <div className={classes.logoImage}>
                        <img src={logoImage} className={classes.bulbImage} alt="Logo" />
                        <img src={logoWithTagline} className={classes.tagline} alt="Tag line" />
                    </div>
                    <div className={classes.heading}>
                        <h2>Login</h2>
                        <p>Hey ! enter your details to login in to your account</p>
                    </div>
                    <div className={classes.container}>
                        <Input type="email"
                            id="userId"
                            placeholder='Enter Email or UserID'
                            autoFocus={true}
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        userId: e.target.value.trim(),
                                    };
                                })
                            }
                            value={userDetails.userId || ""}
                        />
                        {!isEmailValid && (
                            <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
                                Email is not valid{" "}
                            </p>
                        )}
                        <Password
                            id="password"
                            placeholder="Enter Password"
                            togglePasswordVisibility={true}
                            height="2.25rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        password: e.target.value,
                                    };
                                })
                            }
                            autoComplete="off"
                            onCommit={signInHandler}
                        />
                        {!isPasswordValid && (
                            <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
                                Please enter the password{" "}
                            </p>
                        )}
                        <div className={classes.forgotPassword}>
                            <LinkButton
                                onClick={forgotPasswordHandler}
                                label="Forgot Password?"
                            />
                        </div>
                        <div className={classes.actions}>
                            <PrimaryButton onClick={signInHandler} style={{ width: "98%", height: "2.5rem", fontSize: '0.875rem' }}>
                                Sign in
                            </PrimaryButton>
                        </div>
                        <div className={classes.signup}>
                            <span>New user ? </span>
                            <LinkButton onClick={createHandler} label="Signup" />
                        </div>
                        <div className={classes.schoolSignup}>
                            <span>Are you a Principal or a School Coordinator? </span>
                            <LinkButton onClick={e => {
                                window.location.href = '/school-ui/login';
                            }} label="Click here" />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SignInForm