import React from 'react';
import classes from './EmptyDialogue.module.css';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Lottie from 'react-lottie-player';

function EmptyDialogue({animation, descriptionText, buttonText, lottieWidth, lottieHeight, onClick, disabled}) {
    let width = lottieWidth || "10.5rem";
    let height= lottieHeight || "10.5rem";
    return (
        <div className={classes.emptyOrderContainer}>
            <div>
            <Lottie loop animationData={animation} play style={{ width: width, height: height }} />
            </div>
            <div className={classes.descriptionTextAndButtonWrapper}>
                <p className={classes.descriptionText}>{descriptionText}</p>
                { !disabled && (<PrimaryButton onClick={onClick} className={classes.action} >{buttonText}</PrimaryButton>)}
            </div>
        </div>
    )
}

export default EmptyDialogue;