import { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./AIHelp.module.css";
import Container from "../ToolPanels/components/Container";
import Storyline from "./Storyline/Storyline";
import Quotes from "./Quotes";
import Names from "./Name";
import Wordlist from "./Wordlist";
import closeIcon from "../../../assets/back-arrow-icon.svg";
import Character from "./Character";
import storylineIcon from "../../../assets/ai_tool_storyline.svg";
import wordlistIcon from "../../../assets/ai_tool_word_list.svg";
import namesIcon from "../../../assets/ai_tool_names.svg";
import quotesIcon from "../../../assets/ai_tool_quotes.svg";
import charactersIcon from "../../../assets/ai_tool_characters.svg";

const rootSection = {
  key: "aiHelp",
  value: "AI Help",
};

const sectionList = [
  {
    key: "storyline",
    value: "Storyline",
    icon: storylineIcon,
    bgColor: "#FFE7CE",
    bgIconColor: "rgba(242, 110, 20, 0.5)",
    component: Storyline,
  },
  {
    key: "wordlist",
    value: "Wordlist",
    icon: wordlistIcon,
    bgColor: "#F2E0FF",
    bgIconColor: "#D19EF7",
    component: Wordlist,
  },
  {
    key: "names",
    value: "Names",
    icon: namesIcon,
    bgColor: "#E5EBFF",
    bgIconColor: "rgb(67, 31, 178, 0.5)",
    component: Names,
  },
  {
    key: "quotes",
    value: "Quotes",
    icon: quotesIcon,
    bgColor: "#FFF4CA",
    bgIconColor: "#FEBC5B",
    component: Quotes,
  },
  {
    key: "characters",
    value: "Characters",
    icon: charactersIcon,
    bgColor: "#DCF3FF",
    bgIconColor: "rgba(9, 199, 241, 0.2)",
    component: Character,
  },
];

export default function AIHelp({ visible }) {
  const user = useSelector((state) => state.user.user);
  const [sections, setSections] = useState([rootSection]);
  const section = sections[sections.length - 1];
  const Component = section.component;
  function setSection(sec) {
    setSections((s) => [...s, sec]);
  }
  return (
    <Container style={{ height: 'calc(100% - 6rem)' }}
      title={
        <div className={classes.titleContainer}>
          {section.key !== rootSection.key && (
            <img
              className={classes.closeIcon}
              onClick={(e) => {
                if (sections.length < 2) {
                  return;
                }
                const val = [...sections];
                val.pop();
                setSections(val);
              }}
              src={closeIcon}
              alt=""
            />
          )}
          <div>{section.value}</div>
        </div>
      }
      visible={visible}
    >
      {section.key === rootSection.key ? (
        <div className={classes.section}>
          <div>
            <p className={classes.user}>Hi {user?.fullName || "-"}!</p>
            <p className={classes.aiHelpQuestion}>
              How can I help you in crafting your story ?
            </p>
            <div className={classes.subSectionLabel}>
              Select a category you need help with
            </div>
            <div className={classes.subSectionContainer}>
              {sectionList.map((sec) => (
                <div
                  key={sec.key}
                  className={classes.subSection}
                  style={{ background: sec.bgColor }}
                  onClick={(e) => setSection(sec)}
                >
                  <div
                    className={classes.subSectionLogo}
                    style={{ background: sec.bgIconColor }}
                  >
                    <img src={sec.icon} alt="icon-section" />
                  </div>
                  <p className={classes.subSectionTitle}>{sec.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div style={{ margin: 5 }} />
      {Component && <Component setSection={setSection} {...section?.props} />}
    </Container>
  );
}
