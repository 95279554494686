import axios from 'axios';
import Consts from './App/Consts';

axios.interceptors.request.use(function (request) {
    window.loading?.(true);
    return request;
});

axios.interceptors.response.use(function (response) {
    window.loading?.(false);
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        window.location = `${Consts.BASE_URL}/sign-in`;
        localStorage.removeItem("isLoggedIn");
    }
    window.loading?.(false);
    window.error?.(true);
    return Promise.reject(error);
});

export default axios;