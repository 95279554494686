import React from "react";
import "./components.css";

function Wrapper({className, onClick, style, children, title}){
	return <span title={title} onClick={onClick} className={className || "fa fa-plus"} style={{ position: 'relative', textAlign: 'center', color: 'black' ,opacity: '0.8' , ...style }}>
		{children}
	</span>
}

async function getValidFiles(files, validator) {
	let validFiles = validator && validator(files);
	if (validFiles) {
		if (validFiles instanceof Promise) {
			validFiles = await validFiles;
		}
		return validFiles;
	}
	return files;
}
export function FilePicker({ onChange, accept, multiple, className, validate, style, title, Component = Wrapper }) {
	return (
		<Component title={title} 
			onClick={e => e.currentTarget.querySelector('input').click()} 
			className={className} 
			style={style}
		>
			<input onChange={(e) => {
				if (onChange) {
					let currentTarget = e.currentTarget;
					getValidFiles(currentTarget.files, validate).then(files => onChange(files)).finally(() => {
						currentTarget.value = '';
					});
				}
			}} multiple={multiple} type="file" accept={accept} style={{ display: 'none' }} />
		</Component>
	);
}

export function ColorPicker({ onClick, onChange, color, style }) {
	return <span className="color-item-thumb-wrap" style={style}>
		<span onClick={e => {
			if (onClick) {
				onClick.call(null, e);
			}
			let target = e.target;
			if (target && (target = target.querySelector('input'))) {
				if (color) target.value = color;
				target.click();
			}
		}} style={{ background: color }}>
			<input type="color" onChange={onChange}
				style={{ opacity: 0, left: '100%', width: 0, height: 0, position: 'absolute' }} />
		</span>
	</span>;
}
