import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classes from './Dialog.module.css';
import Utils from "../../Utils";

function ModalDialog({ title, onClose, content, footer, width, height, maxWidth, contentClassName, className, contentTextClassName, fullWidth }) {
    let style;
    if (width || height) {
        style = { width, height };
    }
    return <Dialog
        maxWidth={maxWidth}
        className={className}
        fullWidth={fullWidth}
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
            <span>{title}</span>
            {
                onClose &&
                <IconButton style={{ float: 'right', padding: 0, marginLeft: "1rem" }}
                    onClick={onClose}>
                    <CloseIcon style={{ color: '#101010', width: '1.5rem', height: '1.5rem' }} />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent className={contentClassName}><div style={style} className={Utils.getClasses(classes.content, contentTextClassName)}>{content}</div></DialogContent>
        <DialogActions>{footer}</DialogActions>
    </Dialog>
}

export default ModalDialog;