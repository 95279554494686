import React from 'react';
import classes from "./WrapperContainer.module.css";
import Utils from '../../Utils';

const WrapperContainer = React.forwardRef(({ className, title, children }, ref) => {
  return (
    <div ref={ref} className={Utils.getClasses(classes.main, className)}>
      {title && (
        <div className={classes.headingWrapper}>
          <h1 className={classes.heading}>{title}</h1>
        </div>
      )}
      {children}
    </div>
  );
});

export default WrapperContainer;
