import { useEffect, useRef, useState } from 'react';
import Utils from "../../../Utils";
import classes from "./SidebarController.module.css";
import LeftController from '../LeftController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const SideBarController = ({ backColor }) => {
    const ref = useRef();
    const [slider, setSlider] = useState(false);

    useEffect(() => {
        window.showLeftSidebar = setSlider;
        return () => {
            delete window.showLeftSidebar;
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (slider && ref.current && !ref.current.contains(e.target)) {
                setSlider(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [slider]);

    return (
        <div className={classes.sidebarController}>
            <div className={classes.logoContainer}>
                <div ref={ref} className={Utils.getClasses(slider ? classes.open : classes.close)}>
                    {/* <div className={classes.icons}>
                        <FontAwesomeIcon onClick={e => setSlider(false)} className={classes.cancelIcon} icon={faXmark} />
                    </div> */}
                    <LeftController className={classes.leftController} backColor={backColor} setOpen={setSlider} taggablePanel={false} />
                </div>
            </div>
        </div>
    )
}

export default SideBarController;