import React from 'react';
import classes from './RatingsLikesViews.module.css';
import eyeIcon from '../../assets/ratingsLikesStarIcons/icon-eye.svg';
import heartIcon from '../../assets/ratingsLikesStarIcons/icon-heart.svg';
import starIcon from '../../assets/ratingsLikesStarIcons/icon-star.svg'
import Utils from '../../Utils';

function RatingsLikesViews({ likes, views, ratings, eyeStyle, style }) {
    return (
        <div className={classes.ratingsLikesViews}>
            <div style={style} className={classes.ratingsLikesViewsContent}><img className={classes.icon} src={heartIcon} alt="heart" /> {Utils.numberFormatter(likes)}</div>
            <div style={style}  className={classes.ratingsLikesViewsContent}><img className={classes.icon} style={{ ...eyeStyle }} src={eyeIcon} alt='eye' /> {Utils.numberFormatter(views)}</div>
            <div style={style}  className={classes.ratingsLikesViewsContent}><img className={classes.icon} src={starIcon} alt="star" /> {ratings}</div>
        </div>
    )
}

export default RatingsLikesViews