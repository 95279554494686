import React, { useEffect, useState } from "react";
import classes from "./Notification.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import EmptyDialogue from "../EmptyDialogue/EmptyDialogue";
import notificationAnimation from "../../../../assets/dashboardImage/animation/notification-animation.json";
import reject from "../../../../assets/dashboardImage/notification-icon-rejected.svg";
import review from "../../../../assets/dashboardImage/notification-icon-review.svg";
import liked from "../../../../assets/dashboardImage/notification-icon-liked.svg";
import ordered from "../../../../assets/dashboardImage/notification-icon-ordered.svg";
import approved from "../../../../assets/dashboardImage/notification-icon-approved.svg";
import NotificationCard from "../../../../components/NotificationCard/NotificationCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import { deleteNotification } from "../../BooksActions";
import Utils from "../../../../Utils";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";

const cardIcon = {
  review: review,
  rejected: reject,
  approved: approved,
  liked: liked,
  ordered: ordered,
};

function Notification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.user.notifications);
  const theme = useTheme();
  const isMobScreen = !useMediaQuery(theme.breakpoints.up("sm"));

  const newNotifications = [...notifications];
  newNotifications?.sort((a, b) => parseInt(b.time) - parseInt(a.time));

  const createBookHandler = () => {
    Utils.createBook(history);
  };

  function deleteNotificationHandler(notificationId) {
    deleteNotification(dispatch, notificationId);
  }

  return (
    <div className={classes.main}>
      <UserDashboardHeader
        title="Notifications"
        description="Manage all your notifications here."
        disabled
      />
      {notifications && notifications.length > 0 ? (
        <div className={classes.container}>
          {newNotifications.map((item, idx) => {
            const bookTitle = item?.bookDetails?.[0]?.title;
            let icon = "";
            let title = "";
            let description = "";
            let status = item.action;
            if (status === "ORDERED") {
              icon = cardIcon["ordered"];
              title = "Somebody purchased your book !";
              description = (
                <>
                  Your book{" "}
                  <span className={classes.boldText}>{bookTitle}</span> has been
                  ordered by someone.
                </>
              );
            } else if (status === "LIKED") {
              icon = cardIcon["liked"];
              title = "Somebody liked your book !";
              description = (
                <>
                  Someone liked your book with the title{" "}
                  <span className={classes.boldText}>{bookTitle}</span>
                </>
              );
            } else if (status === "REJECTED") {
              const remarks = item?.bookDetails[0]?.remarks;
              let remarkText = "";
              if (remarks) {
                remarkText = remarks[remarks.length - 1]?.text;
              }
              icon = cardIcon["rejected"];
              title = status;
              description = (
                <>
                  Your book with title{" "}
                  <span className={classes.boldText}>{bookTitle}</span> has been
                  rejected due to{" "}
                  <span className={classes.boldText}>{remarkText}</span>. Please
                  edit your book and submit again.
                </>
              );
            } else if (status === "APPROVED") {
              icon = cardIcon["approved"];
              title = status;
              description = (
                <>
                  Your book with title{" "}
                  <span className={classes.boldText}>{bookTitle}</span> has been
                  approved and successfully published. Order your copy now! and
                  earn 10% of MRP as Author Royalty. View your E-certificate and
                  Social media poster <Link to="/user/my-achievements">here</Link>.
                </>
              );
            }

            const timeSince = Utils.getTimeSince(item.time);
            return (
              <NotificationCard
                key={item.id}
                status={status}
                icon={icon}
                title={title}
                description={description}
                timeSince={timeSince}
                deleteNotificationHandler={deleteNotificationHandler}
                notificationId={item.id}
              />
            );
          })}
        </div>
      ) : (
        <EmptyDialogue
          animation={notificationAnimation}
          descriptionText="Looks like you have no notifications yet."
          buttonText="Create your free book now"
          onClick={createBookHandler}
          lottieWidth="100%"
          lottieHeight="14.5rem"
        />
      )}
    </div>
  );
}

export default Notification;
