import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import Utils from "../../Utils";
import classes from "./SideBarMenu.module.css";
import Consts from '../../Consts';
import logoImage from "../../assets/bookalooza-logo.png";
import NavBar from './NavBar';
import { useMobileScreen } from '../../custom-hooks/MobileScreen';

const SideBarMenu = ({ menuItems }) => {
    const history = useHistory();
    const ref = useRef();
    const url = Consts.BASE_URL;
    const [slider, setSlider] = useState(false);
    const isMobScreen = useMobileScreen();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (slider && ref.current && !ref.current.contains(e.target)) {
                setSlider(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [slider]);

    const closeOpenHandler = () => {
        setSlider((prevState) => !prevState)
    }

    return (
        <div>
            <div className={classes.logoContainer}>
                <div className={classes.icons}>
                    <FontAwesomeIcon onClick={closeOpenHandler} className={classes.barsIcon} icon={faBars} />
                    <img src={logoImage} className={classes.logoImage} onClick={() => history.push(url)} alt='' />
                </div>
                <div ref={ref} className={Utils.getClasses(slider ? classes.open : classes.close)}>
                    <ul className={classes.navMenu}>
                        <div className={classes.sidebarIcon}>
                            <FontAwesomeIcon onClick={closeOpenHandler} className={classes.cancelIcon} icon={faXmark} />
                            <img src={logoImage} className={classes.logoImage} onClick={() => { closeOpenHandler(); history.push(url) }} alt='' />
                        </div>
                        <NavBar menuItems={menuItems} depth={1} closeOpenHandler={closeOpenHandler} isMobScreen={isMobScreen} isSideBarMenu />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideBarMenu;