import React from "react";

function JustifiedRight() {
  return (
    <svg
      id="Layer_6"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 6"
    >
      <path d="m478 33.79-444 .18c-18.78 0-34 15.22-34 34s15.22 34 34 34l444-.18c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
      <path d="m478 159.27-444 .18c-18.78 0-34 15.22-34 34s15.22 34 34 34l444-.18c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
      <path d="m478 284.74-444 .18c-18.78 0-34 15.22-34 34s15.22 34 34 34l444-.18c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
      <path d="m478 410.21h-306.64c-18.78 0-34 15.22-34 34s15.22 34 34 34h306.64c18.78 0 34-15.22 34-34s-15.22-34-34-34z" />
    </svg>
  );
}

export default JustifiedRight;
