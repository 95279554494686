import axios from "axios";
import { blogsEventAction } from "./BlogsSlice";

export function fetchAllBlogEvents(page, pageSize, dispatch) {
  let filter = {
    "offset": (page - 1) * pageSize,
    "limit": pageSize,
    sortBy: "_id DESC"
  };
  return axios({
    url: `/designer/blogs?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    method: 'get',
    responseType: 'json'
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(blogsEventAction.fetchBlogEvents({ blogList: data?.data || [] }))
    }
  }).catch(function (error) {
    return error;
  })

}

export function fetchBlog(slug) {
  return axios({
    url: `/designer/blogs/slag/${slug}`,
    method: "get",
    responseType: "json"
  }).then(e => e.data.data)
}

export function getContent(contentDataPath) {
  return axios({
    url: contentDataPath,
    method: 'get',
  }).then(response => response.data)
}

export function fetchBlogsCount(dispatch) {
  axios({
    url: `/designer/blogs/count`,
    method: "get",
    responseType: "json"
  }).then(response => {
    let data = response.data;
    if (data.success) {
      dispatch(blogsEventAction.blogsTotalCount({ count: data.data.count }))
    }
  })
}