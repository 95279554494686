import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: "order",
    initialState: { orderData: {} },
    reducers: {
        saveOrder: (state, action) => {
            state.orderData = action.payload;
        }
    },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
