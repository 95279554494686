import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserImages, uploadUserImages, deleteUserImage } from './UploadImagesAction';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { Grid } from '@material-ui/core';
import deleteIcon from '../../../../assets/delete.svg';
import { FilePicker } from '../../Picker';
import ModalDialog from '../../../../components/Dialog/Dialog';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import classes from "../../Editor.module.css";
import { useFabricCanvas } from '../../FabricCanvas';
import { addImageToCanvas } from '../../objectUtils';

function getImageFromUrl(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = e => resolve(img);
    img.onerror = reject;
    img.src = url;
  })
}

async function getImage(file) {
  let url = URL.createObjectURL(file),
    img = await getImageFromUrl(url);
  URL.revokeObjectURL(url);
  return img;
}

function validate(files) {
  let validFiles = [];
  for (let i = 0; i < files.length; ++i) {
    let f = files[i];
    if (f.type.match(/image\/(png|jpeg)/)) {
      validFiles.push(f);
    }
  }
  if (files.length && !validFiles.length) {
    alert("Error", "The uploaded file(s) are not supported.");
  } else if (files.length > validFiles.length) {
    alert("Warning", "Some of the uploaded file(s) are not supported.");
  }
  return validFiles;
}

function UploadImages({ visible }) {
  const [deleteImage, setDeleteImage] = useState(null)
  const currentUserImages = useSelector(state => state.uploadImage);
  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const canvas = useFabricCanvas();

  useEffect(() => {
    fetchAllUserImages(dispatch)
  }, [])

  return <Container visible={visible} title={"Upload Pictures"}>
    {
      currentUserImages?.items.length > 0 ? <div>
        <div>
          <Grid container spacing={1} style={{ margin: '0', paddingRight: '12px' }}>
            {currentUserImages?.items.map((img, idx) => {
              return (<Grid item xs={6} key={idx} style={{ display: "flex", justifyContent: 'center', position: 'relative' }}>
                <div className={classes.uploadImageDeleteIcon}
                  onClick={() => { setDeleteImage(img.id) }}
                >
                  <img style={{ cursor: "pointer", scale: "0.75" }} src={deleteIcon} />
                </div>
                <div className="image-item-thumb-wrap" style={{ width: '100%' }}
                  onClick={() => {
                    let taggedImages = canvas.getLayerById('taggedImages');
                    if (!taggedImages) {
                      taggedImages = canvas.getLayerById('contents');
                    }

                    if (taggedImages) {
                      addImageToCanvas(img, 0.3, null, null).then(obj => {
                        taggedImages.add(obj);
                        canvas.fire('object:moved', { target: obj });
                        canvas.setActiveObject(obj);
                      });
                    }
                  }}>
                  <img className="image-item-thumb" src={img.thumbPath} alt="" />
                </div>
              </Grid>)
            })}
            {
              deleteImage && <ModalDialog
                title="Confirm"
                content={
                  <span>Delete Image?</span>
                }
                footer={
                  <>
                    <SecondaryButton onClick={() => setDeleteImage(null)}>No</SecondaryButton>
                    <PrimaryButton onClick={e => {
                      deleteUserImage(dispatch, deleteImage)
                      setDeleteImage(null);
                    }}>Yes</PrimaryButton>
                  </>
                }
              />
            }
          </Grid>
        </div>
      </div> : <div style={{ marginTop: "3rem", height: "15rem" }}>
        <h3 className='emptyMessage'>No pictures uploaded yet. Click Upload picture button to add your images.</h3>
      </div>
    }
    <div style={{
      width: "100%",
      margin: "2rem 0px",
      padding: "1rem 0",
      position: "sticky",
      bottom: "3rem",
      zIndex: '1',
      background: "#fff"
    }}>
      <FilePicker
        Component={props => {
          return <PrimaryButton onClick={props.onClick} disabled={!user}>
            <span>Upload picture</span>
            {props.children}
          </PrimaryButton>
        }}
        accept="image/png, image/jpeg"
        multiple
        validate={validate}
        onChange={(files) => uploadUserImages(dispatch, files)}
      />
    </div>
  </Container>
}

export default UploadImages