import axios from '../../../axios';
import Consts from '../../Consts';
import { foregroundColorsActions, backgroundColorsActions, fontsActions, clipartActions, imageActions, shapeActions } from './EditorSlice';

const baseUrl = Consts.BASE_URL;
export function fetchColorData(dispatch, json, action) {
    let version = Consts.APP_VERSION;
    let obj = { library: [], libraryItems: [] };
    return Promise.all([
        axios({
            url: `${baseUrl}/resources/colors/${json}?${version}`,
            method: "get",
            responseType: "json"
        })
    ]).then(function (responses) {
        obj.library.push(...responses[0].data.library.map(i => {
            i.system = true;
            return i;
        }));
        Promise.all(
            obj.library.map(lib => {
                return axios({
                    url: `${baseUrl}/resources/colors/${lib.json}?${version}`,
                    method: "get",
                    responseType: "json"
                }).then(res => {
                    res.data.system = lib.system;
                    return res.data;
                });
            })
        ).then(libraryItems => {
            obj.libraryItems = libraryItems;
            dispatch(action.fetchColors(obj));
        });
    });
}
export function fetchForegroundColorData(dispatch) {
    return fetchColorData(dispatch, "colorlist.json", foregroundColorsActions);
}
export function fetchBackgroundColorData(dispatch) {
    return fetchColorData(dispatch, "backgroundcolorlist.json", backgroundColorsActions);
}

function addColorLibrary(dispatch, name, data, type, json, action) {
    let libs = data.library.slice();
    libs.push({
        json: `${type}/${name}.json`
    });
    return axios({
        url: `${baseUrl}/user-colors/${json}`,
        method: 'POST',
        data: {
            library: libs.filter(i => !i.system)
        }
    }).then(res => {
        axios({
            url: `${baseUrl}/user-colors/${type}/${name}.json`,
            method: 'POST',
            data: {
                name: name,
                colors: []
            }
        }).then(e =>
            dispatch(
                action.fetchColors({
                    library: libs,
                    libraryItems: data.libraryItems.concat([{
                        name: name,
                        colors: []
                    }])
                })
            )
        );
    });
}

export function addBackgroundColorLibrary(dispatch, name, data) {
    return addColorLibrary(dispatch, name, data, "background", "backgroundcolorlist.json", backgroundColorsActions);
}
export function addForegroundColorLibrary(dispatch, name, data) {
    return addColorLibrary(dispatch, name, data, "foreground", "colorlist.json", foregroundColorsActions);
}

function addColor(dispatch, target, data, type, action) {
    return axios({
        url: `${baseUrl}/user-colors/${type}/${target.name}.json`,
        method: 'POST',
        data: target
    }).then(e => {
        dispatch(action.fetchColors({
            library: data.library,
            libraryItems: data.libraryItems.reduce((prev, item) => {
                prev.push(item.name === target.name ? target : item);
                return prev;
            }, [])
        }));
    });
}

export function addBackgroundColor(dispatch, target, data) {
    return addColor(dispatch, target, data, "background", backgroundColorsActions);
}
export function addForegroundColor(dispatch, target, data) {
    return addColor(dispatch, target, data, "foreground", foregroundColorsActions);
}

function removeColorLibrary(dispatch, target, data, type, json, action) {
    const name = `${type}/${target.name}.json`;
    const libs = data.library.filter(i => i.json !== name);
    return axios({
        url: `${baseUrl}/user-colors/${json}`,
        method: 'POST',
        data: {
            library: libs.filter(i => !i.system)
        }
    }).then(e => {
        axios({
            url: `${baseUrl}/user-colors/${name}`,
            method: 'DELETE'
        });
        dispatch(action.fetchColors({
            library: libs,
            libraryItems: data.libraryItems.filter(i => i.name !== target.name)
        }));
    });
}


export function removeBackgroundColorLibrary(dispatch, target, data) {
    return removeColorLibrary(dispatch, target, data, "background", "backgroundcolorlist.json", backgroundColorsActions);
}
export function removeForegroundColorLibrary(dispatch, target, data) {
    return removeColorLibrary(dispatch, target, data, "foreground", "colorlist.json", foregroundColorsActions);
}

export function fetchClipartData(dispatch) {
    let obj = { libraries: [] };
    return axios({
        url: `${baseUrl}/resources/cliparts/clipartlist.json?${Consts.APP_VERSION}`,
        method: "get",
        responseType: "json"
    }).then(function (response) {
        obj.libraries.push(...response.data.libraries.map(i => {
            i.system = true;
            return i;
        }));
        dispatch(clipartActions.fetchCliparts(obj));
    });
}

export function fetchImageData(dispatch) {
    return axios({
        url: `${baseUrl}/resources/images/imagelist.json?${Consts.APP_VERSION}`,
        method: "get",
        responseType: "json"
    }).then(function (response) {
        dispatch(imageActions.fetchImages(response.data.categories));
    });
}

export function fetchShapeData(dispatch) {
    return axios({
        url: `${baseUrl}/resources/shape/clipartlist.json?${Consts.APP_VERSION}`,
        method: "get",
        responseType: "json"
    }).then(function (response) {
        dispatch(shapeActions.fetchShapes(response.data.categories));
    });
}

export function fetchFonts(dispatch) {
    return axios({
        url: `${baseUrl}/resources/fonts/fontlist.json?${Consts.APP_VERSION}`,
        method: 'GET'
    }).then(e => {
        let data = e.data;
        dispatch(fontsActions.fetchFonts(data));
    });
};