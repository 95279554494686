import React, { forwardRef } from "react";
import classes from "./TextArea.module.css";
import Utils from "../../Utils";

const TextArea = forwardRef(({
  name,
  onChange,
  rows = 10,
  placeHolder,
  value,
  disabled,
  className
}, ref) => {
  return (
    <textarea
      name={name}
      rows={rows}
      value={value}
      ref={ref}
      onChange={onChange}
      placeholder={placeHolder}
      className={Utils.getClasses(classes.primaryTextArea, className)}
      disabled={disabled}
    />
  );
});

export default TextArea;
