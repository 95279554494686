import React, { useState, useEffect, useRef } from 'react';
import classes from './DropDownMenu.module.css';
import dropDownImage from '../../assets/dropdown-outline.svg';
import Utils from '../../Utils';
import NumberIcon from '../NumberIcon/NumberIcon';

function DropDownMenu(props) {
    const ref = useRef();
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showChildren && ref.current && !ref.current.contains(e.target)) {
                setShowChildren(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showChildren]);

    const disabled = props.hasOwnProperty('count') && props.count === 0;
    
    return <div className={classes.main} ref={ref}>
        <div 
        className={Utils.getClasses(classes.header, disabled ? 'x-item-disabled' : '')}
        onClick={() => setShowChildren(state => !state)}
        onMouseEnter={() => { props.isOnMouseEnter && setShowChildren(true) }}
        >
            {props.title &&
                <span>{props.title}</span>}
            <div className={Utils.getClasses(classes.dropdownImage, props.alert && classes.bell)} style={{
                ...props.style,
                backgroundImage: `url(${props.imageIcon || dropDownImage})`
            }}>
            </div>
            {!!props.count && <div className={classes.count}>
                <NumberIcon number={props.count} fill={'var(--selection-dark)'}  />
            </div>}
        </div>
        {showChildren && <div className={Utils.getClasses(classes.children, props.childrenClass ? props.childrenClass :"")}  onClick={() => setShowChildren(false)}>
            {props.children}
        </div>}
    </div>
}

export default DropDownMenu;