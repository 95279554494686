import React from 'react'
import Utils from '../../Utils';
import classes from './LinkButton.module.css';

function LinkButton({ className, onClick, style, label, disabled, children }) {
    return <span className={Utils.getClasses(classes.main, className, disabled && classes.disabled)} onClick={onClick} style={style}>
        {children || label}
    </span>
}

export default LinkButton;