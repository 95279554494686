import React, { useEffect } from "react";
import classes from "./ThankYou.module.css";
import thankyouBg from "../../assets/thankyou/thankyoupageBG.jpg";
import bookAloozaCharacter from "../../assets/images/alooza-character.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ThankYou({ location: { pathname, state } }) {
  const history = useHistory();
  useEffect(() => {
    const timerId = setTimeout(() => {
      history.push(state.from);
    }, 7000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const studentData = {
    leftHandSideData: {
      para1:
        "That's an Awesome move! We're super excited that you're part of our Creative Writing Workshop. Get ready for a fun journey into the world of storytelling!",
      para2:
        "You're in for some cool stuff! Our workshop is all about learning how to write awesome stories. At Bookalooza, we want you to be a writing star. Not only will you learn in the workshop, but you can also write your own stories, put them out there, and even earn money!",
    },
    rightHandSideData: {
      para1: (
        <p>
          As a big thank you for hanging out with us, you'll get a special{" "}
          <b>10% discount</b> on every book you sell on Bookalooza. "It's our
          way of saying, You're awesome, and we appreciate you!"
        </p>
      ),
      para2: (
        <p>
          Watch out for an email from us. We'll send you all the info about the
          workshop, the schedule, and anything else you need to know. If you
          have questions, just hit us up on{" "}
          <a href="mailto:connect@bookalooza.com">connect@bookalooza.com</a>
        </p>
      ),
    },
  };

  const schoolData = {
    leftHandSideData: {
      para1:
        "We extend our sincere appreciation for selecting Bookalooza as the platform for your school's creative enrichment. Your registration for the Creative Writing Workshop reflects a commitment to fostering literary excellence among your students.",
      para2:
        "A dedicated representative from our team will promptly reach out to your school to facilitate seamless integration into the workshop.",
    },
    rightHandSideData: {
      para1: (
        <p>
          Kindly monitor your inbox for forthcoming correspondence. Our team
          will furnish you with the complete workshop schedule, supplementary
          resources, and any pertinent information to augment this collaborative
          venture.
        </p>
      ),
      para2: (
        <p>
          Thank you once more for choosing Bookalooza. If you have questions,
          just hit us up on{" "}
          <a href="mailto:connect@bookalooza.com">connect@bookalooza.com</a>
        </p>
      ),
    },
  };

  return (
    <div
      className={classes.main}
      style={{ backgroundImage: `url(${thankyouBg})` }}
    >
      <div className={classes.container}>
        <div className={classes.leftSideContainer}>
          <h1 className={classes.thankyouTitle}>Thank You</h1>
          <p>
            {pathname === "/student/thankyou"
              ? studentData.leftHandSideData.para1
              : schoolData.leftHandSideData.para1}
          </p>
          <p>
            {pathname === "/student/thankyou"
              ? studentData.leftHandSideData.para2
              : schoolData.leftHandSideData.para2}
          </p>
        </div>
        <div className={classes.rightSideContainer}>
          <div className={classes.thankyouImage}>
            <img
              src={bookAloozaCharacter}
              alt={
                pathname === "/student/thankyou"
                  ? "thank you student"
                  : "thank you school"
              }
            />
          </div>

          {pathname === "/student/thankyou"
            ? studentData.rightHandSideData.para1
            : schoolData.rightHandSideData.para1}

          {pathname === "/student/thankyou"
            ? studentData.rightHandSideData.para2
            : schoolData.rightHandSideData.para2}
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
