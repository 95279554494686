import React from 'react';
import personIcon from '../../assets/person-icon.svg'
import classes from "./ProfilePicture.module.css";

function ProfilePicture(props) {
  const profilePicture = props.pictureSource || personIcon
  const borderColor = props.borderColor || "#FB7E05";
  const size = props.size || "2rem";

  return (
    <div className={classes.mainContainer} style={{ borderColor: borderColor, width: size, height: size }}>
        <img src={profilePicture} alt="Profile picture" />
    </div>
  );
}

export default ProfilePicture;
